import { useCallback, useMemo } from 'react'
import { useStore } from '../../root-store'
import EditStoreDialog from '../../stores/edit-store-dialog'
import { Box, IconButton, MenuItem, Select } from '@mui/material'
import { Edit } from '@mui/icons-material'
import { toDictionary } from '../../utils/array-utils'

export function StoreSelector({
  value,
  onChange,
  allowEdit,
}: {
  value?: string
  onChange: (storeId: string | null) => void
  allowEdit?: boolean
}) {
  const { authStore, dialogStore } = useStore()

  const editStore = useCallback((storeId: string) => {
    return dialogStore.showDialogAsync(EditStoreDialog, {
      storeId,
    })
  }, [])

  const options = useMemo(
    () =>
      (authStore.userStores || []).map((s) => ({
        value: s.store_name,
        key: s.store_id,
      })),
    [authStore.claims],
  )
  const keyToValue = useMemo(() => {
    return toDictionary(
      options,
      (o) => o.key,
      (o) => o.value,
    )
  }, [options])

  return (
    <Select
      sx={{
        background: 'rgba(255,255,255,0.8)!important',
        overflow: 'hidden',
        width: '300px',
      }}
      variant='outlined'
      value={value}
      size='small'
      renderValue={(val) => {
        return keyToValue[val]
      }}
      label='Switch Store'
      onChange={(e) => {
        onChange(e.target.value as string)
      }}
    >
      {options.map((o) => (
        <MenuItem key={o.key} value={o.key}>
          <Box display='flex' alignItems='center' flex={1}>
            <Box flex={1}>{o.value}</Box>
            {allowEdit && (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation()
                  return editStore(o.key)
                }}
              >
                <Edit />
              </IconButton>
            )}
          </Box>
        </MenuItem>
      ))}
    </Select>
  )
}
