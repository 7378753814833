import { useNavigate, useSearchParams } from 'react-router-dom'
import { useCallback } from 'react'

export const useOrderPopup = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const toggleOpenOrder = useCallback(
    (orderId?: string) => {
      if (!orderId || orderId === searchParams.get('fullOrderId')) {
        searchParams.delete('fullOrderId')
      } else {
        searchParams.set('fullOrderId', orderId)
      }
      navigate({ search: searchParams.toString() })
    },
    [searchParams.toString()],
  )
  return {
    toggleOpenOrder,
    selectedOrderId: searchParams.get('fullOrderId') as string | undefined,
  }
}
