import { DialogButton, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { ordersService } from '@/orders/order-service'
import { useCallback, useState } from 'react'
import { StepProps } from '../steps'

export default function CancelOrderStep({
  orderId,
  reloadOrder,
  setCurrentStep,
}: StepProps) {
  const [loading, setLoading] = useState<string | false>(false)

  const cancelOrder = useCallback(
    (reason: string) => async () => {
      setLoading(reason)
      await ordersService.cancelOrder(orderId, reason)
      setCurrentStep('orderCanceled')
      reloadOrder()
    },
    [orderId, reloadOrder, setCurrentStep],
  )

  return (
    <>
      <DialogHeader>
        <DialogTitle>Why are you canceling this order?</DialogTitle>
      </DialogHeader>
      <div className='grid grid-cols-2 gap-4'>
        <DialogButton
          loading={loading === 'Store is too busy'}
          className='justify-center text-center'
          onClick={cancelOrder('Store is too busy')}
        >
          Store is too busy
        </DialogButton>
        <DialogButton
          loading={loading === 'Need more prep time'}
          className='justify-center text-center'
          onClick={cancelOrder('Need more prep time')}
        >
          Need more prep time
        </DialogButton>
        <DialogButton
          loading={loading === 'An item is out of stock'}
          className='justify-center text-center'
          onClick={cancelOrder('An item is out of stock')}
        >
          An item is out of stock
        </DialogButton>
        <DialogButton
          loading={loading === 'The store is closing'}
          className='justify-center text-center'
          onClick={cancelOrder('The store is closing')}
        >
          The store is closing
        </DialogButton>
        <DialogButton
          loading={loading === 'Driver never arrived'}
          className='justify-center text-center'
          onClick={cancelOrder('Driver never arrived')}
        >
          Driver never arrived
        </DialogButton>
        <DialogButton
          loading={loading === 'It&apos;s something else'}
          className='justify-center text-center'
          onClick={cancelOrder('It&apos;s something else')}
        >
          It&apos;s something else
        </DialogButton>
      </div>
    </>
  )
}
