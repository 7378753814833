import {
  Autocomplete,
  AutocompleteProps,
  Button,
  ButtonProps,
  Checkbox,
  CircularProgress,
  debounce,
  Fab,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  InputBaseComponentProps,
  InputLabel,
  InputProps,
  MenuItem,
  PropTypes,
  Radio,
  RadioGroup,
  Rating,
  Select,
  TextField,
  TextFieldProps,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import React, {
  CSSProperties,
  HTMLInputTypeAttribute,
  useCallback,
  useMemo,
  useState,
} from 'react'
import {
  Control,
  Controller,
  FieldPath,
  RegisterOptions,
} from 'react-hook-form'
import { FieldValues } from 'react-hook-form/dist/types/fields'
import { SelectProps } from '@mui/material/Select/Select'
import ReactInputMask from 'react-input-mask'
import { Edit, Star, SvgIconComponent } from '@mui/icons-material'
import { IconButtonTypeMap } from '@mui/material/IconButton/IconButton'
import { styledWithConditionalProps } from '../utils/styled'
import { BaseDatePickerProps } from '@mui/x-date-pickers/DatePicker/shared'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DatePicker, TimePicker } from '@mui/x-date-pickers'
import { useEffectNotFirstTime, useStateFromProp } from '../utils/hooks'
import { OptionProps } from './filters/filters'
import { DateTime } from 'luxon'
import Decimal from 'decimal.js'
import { AutocompleteValue } from '@mui/base/useAutocomplete/useAutocomplete'

// export type DebouncedTextInputProps = Omit<
//   TextFieldProps,
//   'variant' | 'onChange'
// > & {
//   rules?: Exclude<
//     RegisterOptions,
//     'valueAsNumber' | 'valueAsDate' | 'setValueAs'
//   >
//   onChange: (value?: any) => void
//   debounceMillis: number
// }

export const DebouncedTextInput = ({
  debounceMillis,
  ...props
}: Omit<TextFieldProps, 'onChange'> & {
  rules?: Exclude<
    RegisterOptions,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >
  onChange: (value?: any) => void
  debounceMillis: number
}) => {
  const [text, setText] = useState((props.value as string) || '')

  const setTextDebounced = useCallback(
    debounce((text: string) => props.onChange(text), debounceMillis),
    [props.onChange],
  )
  useEffectNotFirstTime(() => {
    setTextDebounced(text)
  }, [text])

  useEffectNotFirstTime(() => {
    setText((props.value as string) || '')
  }, [props.value])

  return (
    <TextField
      {...props}
      value={text}
      onChange={(e) => setText(e.target.value)}
    />
  )
}

export const RatingWrapper = <T extends FieldValues>(props: {
  propName: FieldPath<T>
  control: Control<T>
  rules?: Exclude<
    RegisterOptions,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >
  label?: string
  disabled?: boolean | undefined
  fullWidth?: boolean
  size?: 'small' | 'medium' | 'large'
  textFieldProps?: Omit<SelectProps, 'value' | 'onChange'>
  onRatingChange?: () => void
}) => {
  return (
    <>
      <Typography component='legend'>{props.label}</Typography>
      <Controller
        {...(props.rules ? { rules: props.rules } : {})}
        control={props.control}
        name={props.propName}
        render={({ field: { value, onChange } }) => (
          <Rating
            size={props.size || 'large'}
            emptyIcon={<Star style={{ opacity: 0.55 }} fontSize='inherit' />}
            value={value || 0}
            onChange={(_, v) => {
              onChange(v)
              props.onRatingChange?.()
            }}
            {...(props.disabled ? { disabled: props.disabled } : {})}
          />
        )}
      />
    </>
  )
}

export interface AutoCompleteWrapperProps<T extends FieldValues>
  extends Omit<AutoCompleteProps, 'value' | 'onChange'> {
  propName: FieldPath<T>
  control: Control<T>
  validateChange?: (value: any) => Promise<boolean>
  disabled?: boolean
  error?: boolean
  CustomAutocomplete?: React.FunctionComponent<
    AutoCompleteProps & { onChange: (value?: string | null) => void }
  >
}

export const AutoCompleteWrapper = <T extends FieldValues>({
  control,
  propName,
  rules,
  validateChange,
  CustomAutocomplete,
  ...rest
}: AutoCompleteWrapperProps<T>) => {
  return (
    <Controller
      control={control}
      name={propName}
      {...(rules ? { rules } : {})}
      render={({ field: { onChange, value } }) => {
        if (CustomAutocomplete) {
          return (
            <CustomAutocomplete
              {...rest}
              rules={rules}
              value={value}
              onChange={onChange}
            />
          )
        }

        return (
          <AutoCompleteComp
            {...rest}
            rules={rules}
            value={value}
            onChange={async (_event, value) => {
              if (validateChange && !(await validateChange(value))) {
                return
              }
              onChange(value)
            }}
            fullWidth={true}
          />
        )
      }}
    />
  )
}

interface TextInputWrapperProps<T extends FieldValues> {
  propName: FieldPath<T>
  control: Control<T>
  disabled?: boolean
  label?: string
  className?: string
  error?: boolean
  rules?: Exclude<
    RegisterOptions,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >
  textFieldProps?: Omit<TextFieldProps, 'value' | 'onChange'>
  InputProps?: Partial<InputProps>
  inputProps?: Partial<InputBaseComponentProps>
  defaultValue?: unknown
  mask?: string | Array<string | RegExp>
  placeholder?: string
  autoFocus?: boolean
  multiline?: boolean
  rows?: number
  type?: HTMLInputTypeAttribute
}

export const TextInputWrapper = <T extends FieldValues>({
  propName,
  control,
  rules,
  textFieldProps,
  ...rest
}: TextInputWrapperProps<T>) => {
  return (
    <Controller
      control={control}
      name={propName}
      rules={rules}
      render={({ field: { onChange, value } }) => (
        <CustomTextInput
          {...rest}
          {...textFieldProps}
          rules={rules}
          required={!!rules?.required}
          size='small'
          value={value}
          onChange={onChange}
          fullWidth={true}
        />
      )}
    />
  )
}

export const NumericInputWrapper = <T extends FieldValues>({
  propName,
  control,
  rules,
  textFieldProps,
  float,
  ...rest
}: TextInputWrapperProps<T> & { float?: boolean }) => {
  return (
    <Controller
      control={control}
      name={propName}
      rules={rules}
      render={({ field: { onChange, value } }) => (
        <CustomTextInput
          {...rest}
          {...textFieldProps}
          rules={rules}
          required={!!rules?.required}
          size='small'
          value={value}
          onChange={(value) => {
            try {
              const v = value
                ? float
                  ? parseFloat(value)
                  : parseInt(value)
                : value

              onChange(isNaN(v) ? 0 : v)
            } catch (e) {
              onChange(0)
            }
          }}
          fullWidth={true}
        />
      )}
    />
  )
}

export const DatePickerWrapper = <T extends FieldValues>(props: {
  propName: FieldPath<T>
  control: Control<T>
  disabled?: boolean
  label: string
  error: boolean
  required?: boolean
  textFieldProps?: Omit<TextFieldProps, 'value' | 'onChange'>
  datePickerProps?: BaseDatePickerProps<T>
  defaultValue?: Date
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <Controller
        control={props.control}
        name={props.propName}
        rules={{ required: props.required }}
        render={({ field: { onChange, value } }) => (
          <DatePicker
            value={((value as string) || props.defaultValue || null) as any}
            onChange={(date: any) => {
              onChange(date ? date.toJSDate() : null)
            }}
            // renderInput={(params: any) => (
            //   <CustomTextInput
            //     value={value}
            //     size={'small'}
            //     required={props.required || false}
            //     {...params}
            //     label={props.label}
            //     fullWidth={true}
            //     error={props.error}
            //   />
            // )}
          />
        )}
      />
    </LocalizationProvider>
  )
}

export const DayAndTimePickerWrapper = <T extends FieldValues>(props: {
  propName: FieldPath<T>
  control: Control<T>
  disabled?: boolean
  error?: boolean
  required?: boolean
  defaultValue?: string
}) => {
  return (
    <Controller
      control={props.control}
      name={props.propName}
      rules={{ required: props.required }}
      render={({ field: { onChange, value } }) => (
        <DayAndTimePicker
          onChange={onChange}
          value={value}
          required={props.required}
          error={props.error}
          disabled={props.disabled}
          defaultValue={props.defaultValue}
        />
      )}
    />
  )
}

const DayAndTimePicker = (props: {
  disabled?: boolean
  error?: boolean
  required?: boolean
  defaultValue?: string
  value: string
  onChange: (value?: string) => void
}) => {
  const curr = useMemo(() => {
    const date = DateTime.fromISO(props.value)

    return date.set({
      minute: new Decimal(date.minute).div(5).ceil().mul(5).toNumber(),
      second: 0,
    })
  }, [props.value])
  const nowEod = useMemo(() => DateTime.now().endOf('day'), [])

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <Select
        sx={{
          height: 40,
          fontSize: '0.9rem',
        }}
        placeholder='Day'
        disabled={props.disabled}
        size='small'
        fullWidth={true}
        required={!!props?.required}
        error={props.error}
        value={Math.ceil(Math.max(curr.diff(nowEod, 'days').days, 0))}
        onChange={(e) => {
          props.onChange(
            DateTime.now()
              .plus({ day: Number(e.target.value) })
              .set({
                hour: curr.hour,
                minute: curr.minute,
                second: 0,
              })
              .toISO(),
          )
        }}
      >
        <MenuItem value={0}>Today</MenuItem>
        <MenuItem value={1}>Tomorrow</MenuItem>
        <MenuItem value={2}>In 2 days</MenuItem>
        <MenuItem value={3}>In 3 days</MenuItem>
      </Select>
      <TimePicker
        formatDensity='dense'
        disablePast
        value={curr}
        sx={{ '& .MuiInputBase-root': { height: 40 } }}
        onChange={(value) => {
          if (value) {
            props.onChange(
              value
                .set({
                  year: curr.year,
                  month: curr.month,
                  day: curr.day,
                })
                .toISO() || undefined,
            )
          }
        }}
      />
    </LocalizationProvider>
  )
}

export type AutocompleteOption = { value: string; label: string }

export type Value<
  T,
  Multiple extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
> = AutocompleteValue<T, Multiple, any, FreeSolo>

export type AutoCompleteProps<
  T = any,
  Multiple extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
> = Omit<
  AutocompleteProps<T, Multiple, undefined, FreeSolo>,
  'variant' | 'renderInput' | 'options' | 'value' | 'onChange'
> & {
  rules?: Exclude<
    RegisterOptions,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >
  value?: Value<string, Multiple, FreeSolo>
  onChange?: (
    event: React.SyntheticEvent,
    value: Value<string, Multiple, FreeSolo>,
  ) => void
  loading?: boolean
  label?: string
  options?: AutocompleteOption[]
  error?: boolean
  allowNew?: FreeSolo
  onEdit?: (row: AutocompleteOption) => void
}

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const EmailAddressesInput = (props: {
  textFieldProps?: Omit<TextFieldProps, 'value' | 'onChange'>
  value?: string
  setValue: (value: string) => void
}) => {
  const selected = useMemo(() => {
    let array: string[] = []

    if (props.value) {
      array = props.value.split(';').flatMap((a) => a.trim() || [])
    }
    return array
  }, [props.value])

  const [inputValue, setInputValue] = useState<string>('')

  const options = useMemo(() => {
    const match = inputValue.match(EMAIL_REGEX)?.[0]

    return [...selected, ...(match ? [match] : [])]
  }, [selected, inputValue])

  return (
    <Autocomplete
      style={{ minHeight: 40 }}
      size={props.textFieldProps?.size || 'small'}
      filterSelectedOptions={true}
      options={options}
      multiple={true}
      value={selected}
      onChange={(_, value) => {
        props.setValue(value?.join(';') || '')
      }}
      autoHighlight={true}
      autoSelect={true}
      noOptionsText={inputValue ? 'Invalid Email Address' : 'Type Email'}
      inputValue={inputValue}
      onInputChange={(_, value) => setInputValue(value)}
      renderInput={({ inputProps, ...params }) => (
        <CustomTextInput
          {...params}
          InputLabelProps={{
            ...params.InputLabelProps,
            ...props.textFieldProps?.InputLabelProps,
            shrink: true,
          }}
          sx={{
            '& input': {
              fontSize: '0.8rem !important',
              height: '1em !important',
            },
            '& .MuiOutlinedInput-root': {
              padding: 0,
              minHeight: 37,
              margin: '2px',
            },
            '& .MuiAutocomplete-tag': {
              margin: 0,
            },
            ...props.textFieldProps?.sx,
          }}
          autoFocus={false}
          fullWidth
          variant='outlined'
          placeholder='Email Address'
          {...props.textFieldProps}
          size='small'
          inputProps={{
            ...(inputProps || {}),
            ...props.textFieldProps?.inputProps,
          }}
          margin='dense'
        />
      )}
    />
  )
}

export const EmailAddressesWrapper = <T extends FieldValues>(props: {
  propName: FieldPath<T>
  control: Control<T>
  rules?: Exclude<
    RegisterOptions,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >
  textFieldProps?: Omit<TextFieldProps, 'value' | 'onChange'>
}) => {
  return (
    <Controller
      control={props.control}
      name={props.propName}
      rules={props.rules}
      render={({ field: { onChange, value } }) => (
        <EmailAddressesInput
          setValue={onChange}
          value={value}
          textFieldProps={{
            required: props.textFieldProps?.required,
            ...props.textFieldProps,
          }}
        />
      )}
    />
  )
}

export function AutoCompleteComp<
  Multiple extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
>({
  allowNew,
  onEdit,
  ...props
}: AutoCompleteProps<AutocompleteOption, Multiple, FreeSolo>) {
  const itemsMap = (props.options || []).reduce<{
    [key: string]: AutocompleteOption
  }>((acc, { value, label }) => {
    acc[value] = { value, label }
    return acc
  }, {})

  if (
    allowNew &&
    !props.multiple &&
    props.value &&
    !itemsMap[props.value as string]
  ) {
    itemsMap[props.value as string] = {
      label: props.value,
      value: props.value,
    } as AutocompleteOption
  }

  const value: Value<AutocompleteOption, Multiple> = props.multiple
    ? (((props.value as any as string[]) || []).map(
        (v) => itemsMap[v],
      ) as Value<AutocompleteOption, Multiple>)
    : ((props.value ? itemsMap[props.value as string] : null) as Value<
        AutocompleteOption,
        Multiple
      >)

  return (
    <Autocomplete
      {...props}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option?.label || ''
      }
      isOptionEqualToValue={(a, b) =>
        a?.value === b?.value || props.isOptionEqualToValue?.(a, b) || false
      }
      value={value}
      size='small'
      onChange={(e, value) => {
        if (props.multiple) {
          const valArray = value as any as Value<AutocompleteOption, true>
          return props.onChange?.(
            e,
            valArray.map((v) => v.value) as Value<string, Multiple, FreeSolo>,
          )
        } else {
          return props.onChange?.(
            e,
            (value as Value<AutocompleteOption>)?.value as Value<
              string,
              Multiple,
              FreeSolo
            >,
          )
        }
      }}
      // loading={props.loading}
      // disabled={props.disabled}
      // onInputChange={props.onInputChange}
      renderInput={(params) => {
        return (
          <CustomTextInput
            {...params}
            disabled={props.disabled}
            label={props.label}
            rules={props.rules}
            error={props.error}
          />
        )
      }}
      freeSolo={allowNew}
      options={props.options || []}
      renderOption={(p, option, state, ownerState) => {
        if (props.renderOption) {
          return props.renderOption(p, option, state, ownerState)
        }
        return (
          <AutocompleteItem {...p} key={option.value} value={option.value}>
            <span style={{ flex: 1 }}>{option.label}</span>
            {!!onEdit && (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation()
                  return onEdit!(option)
                }}
              >
                <Edit />
              </IconButton>
            )}
          </AutocompleteItem>
        )
      }}
    />
  )
}

export const CustomTextInput = ({
  originalOnChange,
  ...props
}: Omit<TextFieldProps, 'variant' | 'onChange'> & {
  rules?: Exclude<
    RegisterOptions,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >
  onChange?: (value?: any) => void
  originalOnChange?: boolean
}) => {
  const [helperText, setHelperText] = useStateFromProp(
    props.rules?.maxLength
      ? `${(props.value as string)?.length || 0}/${props.rules.maxLength}`
      : '',
  )
  const params: TextFieldProps = {
    ...props,
    value: props.value !== undefined ? props.value : '',
    helperText: props.helperText || helperText,
    FormHelperTextProps: { style: { marginLeft: 0 } },
    InputLabelProps: {
      style: { lineHeight: '1.75em' },
      ...props.InputLabelProps,
    },
    inputProps: {
      max: props.rules?.max,
      min: props.rules?.min,
      maxLength: props.rules?.maxLength,
      minLength: props.rules?.minLength,
      ...props.inputProps,
    },
    onChange: originalOnChange
      ? props.onChange
      : (e: any) => {
          if (
            props.rules?.min !== undefined &&
            parseFloat(e.target.value || '0') < props.rules.min
          ) {
            props.onChange?.(props.rules.min as any)
            return
          }

          if (
            props.rules?.max !== undefined &&
            parseFloat(e.target.value || '0') > props.rules.max
          ) {
            props.onChange?.(props.rules.max as any)
            return
          }

          if (
            e.target.type === 'number' &&
            (Number(e.target.value) > 0 || parseFloat(e.target.value) === 0)
          ) {
            props.onChange?.(parseFloat(e.target.value) as any)
          } else {
            props.onChange?.(e.target.value)
          }
          if (props.rules?.maxLength) {
            setHelperText(`${e.target.value.length}/${props.rules.maxLength}`)
          }
        },
  }

  return (
    <DesktopTextField
      required={!!props.rules?.required}
      fullWidth={true}
      size='small'
      color='secondary'
      InputProps={props.InputProps}
      {...params}
    />
  )
}

const DesktopTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    overflow: 'hidden',
    borderRadius: 4,
    backgroundColor: '#fff',
    '& .Mui-disabled': {
      backgroundColor: theme.colors.dark.D70,
    },
    '&:hover': {
      backgroundColor: theme.colors.blue.B50,
      borderColor: `${theme.colors.blue.B10} !important`,
    },
    '&.Mui-focused': {
      backgroundColor: theme.colors.blue.B50,
      borderColor: theme.colors.blue.B10,
      borderWidth: '1px !important',
      '&.Mui-focused fieldset': {
        borderWidth: '1px',
      },
    },
  },
}))

export const CheckboxWrapper = <T extends FieldValues>(props: {
  propName: FieldPath<T>
  control: Control<T>
  label: string
  rules?: Exclude<
    RegisterOptions,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >
  textFieldProps?: Omit<TextFieldProps, 'value' | 'onChange'>
}) => {
  return (
    <Controller
      control={props.control}
      name={props.propName}
      rules={props.rules}
      render={({ field: { onChange, value } }) => (
        <FormControlLabel
          control={
            <Checkbox
              disabled={props.textFieldProps?.disabled}
              onChange={onChange}
              checked={value as boolean}
            />
          }
          label={props.label}
        />
      )}
    />
  )
}

export const CheckboxGroupWrapper = <T extends FieldValues>(props: {
  propName: FieldPath<T>
  control: Control<T>
  label: string
  error?: boolean
  fullWidth?: boolean
  required?: boolean
  inlineComponent?: React.ReactElement<any, any> | boolean
  oneLine?: boolean
  enumOptions: {
    [key: string | number]: { displayString: string; disable?: boolean }
  }
  displayKey: string
}) => {
  const handleCheck = (checkedId: number, values?: number[]) => {
    if (values?.includes(checkedId)) {
      return values?.filter((id: number) => id !== checkedId)
    } else {
      return [...(values ?? []), checkedId]
    }
  }
  return (
    <Controller
      control={props.control}
      name={props.propName}
      rules={{ required: true, minLength: 1 }}
      render={({ field: { onChange, value } }) => {
        const filteredValues = value.filter(
          (v: any) => !props.enumOptions[v].disable,
        )
        return (
          <FormGroup>
            <Grid item={true} xs={12} style={{ marginBottom: 8 }}>
              <FormLabel
                required={props.required}
                // className={classes.multiSelectLabel}
                error={
                  props.error || (props.required && filteredValues.length === 0)
                }
              >
                {props.label}
              </FormLabel>
              {props.inlineComponent}
            </Grid>
            <Grid container={true} spacing={4}>
              {Object.entries(props.enumOptions)
                .filter(([_option, item]) => !item.disable)
                .map(([option, item]) => {
                  return (
                    <Grid
                      key={option}
                      item={true}
                      xs={6}
                      md={props.oneLine ? true : 6}
                      lg={props.oneLine ? true : 4}
                      xl={props.oneLine ? true : 3}
                    >
                      <ButtonStyled
                        $selected={value.includes(parseInt(option))}
                        onClick={() =>
                          onChange(
                            handleCheck(parseInt(option), value as number[]),
                          )
                        }
                        fullWidth={true}
                      >
                        {props.displayKey
                          ? (item as any)[props.displayKey]
                          : item.displayString}
                      </ButtonStyled>
                    </Grid>
                  )
                })}
            </Grid>
          </FormGroup>
        )
      }}
    />
  )
}

export const CheckboxGroupRadioWrapper = <T extends FieldValues>(props: {
  propName: FieldPath<T>
  control: Control<T>
  label?: string
  disabled?: boolean
  enumOptions: { [key: string]: { displayString: string } }
  required?: boolean
}) => {
  return (
    <Controller
      control={props.control}
      name={props.propName}
      rules={{ required: props.required }}
      render={({ field: { onChange, value } }) => {
        return (
          <>
            {!!props.label && (
              <FormLabel required={props.required}>{props.label}</FormLabel>
            )}
            <RadioGroup
              aria-label='Type'
              name='type'
              value={(value as string) || null}
              row={true}
              onChange={(e) => {
                onChange(parseInt((e.target as HTMLInputElement).value))
              }}
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              {Object.entries(props.enumOptions).map((v) => {
                return (
                  <Grid item xs key={v[0]}>
                    <FormControlLabel
                      value={v[0]}
                      control={<Radio />}
                      label={v[1].displayString}
                    />
                  </Grid>
                )
              })}
            </RadioGroup>
          </>
        )
      }}
    />
  )
}

export const ToggleButtonGroupWrapper = <T extends FieldValues>(props: {
  propName: FieldPath<T>
  control: Control<T>
  label?: string
  disabled?: boolean
  enumOptions: {
    [key: string]: OptionProps
  }
  required?: boolean
}) => {
  return (
    <Controller
      control={props.control}
      name={props.propName}
      rules={{ required: props.required }}
      render={({ field: { onChange, value } }) => {
        return (
          <>
            {!!props.label && (
              <FormLabel required={props.required}>{props.label}</FormLabel>
            )}
            <ToggleButtonGroup
              fullWidth={true}
              sx={{ height: '100%' }}
              size='small'
              color='primary'
              value={value}
              exclusive
              onChange={(_, value) => {
                onChange(value)
              }}
            >
              {Object.entries(props.enumOptions).map(([key, data]) => {
                const icon = data.icon?.({ marginRight: 2 })
                return (
                  <ToggleButton
                    key={key}
                    value={key}
                    sx={{ display: 'flex', gap: 2 }}
                  >
                    {icon}
                    <span>{data.displayString}</span>
                  </ToggleButton>
                )
              })}
            </ToggleButtonGroup>
          </>
        )
      }}
    />
  )
}

const ButtonStyled = styledWithConditionalProps(Button)<
  ButtonProps & { $selected?: boolean }
>(({ $selected, theme }) => ({
  borderWidth: 1,
  borderStyle: 'solid',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  borderRadius: 8,
  '&:hover': {
    // borderColor: theme.palette.secondary.main,
    backgroundColor: theme.palette.background.paper,
  },
  '& > *': {
    fontSize: '9pt',
  },
  ...($selected
    ? {
        // borderColor: theme.palette.secondary.main,
        backgroundColor: theme.palette.success.light,
        color: 'white',
      }
    : {}),
}))

export const AutocompleteItem = styled(MenuItem)((props: any) => {
  const { theme, ['aria-selected']: selected } = props
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    backgroundColor: selected && `${theme.colors.blue.B50} !important`,
    '&:hover': {
      backgroundColor: `${theme.colors.blue.B50} !important`,
    },
    '&.Mui-selected': {
      backgroundColor: `${theme.colors.blue.B50} !important`,
      '&:hover': {
        backgroundColor: `${theme.colors.blue.B50} !important`,
      },
    },
    '& .MuiAutocomplete-option': {
      backgroundColor: `${theme.colors.blue.B50} !important`,
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#fff',
      '&:hover': {
        backgroundColor: `${theme.colors.blue.B50} !important`,
      },
    },
  }
})

export const SelectWrapper = <T extends FieldValues>(props: {
  propName: FieldPath<T>
  control: Control<T>
  rules?: Exclude<
    RegisterOptions,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >
  label?: string
  fullWidth?: boolean
  size?: 'small' | 'medium'
  error?: boolean
  disabled?: boolean
  options: { key: string; value: string }[]
  textFieldProps?: Omit<SelectProps, 'value' | 'onChange'>
  onItemSelected?: (item: T) => void
  convertValue?: (key?: string | null) => any
  convertBack?: (val: any) => string | null
}) => {
  return (
    <Controller
      control={props.control}
      name={props.propName}
      rules={props.rules}
      render={({ field: { onChange, value } }) => {
        const select = (
          <Select
            disabled={props.disabled}
            size={props.size || 'small'}
            required={!!props.rules?.required}
            fullWidth={props.fullWidth ?? true}
            error={props.error}
            label={props.label}
            {...props.textFieldProps}
            value={props.convertBack ? props.convertBack(value) : value || ''}
            onChange={(e) => {
              onChange(
                props.convertValue
                  ? props.convertValue(e.target.value as string)
                  : e.target.value,
              )
              props.onItemSelected?.(e.target.value as T)
            }}
          >
            {props.options.map((o) => (
              <MenuItem key={o.key} value={o.key}>
                {o.value}
              </MenuItem>
            ))}
          </Select>
        )

        if (!props.label) {
          return select
        }

        return (
          <FormControl
            size={props.size}
            required={!!props.rules?.required}
            variant={props.textFieldProps?.variant || 'outlined'}
            fullWidth={props.fullWidth}
            error={props.error}
          >
            <InputLabel>{props.label}</InputLabel>
            {select}
          </FormControl>
        )
      }}
    />
  )
}

interface MaskTextFieldProps {
  disabled?: boolean
  label?: string
  fullWidth?: boolean
  error?: boolean
  rules?: Exclude<
    RegisterOptions,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >
  textFieldProps?: Omit<TextFieldProps, 'value' | 'onChange'>
  InputProps?: Partial<InputProps>
  defaultValue?: unknown
  mask?: string | Array<string | RegExp>
  placeholder?: string
  autoFocus?: boolean
  onChange: (e: any) => void
  value: any
}

export const MaskedTextFieldWrapper = <T extends FieldValues>(
  props: Omit<MaskTextFieldProps, 'value' | 'onChange'> & {
    propName: FieldPath<T>
    control: Control<T>
    rules?: Exclude<
      RegisterOptions,
      'valueAsNumber' | 'valueAsDate' | 'setValueAs'
    >
    label?: string
    fullWidth?: boolean
    error?: boolean
    textFieldProps?: Omit<SelectProps, 'value' | 'onChange'>
  },
) => {
  const { propName, control, rules, ...rest } = props
  return (
    <Controller
      rules={rules}
      render={({ field: { value, onChange } }) => (
        <MaskTextField
          {...rest}
          value={value}
          onChange={onChange}
          rules={rules}
        />
      )}
      name={props.propName}
      control={props.control}
    />
  )
}

export const MaskTextField = (props: MaskTextFieldProps) => {
  const commonProps = {
    disabled: props.disabled,
    value: props.value,
    onChange: props.onChange,
  }

  return (
    <ReactInputMask
      required={!!props.rules?.required}
      alwaysShowMask={true}
      mask={props.mask!}
      {...commonProps}
    >
      <CustomTextInput
        label={props.label}
        type={props.textFieldProps?.type}
        required={!!props.rules?.required}
        fullWidth={true}
        size='small'
        error={props.error}
        originalOnChange={true}
        {...(props.mask ? { disabled: props.disabled } : commonProps)}
        placeholder={props.placeholder}
        InputProps={{
          style: { lineHeight: '1.75em' },
          ...props.InputProps,
          inputProps: {
            multiple: true,
            max: props.rules?.max,
            min: props.rules?.min,
            maxLength: props.rules?.maxLength,
            minLength: props.rules?.minLength,
          },
        }}
      />
    </ReactInputMask>
  )
}

export const AsyncIconButton = ({
  action,
  icon,
  title,
  color,
  disabled,
}: {
  action: (e: any) => void | Promise<void>
  icon: SvgIconComponent
  title: string
  color?: IconButtonTypeMap['props']['color']
  disabled?: boolean
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const Icon = icon

  return (
    <div style={{ position: 'relative' }}>
      <Tooltip title={title}>
        <IconButton
          color={color}
          disabled={isLoading || disabled}
          onClick={async (e) => {
            setIsLoading(true)
            try {
              await action(e)
            } catch (e) {
              alert('An error occurred')
            } finally {
              setIsLoading(false)
            }
          }}
        >
          {!isLoading ? (
            <Icon />
          ) : (
            <div style={{ width: 24 }}>
              <CircularProgress size={18} />
            </div>
          )}
        </IconButton>
      </Tooltip>
    </div>
  )
}

export const FabLoadingButton = ({
  action,
  icon,
  title,
  color,
  disabled,
  style,
  disabledText,
}: {
  action: (e: any) => void | Promise<void>
  icon?: SvgIconComponent
  title: string
  color?: PropTypes.Color
  disabled?: boolean
  style?: CSSProperties
  disabledText?: string
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const Icon = icon

  return (
    <div style={{ position: 'relative', ...style }}>
      <Fab
        variant='extended'
        color={color}
        disabled={isLoading || disabled}
        onClick={async (e) => {
          setIsLoading(true)
          try {
            await action(e)
          } catch (e) {
            alert('An error occurred')
          } finally {
            setIsLoading(false)
          }
        }}
      >
        <Tooltip title={disabledText || title}>
          {!isLoading ? (
            <span style={{ display: 'flex' }}>
              {title}
              {!!Icon && <Icon style={{ marginLeft: 6 }} />}
            </span>
          ) : (
            <div style={{ width: 24 }}>
              <CircularProgress size={18} />
            </div>
          )}
        </Tooltip>
      </Fab>
    </div>
  )
}
