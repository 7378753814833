import Logo from './logo'
import gmailIcon from '/gmail.svg'
import outlookIcon from '/outlook.svg'

export default function OnboardingVerify() {
  return (
    <div className='flex min-h-screen flex-col items-center justify-center gap-5'>
      <div className='max-w-md rounded-lg px-4 md:p-10 md:shadow-[0_0_14px_0_rgba(0,0,0,.1)]'>
        <Logo className='mx-auto mb-4 w-16' />
        <p className='text-center text-2xl/normal font-bold'>Welcome to a2b</p>
        <p className='mb-9 text-center text-2xl/normal md:font-medium'>
          Check your email!
        </p>

        <p className='mb-6 text-pretty text-center text-sm/normal'>
          A link to verify your account has been sent to your email.
        </p>

        <div className='flex gap-2 max-sm:flex-col'>
          <a
            href='https://gmail.com'
            target='_blank'
            rel='noreferrer noopener'
            className='flex flex-1 items-center justify-center gap-2.5 rounded-lg bg-dark-bg px-4 py-2.5 text-sm/normal text-white'
          >
            <img src={gmailIcon} />
            Open Gmail
          </a>
          <a
            href='https://outlook.com'
            target='_blank'
            rel='noreferrer noopener'
            className='flex flex-1 items-center justify-center gap-2.5 rounded-lg bg-dark-bg px-4 py-2.5 text-sm/normal text-white'
          >
            <img src={outlookIcon} />
            Open Outlook
          </a>
        </div>
      </div>

      <p className='text-pretty px-4 text-center text-sm/normal text-[#979595]'>
        Can&apos;t find the email? Check your spam folder.
      </p>
    </div>
  )
}
