import { CreateMUIStyled } from '@mui/system/createStyled'
import { styled, Theme } from '@mui/material'

export const styledWithConditionalProps: CreateMUIStyled<Theme> = (
  component: any,
  options: any = {},
) => {
  return styled(component, {
    ...options,
    shouldForwardProp: (propName: PropertyKey) => {
      return (
        !propName.toString().startsWith('$') &&
        (options.shouldForwardProp ? options.shouldForwardProp(propName) : true)
      )
    },
  })
}
