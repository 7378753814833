import { To } from '@remix-run/router/history'
import {
  DependencyList,
  Dispatch,
  EffectCallback,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useLocation, useNavigate as useNav } from 'react-router-dom'

// export const useQuery = () => {
//   return new URLSearchParams(useSearchParams())
// }

export const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

export const useNavigate = () => {
  // const router = useRouter()
  const navigate = useNav()
  const pathName = useLocation().pathname

  return (route: To) => {
    if (typeof route === 'string') {
      return navigate(route)
    }
    return navigate({
      ...route,
      pathname: route.pathname || pathName,
    })
    // return navigate(`${context || route.pathname || pathName}?${route.search}`)
  }
}

export const useIsClient = () => {
  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  return isClient
}

export const useStateFromProp = <T>(
  initialValue: T,
): [T, Dispatch<SetStateAction<T>>] => {
  const [value, setValue] = useState<T>(initialValue)

  useEffect(() => setValue(initialValue), [initialValue])

  return [value, setValue]
}

export const useEffectNotFirstTime = (
  effect: EffectCallback,
  deps?: DependencyList,
) => {
  const [firstTime, setFirstTime] = useState(true)
  useEffect(() => {
    if (firstTime) {
      setFirstTime(false)
      return
    }

    return effect()
  }, deps)
}

export const useEffectFirstTime = (
  effect: EffectCallback,
  deps?: DependencyList,
) => {
  const [firstTime, setFirstTime] = useState(true)
  useEffect(() => {
    if (firstTime) {
      setFirstTime(false)
      return effect()
    }

    return
  }, deps)
}

export const useIsTouchScreen = () => {
  const isTouchScreen = useMemo(
    () => window.matchMedia('(pointer: coarse)').matches,
    [],
  )

  return isTouchScreen
}
