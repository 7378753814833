export interface Config {
  env: 'prod' | 'sandbox' | 'development'
  serviceUrl: string
  loginUrl: string
  crispWebsiteId: string
  uberClientId: string
  sentryDsn: string
}

export const config: Config = {
  env: `${(import.meta.env['VITE_A2B_ENV'] as Config['env']) ?? 'development'}`,
  serviceUrl: `${import.meta.env['VITE_A2B_SERVICE_URL'] ?? ''}`,
  loginUrl: `${import.meta.env['VITE_A2B_STORE_AUTH_URL'] ?? ''}`,
  crispWebsiteId: `${import.meta.env['VITE_A2B_CRISP_WEBSITE_ID'] ?? ''}`,
  uberClientId: import.meta.env.VITE_A2B_POS_UBER_CLIENT_ID ?? '',
  sentryDsn: (import.meta.env['VITE_A2B_SENTRY'] as string) ?? '',
}
