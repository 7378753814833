import Decimal from 'decimal.js'

export const formatPriceStr = ({
  d,
  cents,
  showZero,
  showPlus,
  hideCurrency,
  showComma,
}: {
  d: string | number | null | undefined | Decimal
  showZero?: boolean
  showPlus?: boolean
  cents?: boolean
  hideCurrency?: boolean
  showComma?: boolean
}) => {
  const dec =
    d instanceof Decimal
      ? d
      : d === null || d === undefined
        ? undefined
        : new Decimal(d)

  const numberStr = dec && (cents ? dec.div(100) : dec).abs().toFixed(2)
  const formattedNumber =
    numberStr && (showComma ? numberWithCommas(numberStr) : numberStr)

  return dec && (showZero || !dec?.isZero())
    ? `${dec.isNegative() ? '-' : dec.isPositive() && showPlus ? '+' : ''}${hideCurrency ? '' : '$'}${formattedNumber}`
    : ''
}

const numberWithCommas = (x: string) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
