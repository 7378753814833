import { observer } from 'mobx-react'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useStore } from '../root-store'
import { AuthState } from './auth-store'
import Loader from '../components/loader'
import { ErrorView } from '../components/async-fetch-wrapper'
import { useEffect } from 'react'

const PrivateRoute = observer(() => {
  const {
    authStore: { authState, login },
  } = useStore()

  if (authState === AuthState.SIGNED_IN) {
    return <Outlet />
  }

  if (authState === AuthState.SIGNED_OUT) {
    return <Navigate to='/login' />
  }
  if (authState === AuthState.ERROR_SIGNING_IN) {
    return <ErrorView onReload={login} />
  }
  return <Loader />
})

const PrivateAdminUserRoute = observer(() => {
  const {
    authStore: { authState, login, claims },
  } = useStore()

  if (authState === AuthState.SIGNED_IN && claims?.user?.is_admin) {
    return <Outlet />
  }

  if (
    authState === AuthState.SIGNED_OUT ||
    (!claims?.user?.is_admin && authState === AuthState.SIGNED_IN)
  ) {
    return (
      <NavigateToLoginWithRedirect
        forceLogout={authState === AuthState.SIGNED_IN}
      />
    )
  }

  if (authState === AuthState.ERROR_SIGNING_IN) {
    return <ErrorView onReload={login} />
  }
  return <Loader />
})

export const NavigateToLoginWithRedirect = ({
  forceLogout,
}: {
  forceLogout?: boolean
}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { authStore } = useStore()

  useEffect(() => {
    authStore.clearNavigateToLogin(`${location.pathname}${location.search}`)
    navigate('/login')

    if (forceLogout) {
      authStore.signOut()
    }
  }, [])

  return null
}

export { PrivateRoute, PrivateAdminUserRoute }
