import React, { useEffect } from 'react'

export const Page = (props: {
  title: string
  children?:
    | React.ReactChild
    | React.ReactChild[]
    | JSX.Element
    | JSX.Element[]
    | Element
    | string
    | null
}) => {
  useEffect(() => {
    document.title = `a2b ${props.title}` || ''

    return () => {
      if (document.title === `a2b ${props.title}`) {
        document.title = 'a2b'
      }
    }
  }, [props.title])
  return <>{props.children}</>
}
