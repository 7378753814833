import { cn } from '@/utils'
import { SelectProps } from '@radix-ui/react-select'
import {
  Control,
  Controller,
  FieldPath,
  FieldValues,
  Path,
  PathValue,
} from 'react-hook-form'
import { Select, SelectContent, SelectTrigger, SelectValue } from './select'

interface Props<T extends FieldValues>
  extends Omit<SelectProps, 'value' | 'onChange'> {
  control: Control<T>
  name: FieldPath<T>
  placeholder: string
  displayValue?: (value: PathValue<T, Path<T>>) => string | undefined
  triggerClassName?: string
  contentClassName?: string
}

export default function FormSelect<T extends FieldValues>({
  control,
  name,
  children,
  placeholder,
  displayValue,
  triggerClassName,
  contentClassName,
  ...props
}: Props<T>) {
  return (
    <div className='w-full'>
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <>
            <Select
              value={value ?? undefined}
              onValueChange={onChange}
              {...props}
            >
              <SelectTrigger
                className={cn('w-full gap-2 text-start', triggerClassName)}
                placeholder={placeholder}
              >
                <SelectValue>
                  {displayValue && <>{displayValue(value)}</>}
                </SelectValue>
              </SelectTrigger>
              <SelectContent className={contentClassName}>
                {children}
              </SelectContent>
            </Select>
            {error && (
              <p className='ml-1 mt-1 text-xs text-red-500'>{error.message}</p>
            )}
          </>
        )}
      />
    </div>
  )
}
