import { LiveOrder, PipeStatus } from '../orders/models'
import { Grid, IconButton, styled } from '@mui/material'
import { OrderCube } from './order-cube'
import { useOrderPopup } from '../order-details/hooks'
import CreateOrderDialog from '../orders/create-order-dialog'
import { useStore } from '../root-store'
import { observer } from 'mobx-react'
import { Add } from '@mui/icons-material'
import { useCallback, useRef } from 'react'
import { useHandleAction } from './order-actions'
import { BackdropPresenter } from '../components/backdrop'
import { cn } from '@/utils'

const PipeStatuses: Record<PipeStatus, number> = {
  [PipeStatus.Scheduled]: 5,
  [PipeStatus.Pending]: 5,
  [PipeStatus.InProcess]: 5,
  [PipeStatus.Ready]: 2,
}

const Tab = ({
  status,
  orders,
  reloadOrders,
  allowDrop,
}: {
  status: PipeStatus
  orders: { [key in PipeStatus]?: LiveOrder[] }
  reloadOrders: () => void
  allowDrop: boolean
}) => {
  const { authStore, dialogStore, generalStore, printStore } = useStore()
  const { toggleOpenOrder } = useOrderPopup()
  const { handleAction, isLoading } = useHandleAction()
  const dragEvents = useDragEvents()

  const onDropOrder = useCallback(
    (orderId: string, sourceStatus: PipeStatus) => {
      const order = orders[sourceStatus]?.find((o) => o.id === orderId)

      if (order?.drag_action) {
        return handleAction({
          order,
          action: order.drag_action.action,
          dialogStore,
          printStore,
          reloadOrder: reloadOrders,
        })
      }
    },
    [dialogStore, handleAction, orders, reloadOrders],
  )

  return (
    <>
      <BackdropPresenter open={isLoading} size={40} absolute={true} />
      <Grid
        item
        xs={12}
        md={PipeStatuses[status as PipeStatus]}
        key={status}
        display='flex'
        flexDirection='column'
        gap={2}
        onDrop={
          (allowDrop &&
            ((e) => {
              const data = dragEvents.onDrop(e, status as PipeStatus)
              if (!data) {
                return
              }
              return onDropOrder(data.orderId, data.sourcePipe)
            })) ||
          undefined
        }
        onDragOver={(e) => {
          dragEvents.onDragOver(e, status as PipeStatus)
          if (!allowDrop) {
            e.dataTransfer.dropEffect = 'none'
          }
        }}
        onDragLeave={(allowDrop && dragEvents.onDragLeave) || undefined}
        onDragEnter={
          (allowDrop &&
            ((e) => dragEvents.onDragEnter(e, status as PipeStatus))) ||
          undefined
        }
        onDragEnd={(event) => {
          event.currentTarget.style.opacity = '1'
          const draggable = document.getElementById('current-draggable')
          if (draggable?.parentNode) {
            draggable.parentNode.removeChild(draggable)
          }
        }}
        sx={{ overflow: 'hidden' }}
      >
        <div className='mb-3 flex items-center justify-between px-2'>
          <div className='flex gap-3.5'>
            {status === PipeStatus.Pending && (
              <>
                <button
                  className='text-lg font-medium leading-normal text-[#DFDFDF]/60'
                  onClick={() => generalStore.setShowScheduledTab(true)}
                >
                  {PipeStatus.Scheduled} (
                  {orders[PipeStatus.Scheduled]?.length ?? 0})
                </button>
                <span className='h-[1.6875rem] w-0.5 rounded-full bg-[#F2F2F2]/60'></span>
              </>
            )}
            <h6 className='text-lg font-medium leading-normal text-white'>
              {status} ({orders[status]?.length ?? 0})
            </h6>
            {status === PipeStatus.Scheduled && (
              <>
                <span className='h-[1.6875rem] w-0.5 rounded-full bg-[#F2F2F2]/60'></span>
                <button
                  className='text-lg font-medium leading-normal text-[#DFDFDF]/60'
                  onClick={() => generalStore.setShowScheduledTab(false)}
                >
                  {PipeStatus.Pending} (
                  {orders[PipeStatus.Pending]?.length ?? 0})
                </button>
              </>
            )}
          </div>
          {(status === PipeStatus.Pending || status === PipeStatus.Scheduled) &&
            !!authStore.currentUserStore?.store_id && (
              <IconButton
                sx={{ color: 'white', padding: 0 }}
                size='small'
                onClick={() => {
                  return dialogStore.showDialogAsync(CreateOrderDialog)
                }}
              >
                <Add />
              </IconButton>
            )}
        </div>
        <div
          className={cn(
            'flex flex-1 flex-col overflow-auto pb-9',
            status === PipeStatus.Ready ? 'gap-2' : 'gap-[1.125rem]',
          )}
        >
          {orders[status as PipeStatus]?.map((o) => (
            <OrderCube
              key={o.id}
              order={o}
              reloadOrders={reloadOrders}
              onExpand={() => toggleOpenOrder(o.id)}
              minimized={status === PipeStatus.Ready}
            />
          ))}
        </div>
      </Grid>
    </>
  )
}

export const LiveOrdersTablet = observer(
  ({
    orders,
    reloadOrders,
  }: {
    orders: { [key in PipeStatus]?: LiveOrder[] }
    reloadOrders: () => void
  }) => {
    const { generalStore } = useStore()
    const hasOrders = Object.values(orders).some((o) => o?.length)
    return (
      <PipeGrid $hasOrders={hasOrders} container gap={2}>
        {generalStore.showScheduledTab ? (
          <Tab
            status={PipeStatus.Scheduled}
            orders={orders}
            reloadOrders={reloadOrders}
            allowDrop={false}
          />
        ) : (
          <Tab
            status={PipeStatus.Pending}
            orders={orders}
            reloadOrders={reloadOrders}
            allowDrop={false}
          />
        )}
        <Tab
          status={PipeStatus.InProcess}
          orders={orders}
          reloadOrders={reloadOrders}
          allowDrop
        />
        <Tab
          status={PipeStatus.Ready}
          orders={orders}
          reloadOrders={reloadOrders}
          allowDrop
        />
      </PipeGrid>
    )
  },
)

const PipeGrid = styled(Grid, {
  shouldForwardProp: (prop: string) => !prop.startsWith('$'),
})<{ $hasOrders: boolean }>(({ theme, $hasOrders }) => ({
  overflow: 'hidden',
  position: 'relative',
  flexWrap: 'nowrap',
  height: $hasOrders ? '100%' : 'unset',

  [theme.breakpoints.down('md')]: {
    flexWrap: 'wrap',
    overflow: 'auto',
  },
}))

export const useDragEvents = () => {
  const draggedId = 'dragged-item'
  const sourceRef = useRef<any>()

  const dragStart = (
    event: React.DragEvent<HTMLDivElement>,
    order: LiveOrder,
  ) => {
    if (!order.drag_action) {
      return
    }

    sourceRef.current = event.currentTarget
    event.currentTarget.style.opacity = '0.4'
    event.dataTransfer.effectAllowed = 'all'
    event.dataTransfer.setData(draggedId, order.id)
    event.dataTransfer.setData('sourcePipe'.toLowerCase(), order.pipe_status)
    event.dataTransfer.setData(`order-${order.id}`.toLowerCase(), order.id)
    event.dataTransfer.setData(
      `target-${order.drag_action?.target_pipe_status}`.toLowerCase(),
      order.id,
    )
  }

  const onDrop = (
    event: React.DragEvent<HTMLDivElement>,
    pipeStatus: PipeStatus,
  ) => {
    event.preventDefault()

    if (
      !event.dataTransfer.types.includes(draggedId) ||
      !event.dataTransfer.types.includes(`target-${pipeStatus}`.toLowerCase())
    ) {
      return
    }

    if (event.currentTarget === sourceRef.current) {
      return
    }

    event.currentTarget.classList.remove('drag-enter')
    return {
      orderId: event.dataTransfer.getData(draggedId),
      sourcePipe: event.dataTransfer.getData('sourcePipe') as PipeStatus,
    }
  }

  const onDragOver = (
    event: React.DragEvent<HTMLDivElement>,
    pipeStatus: PipeStatus,
  ) => {
    event.preventDefault()

    if (
      event.currentTarget === sourceRef.current ||
      !event.dataTransfer.types.includes(`target-${pipeStatus}`.toLowerCase())
    ) {
      return
    }

    event.currentTarget.classList.add('drag-enter')
    event.dataTransfer.dropEffect = 'copy'
  }
  const onDragEnter = (
    event: React.DragEvent<HTMLDivElement>,
    pipeStatus: PipeStatus,
  ) => {
    if (
      event.dataTransfer.types.includes(`target-${pipeStatus}`.toLowerCase())
    ) {
      event.preventDefault()
      event.currentTarget.classList.add('drag-enter')
    }
  }

  const onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.stopPropagation()
    event.currentTarget.classList.remove('drag-enter')
  }

  return { dragStart, onDragEnter, onDragLeave, onDragOver, onDrop }
}
