import Spinner from '@/components/spinner'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { useStore } from '../root-store'
import GmailIcon from '/gmail.svg'
import OutlookIcon from '/outlook.svg'
import Logo from '/a2b-gray.svg'
import {
  Alert,
  createTheme,
  CssBaseline,
  Grid,
  Link,
  styled,
  TextField,
  Typography,
  Divider,
  ThemeProvider,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useQuery } from '../utils/hooks'
import { auth } from './firebase'
import theme from '../theme'
import { Box } from '@mui/system'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { ContainerTypeMap } from '@mui/material/Container/Container'
import { TextFieldProps } from '@mui/material/TextField/TextField'
import { USER_NOT_FOUND } from './auth-store'
import { useNavigate } from 'react-router-dom'
import * as Sentry from '@sentry/react'

const DISPLAY_QUERY_PARAM = 'display'

enum DisplayStatus {
  LOGIN = 'Please log in',
  FORGOT_PASSWORD = 'Forgot password',
  RESET_PASSWORD = 'Reset Password',
  EMAIL_MAGIC_LINK = 'Sign in with Email',
}

const LoginMethods: { [k in DisplayStatus]: string } = {
  [DisplayStatus.FORGOT_PASSWORD]: 'forgot-password',
  [DisplayStatus.LOGIN]: 'email-and-password',
  [DisplayStatus.RESET_PASSWORD]: 'reset-password',
  [DisplayStatus.EMAIL_MAGIC_LINK]: 'email',
}

const ReverseLoginMethods = Object.fromEntries(
  Object.entries(LoginMethods).map(([key, value]) => [value, key]),
)

const sendRecoveryLinkTxt = 'Send Recovery Link'
const loginTxt = 'Login'
const newPasswordText = 'Submit'
const magicLinkText = 'Submit'

export const validateEmailFormat = (email: string) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // eslint-disable-line
  )
}

export const Login = observer((props: any) => {
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [loadingMagicLink, setLoadingMagicLink] = useState(false)
  const [error, setIsError] = useState(false)
  const [password, setPassword] = useState('')
  const [verifyPassword, setVerifyPassword] = useState('')
  const [invalidEmailError, setInvalidEmailError] = useState('')
  const [bottomButtonText, setButtonText] = useState(loginTxt)
  const query = useQuery()
  const [displayState, setDisplayState] = useState(
    ReverseLoginMethods[query.get('display') || ''] || DisplayStatus.LOGIN,
  )
  const [activationCode, setActivationCode] = useState('')
  const { authStore } = useStore()
  const [link, setLink] = useState('')
  const [emailSent, setEmailSent] = useState(false)
  const navigate = useNavigate()

  const loginThrowEmailLink = async () => {
    setLoadingMagicLink(auth.isSignInWithEmailLink(link))

    const loading = await authStore.signInWithMagicLink(
      query.get('email')?.toString() ||
        (localStorage.getItem('emailForSignIn') as string) ||
        '',
      link,
    )
    setLoadingMagicLink(loading)
  }

  useEffect(() => {
    loginThrowEmailLink()
  }, [link])

  useEffect(() => {
    if (query.get('mode') === 'signIn') {
      setLink(window.location.href)
      loginThrowEmailLink()
    }
  }, [query.get('mode')])

  const sendEmailLink = async () => {
    authStore.setError(null)

    const success = await authStore.sendMagicLink(email)
    setEmailSent(success)

    if (!success) {
      authStore.setError('Email was not sent')
    }

    setLoading(false)
  }

  const onClick = async (event: any) => {
    event.preventDefault()
    setInvalidEmailError('')

    if (isDisabled()) {
      return
    }

    if (email && !validateEmailFormat(email)) {
      setInvalidEmailError('Invalid email address')
      return
    }

    setLoading(true)
    authStore.setError(null)
    switch (displayState) {
      case DisplayStatus.LOGIN: {
        await login()
        break
      }
      case DisplayStatus.FORGOT_PASSWORD: {
        await forgotPassword()
        break
      }
      case DisplayStatus.RESET_PASSWORD: {
        await confirmPasswordResetPassword()
        break
      }
      case DisplayStatus.EMAIL_MAGIC_LINK: {
        await sendEmailLink()
        break
      }
      default: {
        break
      }
    }
  }
  useEffect(() => {
    if (activationCode.length) {
      onResetPasswordMode()
    }
  }, [activationCode, email])

  useEffect(() => {
    authStore.setError(null)
    setIsError(!error)
  }, [displayState])

  const onResetPasswordMode = async () => {
    const currentEmail = await authStore.verifyPasswordResetCode(activationCode)
    if (currentEmail.length) {
      setEmail(currentEmail)
      setButtonText(newPasswordText)
      setDisplayState(DisplayStatus.RESET_PASSWORD)
      query.set(DISPLAY_QUERY_PARAM, LoginMethods[DisplayStatus.RESET_PASSWORD])
      navigate({
        search: query.toString(),
      })
    }
  }

  const onForgotPassword = () => {
    setDisplayState(DisplayStatus.FORGOT_PASSWORD)
    setButtonText(sendRecoveryLinkTxt)
    query.set(DISPLAY_QUERY_PARAM, LoginMethods[DisplayStatus.FORGOT_PASSWORD])
    navigate({
      search: query.toString(),
    })
  }

  const confirmPasswordResetPassword = async () => {
    await authStore.confirmPasswordReset(
      email,
      activationCode,
      password,
      verifyPassword,
    )
    setLoading(false)
  }

  const login = async () => {
    try {
      await auth.signInWithEmailAndPassword(email, password)
    } catch (error) {
      Sentry.captureException(error)

      authStore.setError('Invalid Credentials')
    } finally {
      setLoading(false)
    }
  }

  const forgotPassword = async () => {
    if (await authStore.forgotPassword(email)) {
      setDisplayState(DisplayStatus.LOGIN)
      setButtonText(loginTxt)
      setEmailSent(true)
    }
    setLoading(false)
  }

  const isDisabled = (): boolean => {
    switch (displayState) {
      case DisplayStatus.LOGIN: {
        return !(password && email)
      }
      case DisplayStatus.FORGOT_PASSWORD: {
        return !email
      }
      case DisplayStatus.RESET_PASSWORD: {
        return !(email && password && verifyPassword)
      }
      case DisplayStatus.EMAIL_MAGIC_LINK: {
        return !email?.length
      }
      default:
        return true
    }
  }

  if (
    query.get('oobCode') &&
    query.get('mode') === 'resetPassword' &&
    !activationCode
  ) {
    setActivationCode(query.get('oobCode')!)
  }

  useEffect(() => {
    if (authStore.error) {
      setIsError(true)
    }
  }, [authStore.error])

  useEffect(() => {
    if (!link || !email) {
      setLink(window.location.href)
      if (query.get('email')) {
        setEmail(query.get('email')!.toLowerCase())
      }
    }
  }, [query.get('link'), query.get('email')])

  useEffect(() => {
    const display = query.get(DISPLAY_QUERY_PARAM)

    if (!display && displayState === DisplayStatus.LOGIN && !emailSent) {
      query.set(DISPLAY_QUERY_PARAM, LoginMethods[DisplayStatus.LOGIN])
      navigate({ search: query.toString() })
    }

    if (display === LoginMethods[DisplayStatus.EMAIL_MAGIC_LINK]) {
      setButtonText(magicLinkText)
      setDisplayState(DisplayStatus.EMAIL_MAGIC_LINK)
    } else if (display === LoginMethods[DisplayStatus.FORGOT_PASSWORD]) {
      setButtonText(sendRecoveryLinkTxt)
      setDisplayState(DisplayStatus.FORGOT_PASSWORD)
    }
  }, [])

  useEffect(() => {
    if (emailSent) {
      query.delete(DISPLAY_QUERY_PARAM)
      navigate({ search: query.toString() })
    }
  }, [emailSent])

  useEffect(() => {
    if (authStore.isAuthenticated) {
      const referrer =
        props.location?.state?.referrer ||
        authStore.getAndClearRedirectUrl() ||
        '/'
      navigate(referrer)
    }
  }, [authStore.isAuthenticated])

  if (authStore.isAuthenticated) {
    return null
  }

  const inputProps: Partial<TextFieldProps> = {
    size: 'small',
    color: 'secondary',
    variant: 'outlined',
    margin: 'normal',
    required: true,
    fullWidth: true,
  }

  if (loadingMagicLink) {
    return (
      <div className='flex h-full items-center justify-center'>
        <Spinner className='w-10' />
      </div>
    )
  }

  return (
    <>
      <CssBaseline />
      <LoginContent>
        <ThemeProvider theme={loginTheme}>
          {error && authStore.error && authStore.error !== USER_NOT_FOUND && (
            <Alert sx={{ position: 'absolute', bottom: 20 }} severity='error'>
              {authStore.error || null}
            </Alert>
          )}
          <Box
            sx={{
              position: 'relative',
              overflow: 'hidden',
              borderRadius: 2,
              margin: 4,
            }}
          >
            <LoginBackground width={200} height={40} />

            <Container>
              <img src={Logo} style={{ height: 100, width: 100 }} />
              <Typography variant='h5' sx={{ marginBottom: 4 }}>
                Welcome to a2b
              </Typography>
              {emailSent ? (
                <EmailButtonsLinks
                  infoText={`A magic link to login to your account has been sent to ${email}`}
                />
              ) : (
                <Box
                  display='flex'
                  flexDirection='column'
                  alignItems='center'
                  width='100%'
                >
                  <Typography variant='body1'>{displayState}</Typography>

                  <form style={{ maxWidth: 370 }} onSubmit={onClick}>
                    <FormInput
                      {...inputProps}
                      value={email}
                      onChange={(e) => setEmail(e.target.value.toLowerCase())}
                      id='email'
                      label='Email Address'
                      name='email'
                      autoComplete='email'
                      autoFocus
                      helperText={
                        invalidEmailError ? (
                          <Box>
                            <span className='text-red-500'>
                              {invalidEmailError}
                            </span>
                          </Box>
                        ) : authStore.error === USER_NOT_FOUND ? (
                          <Box>
                            <span className='text-red-500'>User not found</span>
                          </Box>
                        ) : null
                      }
                      disabled={displayState === DisplayStatus.RESET_PASSWORD}
                    />
                    {displayState === DisplayStatus.LOGIN && (
                      <FormInput
                        {...inputProps}
                        name='password'
                        label='Password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type='password'
                        id='password'
                        autoComplete='current-password'
                      />
                    )}
                    {displayState === DisplayStatus.RESET_PASSWORD && (
                      <>
                        <FormInput
                          {...inputProps}
                          name='password'
                          label='Password'
                          value={password}
                          onChange={(event) => {
                            setPassword(event.target.value)
                          }}
                          type='password'
                          id='password'
                          autoComplete='new-password'
                        />

                        <FormInput
                          {...inputProps}
                          name='verify_password'
                          label='Verify Password'
                          value={verifyPassword}
                          onChange={(event) => {
                            setVerifyPassword(event.target.value)
                          }}
                          type='password'
                          id='verify_password'
                          autoComplete='verify-password'
                        />
                      </>
                    )}

                    <LoadingButton
                      size='large'
                      loading={loading}
                      // disabled={isDisabled()}
                      type='submit'
                      fullWidth
                      variant='contained'
                      color='secondary'
                      sx={{
                        margin: loginTheme.spacing(2, 0, 0),
                      }}
                    >
                      {bottomButtonText}
                    </LoadingButton>

                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginTop: loginTheme.spacing(3),
                      }}
                    >
                      <Typography
                        color='secondary'
                        variant='caption'
                        sx={{
                          cursor: 'pointer',
                          '&:hover': { textDecorationLine: 'underline' },
                        }}
                        onClick={() => {
                          setDisplayState(
                            displayState === DisplayStatus.EMAIL_MAGIC_LINK
                              ? DisplayStatus.LOGIN
                              : DisplayStatus.EMAIL_MAGIC_LINK,
                          )
                          setButtonText(
                            displayState === DisplayStatus.EMAIL_MAGIC_LINK
                              ? loginTxt
                              : magicLinkText,
                          )
                          query.set(
                            DISPLAY_QUERY_PARAM,
                            displayState === DisplayStatus.LOGIN
                              ? LoginMethods[DisplayStatus.EMAIL_MAGIC_LINK]
                              : LoginMethods[DisplayStatus.LOGIN],
                          )
                          navigate({
                            search: query.toString(),
                          })
                        }}
                      >
                        {displayState === DisplayStatus.EMAIL_MAGIC_LINK
                          ? 'Or log in with a password'
                          : 'Or log in with an email'}
                      </Typography>
                      {displayState === DisplayStatus.LOGIN && (
                        <Typography
                          color='textSecondary'
                          variant='caption'
                          sx={{
                            cursor: 'pointer',
                            '&:hover': { textDecorationLine: 'underline' },
                          }}
                          onClick={onForgotPassword}
                        >
                          Forgot Password?
                        </Typography>
                      )}
                    </div>

                    <Divider
                      sx={{
                        marginTop: 5,
                        marginBottom: 3,
                        // borderColor: 'rgba(0, 0, 0, 0.3)',
                      }}
                    />

                    <Typography variant='caption' sx={{ color: '#979595' }}>
                      By logging in your accept to a2b’s Terms & Conditions
                    </Typography>
                    {/*<Button*/}
                    {/*  size={'large'}*/}
                    {/*  sx={{ marginBottom: loginTheme.spacing(10) }}*/}
                    {/*  fullWidth*/}
                    {/*  href={`https://triadplants.com/pages/account`}*/}
                    {/*  variant={'outlined'}>*/}
                    {/*  New to Triad? Open an account now*/}
                    {/*</Button>*/}
                  </form>
                </Box>
              )}
            </Container>
          </Box>
        </ThemeProvider>
      </LoginContent>
    </>
  )
})

const loginTheme = createTheme({
  ...theme(),
  // palette: {
  //   // mode: 'dark',
  //   // primary: {
  //   //   main: '#FFFFFF',
  //   //   dark: '#FFFFFF',
  //   // },
  //   // secondary: {
  //   //   main: colors.green.G00,
  //   // },
  //   // action: {
  //   //   active: '#FFFFFF',
  //   //   focus: '#FFFFFF',
  //   //   disabledBackground: 'rgba(0,0,0,0.4)',
  //   //   disabled: 'rgba(0, 0, 0, 0.6)',
  //   // },
  //   // text: {
  //   //   secondary: '#FFFFFF',
  //   // },
  // },
})

const LoginBackground = styled(Box)(({ theme }) => ({
  position: 'absolute',
  minHeight: '100%',
  minWidth: '100%',
  maxHeight: '100%',
  maxWidth: '100%',
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: 1,
  opacity: 0.8,
  [theme.breakpoints.down('md')]: {
    position: 'fixed',
    top: 0,
  },
}))

const LoginContent = styled(Box)(() => ({
  backgroundSize: 'cover',
  display: 'flex',
  position: 'fixed',
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  margin: 0,
  height: '100%',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  // color: 'white',
  '& .MuiInputBase-root': {
    // color: 'white',
  },
})) as OverridableComponent<ContainerTypeMap>

export const EmailButtonsLinks = ({ infoText }: { infoText: string }) => {
  const navigate = useNavigate()
  return (
    <Grid style={{ justifyContent: 'center' }} component='div'>
      <Typography align='center' variant='h4' margin={4} mt={3}>
        Check your email!
      </Typography>
      <Typography align='center' style={{ fontWeight: 400 }}>
        {infoText}
      </Typography>
      <Grid container style={{ justifyContent: 'center' }} component='div'>
        <LoadingButton
          loading={false}
          variant='contained'
          color='primary'
          sx={{ margin: 5, height: 45 }}
        >
          <Link
            target='_blank'
            href='https://gmail.com'
            style={{ color: 'black' }}
            underline='none'
          >
            <Grid
              container
              sx={{
                alignItems: 'center',
                justifyContent: 'space-evenly',
                width: 196,
                color: 'white',
              }}
              component='div'
            >
              <img src={GmailIcon} />
              Open Gmail
            </Grid>
          </Link>
        </LoadingButton>
        <LoadingButton
          loading={false}
          variant='contained'
          color='primary'
          sx={{ margin: 5, height: 45 }}
        >
          <Link
            style={{ color: 'black' }}
            target='_blank'
            href='https://outlook.com'
            underline='none'
          >
            <Grid
              component='div'
              sx={{
                alignItems: 'center',
                justifyContent: 'space-evenly',
                width: 196,
                color: 'white',
              }}
              container
            >
              <img src={OutlookIcon} />
              Open Outlook
            </Grid>
          </Link>
        </LoadingButton>
      </Grid>
      <Typography margin={4} align='center' variant='caption'>
        Can&apos;t find your email? Check your spam folder
      </Typography>
      <br />
      <br />
      <Typography
        margin={4}
        onClick={() => {
          navigate(0)
        }}
        variant='caption'
        sx={{
          cursor: 'pointer',
          '&:hover': { textDecorationLine: 'underline' },
        }}
      >
        Back to login
      </Typography>
    </Grid>
  )
}

const FormInput = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(2),
  // borderRadius: 8,
  // '& .MuiOutlinedInput-notchedOutline': {
  //   borderRadius: 8,
  // },
  // '& .MuiOutlinedInput-root': {
  //   height: 42.5,
  //   borderRadius: 8,
  //   backgroundColor: 'rgba(255,255,255,0.4)',
  // },
  // // '& .MuiInputLabel-shrink': {
  // //   // color: 'black',
  // // },
}))

const Container = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  rowGap: 4,
  flexDirection: 'column',
  alignItems: 'center',
  zIndex: 1000,
  padding: 10,
  justifyContent: 'center',
  textAlign: 'center',
  [theme.breakpoints.up('md')]: {
    padding: 15,
    paddingTop: 10,
    paddingLeft: 50,
    paddingRight: 50,
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    border: '1px solid rgb(219, 219, 220)',
    borderRadius: 8,
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 4px 4px 0px',
  },
}))
