import LoadingButton from '@/components/loading-button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { AsyncDialogBaseProps } from '@/dialogs/models'
import { useStore } from '@/root-store'
import { storesService } from '@/stores/store-service'
import { useCallback, useState } from 'react'

export default function OutOfFreeDeliveries({
  reject,
  open,
}: Pick<AsyncDialogBaseProps, 'reject' | 'open'>) {
  const { authStore } = useStore()
  const [loading, setLoading] = useState(false)

  const onClick = useCallback(async () => {
    setLoading(true)
    const response = await storesService.getStoreBillingPortalUrl(
      authStore.currentStoreId!,
    )
    window.open(response.data.billingPortalUrl, '_blank', 'noopener noreferrer')
    setLoading(false)
  }, [authStore.currentStoreId])

  return (
    <Dialog open={open} onOpenChange={() => reject()}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className='mb-2'>Out of Free Deliveries</DialogTitle>
          <DialogDescription>
            Your free deliveries are complete! Add a payment method now to keep
            your orders moving smoothly.
          </DialogDescription>

          <LoadingButton
            loading={loading}
            className='!mt-8 shrink-0 rounded-md bg-[#00C2E8] bg-opacity-60 px-4 py-2.5 text-sm/normal font-medium text-black transition-colors hover:bg-opacity-100'
            onClick={onClick}
          >
            Add Payment Method
          </LoadingButton>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  )
}
