import { ThemeProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { Crisp } from 'crisp-sdk-web'
import { observer } from 'mobx-react'
import { useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Login } from './auth/login'
import { PrivateRoute } from './auth/private-route'
import Onboarding from './components/onboarding'
import OnboardingPassword from './components/onboarding-password'
import { config } from './config'
import { CrispPosition } from './layout/crisp-box'
import { Layout } from './layout/layout'
import { PrintQueue } from './live-orders/print-queue'
import { useStore } from './root-store'
import { Snackbars } from './snackbars/snackbars'
import theme from './theme'

function App() {
  const { authStore } = useStore()

  useEffect(() => {
    if (config.crispWebsiteId) {
      Crisp.configure(config.crispWebsiteId)
      Crisp.setZIndex(999999)
      // Crisp.chat.hide()
    }
    return authStore.subscribe()
  }, [])

  return (
    <ThemeProvider theme={theme()}>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <BrowserRouter>
          <Routes>
            <Route path='/onboarding' element={<Onboarding />} />
            <Route
              path='/onboarding/set-password'
              element={<OnboardingPassword />}
            />
            <Route path='*' element={<PrivateRoute />}>
              <Route path='*' element={<Layout />} />
            </Route>
            <Route path='/login/*' element={<Login />} />
            {/*<Route path={'orders/*'} element={<OrdersRoute />} />*/}
          </Routes>

          <PrintQueue />
          <CustomDialogs />
          <Snackbars />
          {/*<CrispBox />*/}
          <CrispPosition />
        </BrowserRouter>
      </LocalizationProvider>
    </ThemeProvider>
  )
}

// const OverrideTheme = ({ children }: { children: React.ReactNode }) => {
//   return (
//     <BreakpointsSelector
//       xl={children}
//       xs={<ThemeProvider theme={darkTheme}>{children}</ThemeProvider>}
//     />
//   )
// }

const CustomDialogs = observer(() => {
  const { dialogStore } = useStore()
  const { customDialogs } = dialogStore

  return (
    <>
      {Object.entries(customDialogs).map(([id, dialog]) => {
        const Comp = dialog.component
        return <Comp key={id} {...dialog.props} {...dialog.additionalProps} />
      })}
    </>
  )
})

export default App
