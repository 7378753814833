import { observer } from 'mobx-react'
import { useCallback, useState } from 'react'
import { OrderWithDeliveries } from './models'
import { ordersService } from './order-service'
import { Box } from '@mui/material'
import { AsyncFetchWrapper } from '../components/async-fetch-wrapper'
import { OrdersTable } from './orders-table'
import { v4 as uuid } from 'uuid'
import { Section } from './utils'
import { OrderInsights } from './order-insights'
import { useStore } from '../root-store'

export const OrdersPage = observer(() => {
  const [orders, setOrders] = useState<OrderWithDeliveries[]>([])
  const [reloadOrders, setReloadOrders] = useState('')
  const { authStore } = useStore()

  const fetchOrders = useCallback(async () => {
    const ordersRes = await ordersService.getOrders()
    setOrders(ordersRes.data)
  }, [])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        paddingTop: 3,
        paddingBottom: 3,
      }}
    >
      <OrderInsights />
      <AsyncFetchWrapper
        loadAction={fetchOrders}
        triggerProp={[reloadOrders, authStore.currentUserStore?.store_id]}
      >
        <Section marginBottom={5} padding={2}>
          <OrdersTable
            orders={[...orders]}
            reloadOrders={() => setReloadOrders(uuid())}
          />
        </Section>
      </AsyncFetchWrapper>
    </Box>
  )
})
