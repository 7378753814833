import { cn } from '@/utils'
import * as SwitchPrimitives from '@radix-ui/react-switch'
import * as React from 'react'

export type SwitchProps = React.ComponentPropsWithoutRef<
  typeof SwitchPrimitives.Root
> & { inverse?: boolean }

const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  SwitchProps
>(({ className, inverse, ...props }, ref) => (
  <SwitchPrimitives.Root
    className={cn(
      'mx-[0.375rem] flex h-[0.4375rem] w-[0.9375rem] cursor-pointer items-center justify-end rounded-full transition-colors data-[disabled]:bg-[#EFEFEF]',
      inverse
        ? 'bg-[#98E7F6] data-[state=checked]:bg-outline'
        : 'bg-outline data-[state=checked]:bg-[#98E7F6]',
      className,
    )}
    {...props}
    ref={ref}
  >
    <SwitchPrimitives.Thumb
      className={cn(
        'pointer-events-none block h-3.5 w-3.5 rounded-full transition-transform data-[disabled]:bg-[#CECECE]',
        inverse
          ? 'translate-x-[0.375rem] bg-[#00C2E8] data-[state=checked]:translate-x-[-0.375rem] data-[state=checked]:bg-placeholder'
          : 'translate-x-[-0.375rem] bg-placeholder data-[state=checked]:translate-x-[0.375rem] data-[state=checked]:bg-[#00C2E8]',
      )}
    />
  </SwitchPrimitives.Root>
))
Switch.displayName = SwitchPrimitives.Root.displayName

export { Switch }
