import { cn } from '@/utils'
import { DeliveryLogo } from '../orders/components'
import { DeliveryStatus, OrderDelivery } from '../orders/models'
import { getEstimatedPickupTime } from '../orders/utils'

export const DeliveryETA = ({
  delivery,
  flat,
}: {
  delivery: OrderDelivery
  flat?: boolean
}) => {
  if (delivery.delivery_status === DeliveryStatus.Canceled) {
    return null
  }

  const time = getEstimatedPickupTime(delivery)
  const timeToPickup = time
    ? time.diffNow('minutes').toFormat("m 'min'")
    : undefined

  return (
    <div
      className={cn(
        'flex items-center',
        flat && 'gap-1',
        !flat && 'gap-2 rounded bg-[#EDEDED] p-4',
      )}
    >
      <DeliveryLogo
        deliveryPartner={delivery.delivery_partner}
        size={flat ? 16 : 24}
      />
      <p
        className={cn(
          'leading-normal',
          flat && 'text-xs text-[#A3A3A3]',
          !flat && 'text-sm',
        )}
      >
        {[
          DeliveryStatus.PickedUp,
          DeliveryStatus.EnrouteToDropoff,
          DeliveryStatus.ArrivingAtDropoff,
        ].includes(delivery.delivery_status) ? (
          'Picked Up'
        ) : delivery.delivery_status === DeliveryStatus.Delivered ? (
          'Delivered'
        ) : timeToPickup ? (
          <>
            <span className='font-semibold'>ETA: </span>
            {timeToPickup}
          </>
        ) : (
          'Assigning Courier'
        )}
      </p>
    </div>
  )
}
