import CircularProgress from '@mui/material/CircularProgress'
import { styled } from '@mui/material'

const CenterDiv = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  flex: 1,
}))

const AbsoluteCenterDiv = styled('div')(() => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  left: 0,
  right: 0,
}))

const Loader = (props: { isAbsolute?: boolean }) => {
  const Container = props.isAbsolute ? AbsoluteCenterDiv : CenterDiv
  return (
    <Container>
      <CircularProgress />
    </Container>
  )
}

export default Loader
