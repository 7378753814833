import { cn } from '@/utils'

export default function Logo({ className }: { className?: string }) {
  return (
    <svg
      viewBox='0 0 71 43'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={cn('text-[#DFDFDF]', className)}
    >
      <path
        d='M45.7166 11.8716C48.2091 10.3142 50.5831 9.45464 53.5212 9.38283C56.4594 9.31102 59.3629 10.0296 61.9285 11.4634C64.4941 12.8973 66.6275 14.9938 68.1059 17.5339C69.5843 20.0741 70.3534 22.9646 70.3329 25.9036C70.3124 28.8426 69.503 31.7221 67.9893 34.2414C66.4755 36.7606 64.313 38.8272 61.7277 40.225C59.1424 41.6229 56.2291 42.3009 53.2922 42.1881C50.3553 42.0752 48.1871 41.1758 45.7166 39.5837V32.2363C46.4235 32.6918 47.5716 33.6048 49.2333 34.2414C50.94 34.8952 52.6937 35.2673 53.5568 35.3005C55.2602 35.3659 56.9499 34.9727 58.4494 34.1619C59.9489 33.3512 61.2031 32.1526 62.0811 30.6914C62.959 29.2302 63.4285 27.5601 63.4404 25.8555C63.4523 24.1509 63.0062 22.4744 62.1487 21.0011C61.2913 19.5278 60.0539 18.3118 58.5659 17.4802C57.0778 16.6486 55.3938 16.2318 53.6896 16.2734C52.8146 16.2948 50.9938 16.4414 49.2333 16.9974C47.5651 17.5242 46.42 18.4497 45.7166 18.8892V11.8716Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M32.8222 9.37793H25.7889V10.5502L32.8222 16.9974V9.37793ZM25.7889 23.4445L32.8222 29.8917V42.2H25.7889V39.2567C24.8226 39.9295 23.7844 40.498 22.6914 40.9508C20.7002 41.7755 19.1523 42.2 16.9972 42.2V35.1432C18.2256 35.1432 18.8559 34.9013 19.9908 34.4312C21.1258 33.9611 22.157 33.272 23.0256 32.4034C23.8942 31.5348 24.5833 30.5036 25.0534 29.3687C25.5235 28.2337 25.7889 27.0173 25.7889 25.7889L25.7889 23.4445Z'
        fill='currentColor'
      />
      <path
        d='M44.5444 0H37.5111V23.3766L29.6779 15.7781C28.7194 14.9458 26.9611 13.2344 26.9611 13.2344L26.2018 12.6248C25.3197 12.0087 24.3869 11.4129 23.4331 10.9612C20.4994 9.57169 17.2198 9.08376 14.0089 9.55909C10.7981 10.0344 7.80024 11.4517 5.39444 13.6316C2.98868 15.8115 1.28305 18.6562 0.493241 21.806C-0.296528 24.9557 -0.135047 28.269 0.957329 31.327C2.04971 34.3849 4.02393 37.05 6.63027 38.9854C9.23665 40.9208 12.5832 42.0395 15.825 42.2V35.1485C13.9771 35.057 12.3217 34.4194 10.836 33.3162C9.35041 32.213 8.22512 30.6939 7.60245 28.9509C6.97981 27.2079 6.88773 25.3192 7.33794 23.5239C7.78811 21.7285 8.76033 20.1071 10.1316 18.8645C11.5029 17.622 13.2117 16.8141 15.0419 16.5432C16.872 16.2722 18.7415 16.5504 20.4136 17.3424C22.0858 18.1344 23.4331 19.3746 24.6167 20.5036C25.9692 21.7937 28.1333 24.0219 28.1333 24.0219L30.9513 26.5211L37.5111 32.6822V32.6912L44.5444 39.2881V0Z'
        fill='#00C2E8'
      />
    </svg>
  )
}
