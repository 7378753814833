import { Address } from '../components/autocomplete-address'
import { NewOrderAlertVolume } from '../orders/models'
import { BaseModel } from '../utils/base-model'

export enum StoreType {
  Restaurant = 'RESTAURANT',
  GardenCenter = 'GARDEN_CENTER',
}

export interface StoreModel extends BaseModel {
  name: string
  external_id: string
  location_id: string
  location?: Address

  desk_contact_id: string
  pickup_contact_id: string

  pickup_contact?: Contact

  default_courier_pickup_instructions: string
  default_prep_time: number

  new_order_alert_volume: NewOrderAlertVolume
  dropoff_notifications_enabled: boolean

  uber_eats_store_id?: string

  has_payment_method: boolean
  free_deliveries_remaining: number

  is_self_delivery_allowed: boolean
  is_a2b_delivery_active: boolean
}

interface Contact extends BaseModel {
  name: string
  phone_number: string
  email?: string
}
