import { cn } from '@/utils'
import { NoMeals, Restaurant } from '@mui/icons-material'
import { useMemo } from 'react'
import { Order } from '../orders/models'
import { formatPriceStr } from '../utils/price-utils'

export const OrderItems = ({
  order,
  expanded,
}: {
  order: Order
  expanded?: boolean
}) => {
  const items = useMemo(
    () =>
      order.items.length === 0
        ? null
        : order.items[0].label
          ? order.items.reduce(
              (items, item) => ({
                ...items,
                [item.label!]: [...(items[item.label!] ?? []), item],
              }),
              {} as Record<string, Order['items']>,
            )
          : { '': order.items },
    [order.items],
  )

  if (items === null) {
    return null
  }

  return (
    <>
      <hr className='mb-6 border-[#DBDBDC]' />
      <div
        className={cn(
          'grid w-full gap-4 text-sm/normal',
          expanded
            ? 'grid-cols-[1fr_minmax(10%,max-content)_minmax(10%,max-content)]'
            : 'grid-cols-[1fr_minmax(5%,max-content)]',
        )}
      >
        <div className='col-span-full grid grid-cols-subgrid text-xs/normal font-semibold text-[#AFAFAF]'>
          <p>Items</p>
          <p className='text-right'>Qty</p>
          {expanded && <p className='text-right'>Price</p>}
        </div>
        {Object.entries(items).map(([label, items], index) => (
          <div
            key={index}
            className='col-span-full grid grid-cols-subgrid gap-y-2'
          >
            {label !== '' && (
              <p className='col-span-full mb-0.5 rounded bg-[#F2F2F2] px-0 py-1 text-center text-xs/normal font-bold'>
                {label}
              </p>
            )}
            {items.map((item, index) => (
              <div key={index} className='col-span-full grid grid-cols-subgrid'>
                <div
                  className={cn(
                    'col-span-full grid grid-cols-subgrid',
                    item.options?.length || item.specialInstructions
                      ? 'mb-1'
                      : '',
                  )}
                >
                  <p>{item.name}</p>
                  <p
                    className={cn(
                      'text-right',
                      item.quantity > 1 ? 'text-a2b-blue' : 'text-black',
                    )}
                  >
                    {item.quantity}x
                  </p>
                  {expanded && (
                    <p className='text-right'>
                      {formatPriceStr({
                        d: item.total_price,
                        showZero: true,
                      })}
                    </p>
                  )}
                </div>
                {(item.options?.length || item.specialInstructions) && (
                  <ul className='list-disc pl-3'>
                    {item.specialInstructions && (
                      <li className='text-xs/normal text-a2b-blue'>
                        Special instructions: {item.specialInstructions}
                      </li>
                    )}
                    {item.options?.map((option, index) => (
                      <li key={index} className='text-xs/normal text-[#9D9D9D]'>
                        {option}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            ))}
          </div>
        ))}
        {order.include_utensils !== null && (
          <>
            <hr className='col-span-full border-[#DBDBDC]' />
            <div className='col-span-full grid grid-cols-subgrid gap-y-2'>
              <p className=''>
                {order.include_utensils ? '' : 'Do Not'} Include Utensils and
                Condiments
              </p>
              {expanded && <p />}
              <div className='flex items-center justify-end'>
                {order.include_utensils ? (
                  <Restaurant className='!h-4 !w-4' />
                ) : (
                  <NoMeals className='!h-4 !w-4' />
                )}
              </div>
            </div>
          </>
        )}
        {expanded && order.subtotals.length > 0 && (
          <>
            <hr className='col-span-full border-[#DBDBDC]' />
            <div className='col-span-full grid grid-cols-subgrid gap-y-2'>
              {order.subtotals.map((subtotal) => (
                <div
                  key={subtotal.id}
                  className='col-span-full grid grid-cols-subgrid first:font-semibold'
                >
                  <p>{subtotal.name}</p>
                  <p />
                  <p className='text-right'>
                    {formatPriceStr({
                      d: subtotal.price,
                      showZero: true,
                    })}
                  </p>
                </div>
              ))}
            </div>
          </>
        )}
        <hr className='col-span-full border-[#DBDBDC]' />
        <div
          className={cn(
            'col-span-full grid grid-cols-subgrid',
            expanded && 'font-semibold',
          )}
        >
          <p>Total</p>
          {expanded && <p />}
          <p className='text-right'>
            {formatPriceStr({
              d: order.order_value,
              showZero: true,
            })}
          </p>
        </div>
      </div>
    </>
  )
}
