import ConnectUberEatsPage from '@/components/connect-uber-eats-page'
import { Box } from '@mui/material'
import { Route, Routes } from 'react-router-dom'
import { LiveOrdersRoute } from '../live-orders/live-orders-route'
import { styled } from '@mui/system'
import { observer } from 'mobx-react'
import { NavigationMenu } from './navigation-menu'
import { OrdersRoute } from '../orders/orders-route'
import { AppBar } from './app-bar'
import { OrderPopup } from '../order-details/order-popup'
import { BreakpointsSelector } from '../utils/mobile'
import { useStore } from '@/root-store'
import { useEffect } from 'react'

export const Layout = observer(() => {
  const {
    authStore: { isFirstLogin, externalCustomer },
    dialogStore,
    generalStore,
  } = useStore()

  useEffect(() => {
    if (generalStore.hasMerchantForceUpdate) {
      location.reload()
    }
  }, [generalStore.hasMerchantForceUpdate])

  useEffect(() => {
    generalStore.startHeartbeatInterval()

    if (
      isFirstLogin &&
      externalCustomer &&
      // make sure that the dialog is not already open
      Object.entries(dialogStore.customDialogs).length === 0
    ) {
      dialogStore.showPromptAsync({
        title: 'You are Now Set Up for Local Delivery!',
        promptMessage:
          'A Triad Local Delivery rep will be in touch with you shortly to walk you through the portal.',
        hideRejectButton: true,
        resolveButton: {
          title: 'Got it',
        },
      })
    }
  }, [])

  return (
    <Container>
      <AppBar />
      <Row>
        <BreakpointsSelector xl={<NavigationMenu />} />
        <Content>
          <RoutesContainer className='routesContainer'>
            <Routes>
              <Route path='*' element={<LiveOrdersRoute />} />
              <Route
                path='/connect-uber-eats'
                element={<ConnectUberEatsPage />}
              />
              <Route path={'/orders/*'} element={<OrdersRoute />} />
            </Routes>
          </RoutesContainer>
        </Content>
      </Row>

      <OrderPopup />
    </Container>
  )
})

const RoutesContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  overflow: 'hidden',
  display: 'flex',
  flex: 1,
  '& > *': {
    overflow: 'auto',
  },

  [theme.breakpoints.down('xl')]: {},
}))

const Row = styled(Box)(() => ({
  flex: 1,
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'row',
  // '& > *': {
  //   [theme.breakpoints.up('xl')]: {
  //     paddingLeft: theme.spacing(8),
  //     paddingRight: theme.spacing(8),
  //   },
  // },
}))

const Content = styled(Row)(({ theme }) => ({
  '& > *': {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}))

const Container = styled(Box)(({ theme }) => ({
  height: '100vh',
  display: 'flex',
  alignItems: 'stretch',
  overflow: 'hidden',
  flexDirection: 'column',

  [theme.breakpoints.down('xl')]: {},
}))
