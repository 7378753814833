import { useEffect, useState } from 'react'
import { Order, OrderingPlatforms } from '../orders/models'
import { observer } from 'mobx-react'
import { useStore } from '../root-store'
import { Box, Divider, Grid, styled, Typography } from '@mui/material'
import { formatPriceStr } from '../utils/price-utils'
import { DateTime } from 'luxon'

export const PrintQueue = observer(() => {
  const { printStore } = useStore()
  const [order, setOrder] = useState<Order>()

  useEffect(() => {
    if (!order) {
      const a = printStore.getAndRemoveFromQueue()

      if (a) {
        setOrder(a)
      }
    }
  }, [printStore.printQueue])

  useEffect(() => {
    if (order) {
      window.print()

      setTimeout(() => {
        setOrder(printStore.getAndRemoveFromQueue())
      }, 300)
    }
  }, [order])

  if (!order) {
    return null
  }

  return (
    <Box className='printed-section'>
      <Root sx={{ padding: 4 }}>
        <Box display='flex' flexDirection='column' alignItems='center'>
          <span>{order.store?.name}</span>
          <span>{order.pickup_location?.display_string}</span>
        </Box>
        <SpaceBetween>
          <Typography variant='h6'>Order #:</Typography>
          <Typography variant='h6'>
            {order.display_id ?? order.external_id}
          </Typography>
        </SpaceBetween>
        <SpaceBetween>
          <span>Customer:</span>
          <span>
            {OrderingPlatforms[order.ordering_platform].displayString}
          </span>
        </SpaceBetween>
        <SpaceBetween>
          <span>For:</span>
          <span>{order.customer_name}</span>
        </SpaceBetween>

        <br />
        <DashedDivider />
        <br />

        <Grid container spacing={1}>
          <Grid item xs={12} container spacing={1} fontWeight={600}>
            <Grid item xs={2}>
              Qty
            </Grid>
            <Grid item xs={8}>
              Item
            </Grid>
            <Grid item xs={2} textAlign='end'>
              Price
            </Grid>
          </Grid>
          {order.items?.map((i) => (
            <Grid key={i.id} item xs={12} container spacing={1}>
              <Grid item xs={2}>
                {i.quantity}
              </Grid>
              <Grid item xs={8}>
                {i.name}
              </Grid>
              <Grid item xs={2} textAlign='end'>
                {formatPriceStr({ d: i.total_price, showZero: true })}
              </Grid>
            </Grid>
          ))}
        </Grid>

        <br />
        <DashedDivider />

        <Grid container spacing={1}>
          {order.subtotals?.map((i) => (
            <Grid key={i.id} item xs={12} container spacing={1}>
              <Grid item xs={2} />
              <Grid item xs={8}>
                {i.name}
              </Grid>
              <Grid item xs={2} textAlign='end'>
                {formatPriceStr({ d: i.price, showZero: true })}
              </Grid>
            </Grid>
          ))}
        </Grid>

        <br />
        <DashedDivider />

        <Grid container spacing={1}>
          <Grid item xs={12} container spacing={1} fontWeight={600}>
            <Grid item xs={2} />
            <Grid item xs={8}>
              Price
            </Grid>
            <Grid item xs={2} textAlign='end'>
              {formatPriceStr({ d: order.order_value, showZero: true })}
            </Grid>
          </Grid>
        </Grid>

        <Box display='flex' flexDirection='column' alignItems='center'>
          <span>{DateTime.now().toLocaleString(DateTime.DATETIME_SHORT)}</span>
          <span>Customer Copy</span>
          <span>{order.customer_phone}</span>
          <br />
          <br />
          <span>Thank you for ordering with us!</span>
          <span>Hope to see you soon</span>
        </Box>
      </Root>
    </Box>
  )
})

const Root = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  fontFamily: 'Anonymous Pro',
}))

const SpaceBetween = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}))

const DashedDivider = styled(Divider)(() => ({
  borderStyle: 'dashed',
}))
