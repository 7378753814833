export default function PickUpIcon({ className }: { className?: string }) {
  return (
    <svg viewBox='0 0 24 24' fill='none' className={className}>
      <path
        d='M13.6427 8.89825C12.8622 8.89825 12.1953 8.60334 11.6419 8.02826C11.0885 7.45318 10.8046 6.76014 10.8046 5.94913C10.8046 5.13812 11.0885 4.44507 11.6419 3.86999C12.1953 3.29491 12.8622 3 13.6427 3C14.4232 3 15.0901 3.29491 15.6435 3.86999C16.1969 4.44507 16.4808 5.13812 16.4808 5.94913C16.4808 6.76014 16.1969 7.45318 15.6435 8.02826C15.0901 8.60334 14.4232 8.89825 13.6427 8.89825ZM12.6494 21.432H6.97327L8.3923 13.9118L5.83805 14.944V19.9575H3V13.027L8.60516 10.5203C9.42819 10.1516 10.0384 9.9157 10.4357 9.79774C10.833 9.67977 11.1878 9.63554 11.5142 9.63554C12.0108 9.63554 12.4791 9.76825 12.9048 10.0484C13.3305 10.3286 13.6711 10.6825 13.9407 11.1248L15.3597 13.4841C15.9699 14.5163 16.8071 15.3716 17.8572 16.0351C18.9073 16.6987 20.1135 17.0378 21.4615 17.0378V19.987C19.9006 19.987 18.439 19.6478 17.0767 18.9695C15.7145 18.2912 14.5793 17.4065 13.6569 16.3006L12.6636 21.4615L12.6494 21.432Z'
        fill='#2C2C2D'
      />
    </svg>
  )
}
