import { Crisp, PickerMessage } from 'crisp-sdk-web'
import { Order } from '../orders/models'
import { toDictionary } from '../utils/array-utils'

export type CrispMessageReceived = {
  fingerprint: number
  timestamp: number
  edited: boolean
  type: 'picker'
  origin: 'update'
  content: PickerMessage
}

export enum CrispQuestions {
  TypeOfHelp = 'typeOfHelp',
  HumanSupport = 'humanSupport',
  SelectOrder = 'selectOrder',
  SelectOrderCarousel = 'selectOrderCarousel',
  OrderIssue = 'orderIssue',
  CancelOrder = 'cancelOrder',
}

export enum Actions {
  CancelAction = 'cancelAction',
}

export type FlowData = {
  type: 'picker' | 'text' | 'carousel'
  text: string
  options?: {
    [key: string]: {
      text: string
      description?: string
      nextQuestion?: CrispQuestions
      nextAction?: Actions
    }
  }
  nextQuestion?: CrispQuestions
  nextAction?: Actions
}

export const Flow: {
  [key in CrispQuestions]: FlowData | ((orders?: Order[]) => FlowData)
} = {
  [CrispQuestions.TypeOfHelp]: {
    type: 'picker',
    text: "Hey, seems like you're having some issue, what help do you need?",
    options: {
      ['orderIssue']: {
        text: 'Issue with an order',
        nextQuestion: CrispQuestions.SelectOrder,
      },
      ['techSupport']: {
        text: 'Technical support',
        nextQuestion: CrispQuestions.HumanSupport,
      },
      ['other']: {
        text: 'Other issue',
        nextQuestion: CrispQuestions.SelectOrder,
      },
    },
  },
  [CrispQuestions.HumanSupport]: {
    type: 'text',
    text: 'Hey, a human will be right with you',
  },
  [CrispQuestions.SelectOrder]: (): FlowData => {
    const orderId = Crisp.session.getData('externalOrderId') as string

    if (!orderId) {
      return Flow[CrispQuestions.SelectOrderCarousel] as FlowData
    }

    return {
      type: 'picker',
      text: 'Please choose an order',
      options: {
        [orderId]: {
          text: `Order #${orderId}`,
          nextQuestion: CrispQuestions.OrderIssue,
        },
        ['other']: {
          text: 'Other order',
          nextQuestion: CrispQuestions.HumanSupport,
        },
      },
    }
  },
  [CrispQuestions.SelectOrderCarousel]: (orders) => {
    return {
      type: 'carousel',
      options: toDictionary(
        orders || [],
        (o) => o.id,
        (o) => ({
          text: `Order #${o.id}`,
        }),
      ),
      text: 'Choose an Order',
    }
  },

  [CrispQuestions.OrderIssue]: {
    type: 'picker',
    text: 'What type of issue?',
    options: {
      ['courier']: {
        text: 'Issue with the courier',
        nextQuestion: CrispQuestions.HumanSupport,
      },
      ['missingItems']: {
        text: 'Missing items',
        nextQuestion: CrispQuestions.HumanSupport,
      },
      ['cancel']: {
        text: 'Need to cancel order',
        nextQuestion: CrispQuestions.CancelOrder,
      },
      ['other']: {
        text: 'Other issue',
        nextQuestion: CrispQuestions.HumanSupport,
      },
    },
  },
  [CrispQuestions.CancelOrder]: {
    type: 'picker',
    text: 'Need to cancel the order',
    options: {
      ['customerCanceled']: {
        text: 'Customer canceled',
        nextAction: Actions.CancelAction,
        // next: CrispQuestions.HumanSupport,
      },
      ['cantFulfill']: {
        text: "Can't fulfill the order",
        nextAction: Actions.CancelAction,
        // next: CrispQuestions.,
      },
      ['other']: {
        text: 'Other reason',
        nextQuestion: CrispQuestions.HumanSupport,
      },
    },
  },
}
