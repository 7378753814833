import { Order } from '@/orders/models'
import CancelOrderStep from './steps/cancel-order'
import IssueWithOrderStep from './steps/main'
import OrderCanceledStep from './steps/order-canceled'

export type Step = 'issueWithOrder' | 'cancelOrder' | 'orderCanceled'
export interface StepProps {
  orderId: string
  reloadOrder: () => void
  setOpen: (open: boolean) => void
  setCurrentStep: (step: Step) => void
  order: Order
}

export const steps: Record<Step, (props: StepProps) => JSX.Element> = {
  issueWithOrder: IssueWithOrderStep,
  cancelOrder: CancelOrderStep,
  orderCanceled: OrderCanceledStep,
}
