import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import z from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { cx } from 'class-variance-authority'
import { CurrencyExchange, UploadFile } from '@mui/icons-material'

import { AsyncDialogBaseProps } from '../dialogs/models'
import {
  OrderRefund,
  OrderDetails,
  OrderStatus,
  OrderWithDeliveries,
  RefundStatus,
  OrderItem,
  OrderingPlatform,
} from '../orders/models'
import { axiosClient } from '@/axios-client'
import { TextInputWrapper } from '@/components/inputs'
import {
  DialogHeader,
  DialogButton,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@/components/ui/dialog'

import { useStore } from '@/root-store'
import FormInput from '@/components/ui/form-input'

interface BadgeProps {
  status: 'success' | 'error' | 'pending' | 'cancel'
  text: string
}

export const getBadgeProps = (
  refundStatus: RefundStatus,
): {
  status: 'success' | 'error' | 'pending' | 'cancel'
  text: string
  position: number
} => {
  switch (refundStatus) {
    case RefundStatus.Approved:
      return {
        position: 1,
        status: 'success',
        text: 'Refund Approved',
      }
    case RefundStatus.Rejected:
      return {
        position: 2,
        status: 'error',
        text: 'Refund Rejected',
      }
    case RefundStatus.Canceled:
      return {
        position: 3,
        status: 'cancel',
        text: 'Cancel Refund Request',
      }
    default:
      return {
        position: 4,
        status: 'pending',
        text: 'Refund Pending',
      }
  }
}

export const StatusBadge: React.FC<BadgeProps> = ({ status, text }) => {
  const statusClasses = {
    success: 'bg-green-100 text-green-800 border-green-300',
    error: 'bg-red-100 text-red-800 border-red-300',
    pending: 'bg-yellow-100 text-yellow-800 border-yellow-300',
    cancel: 'bg-gray-100 text-gray-800 border-gray-300',
  }

  return (
    <span
      className={cx(
        'inline-flex items-center gap-1 rounded-full border px-2 py-1 text-xs',
        statusClasses[status],
      )}
    >
      <CurrencyExchange style={{ height: '0.8em' }} />
      {text}
    </span>
  )
}

const CreateRefundSchema = z.object({
  notes: z.string().min(1, 'Notes must be at least one character'),
  refundAmount: z
    .number()
    .min(0.01, 'Refund amount must be greater than 0')
    .max(500, 'Refund amount must be less than 500'),
  receipt: z.any().optional(),
})

export const showRefundAction = (order: OrderDetails) =>
  !order.is_pickup &&
  [OrderStatus.Canceled, OrderStatus.Delivered].includes(order.status)

export const RefundDialog = ({
  open,
  reject,
  resolve,
  order,
  refund,
  reloadOrder,
}: AsyncDialogBaseProps & {
  order: OrderWithDeliveries
  isEdit?: boolean
  refund?: OrderRefund
  reloadOrder: () => void
}) => {
  const { snackbarStore } = useStore()

  const totalMerchantChargeItem = {
    unit_price: order.total_merchant_charge,
    id: 'merchant_charge',
    name: 'a2b Fees',
    quantity: 1,
  }

  const getSelectedItems = () => {
    const allItems = [...order.items, totalMerchantChargeItem]
    if (order.items.length && order.order_value > 0) {
      return allItems
    }

    return []
  }

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    resolver: zodResolver(CreateRefundSchema),
    defaultValues: {
      refundAmount: 0,
      notes: refund?.refund_reason || '',
      refundStatus: refund?.refund_status || RefundStatus.Pending,
      receipt: null,
      selectedItems: getSelectedItems(),
      reject_reason: refund?.refund_rejected_reason || '',
    },
  })

  const [showPopup, setShowPopup] = useState(false)
  const [loading, setLoading] = useState(false)
  const [totalRefund, setTotalRefund] = useState(0)
  const [customFileName, setCustomFileName] = useState('')
  const watchRefundAmount = watch('refundAmount')
  const watchStatus = watch('refundStatus')
  const selectedItems = watch('selectedItems')

  const onSubmit = async (data: any) => {
    if (data) {
      setLoading(true)

      if (
        order.order_value > 0 &&
        watchRefundAmount &&
        Number(watchRefundAmount) >
          order.order_value + Number(order.total_merchant_charge)
      ) {
        snackbarStore.showSnackbar({
          title: 'Refund amount cannot be greater than order total',
          severity: 'error',
        })
        setLoading(false)
        return
      }

      try {
        const formData = new FormData()
        formData.append('order_id', order.id)
        formData.append('refund_status', watchStatus)
        formData.append('notes', data.notes || '')

        if (watchRefundAmount || totalRefund) {
          formData.append('refund_requested', watchRefundAmount!.toString())
        }

        if (!refund?.receipt_url && data.receipt) {
          formData.append('custom_file_name', customFileName)
          formData.append('receipt', data.receipt)
        }

        await axiosClient.post(`/billing/refunds`, formData, {
          timeout: 30000, // 30 seconds
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

        reloadOrder()
        setShowPopup(true)
      } catch (error: any) {
        snackbarStore.showSnackbar({
          title: error.response?.data?.message || 'Error refunding order',
          severity: 'error',
        })
      } finally {
        setLoading(false)
      }
    }
  }

  const toggleItemSelection = (item: OrderItem) => {
    const alreadySelected = selectedItems.find(
      (i: OrderItem) => i.id === item.id,
    )

    if (alreadySelected) {
      setValue(
        'selectedItems',
        selectedItems.filter((i: OrderItem) => i.id !== item.id),
        { shouldDirty: true },
      )
    } else {
      setValue('selectedItems', [...selectedItems, item], { shouldDirty: true })
    }
  }

  useEffect(() => {
    if (selectedItems.length) {
      const total = selectedItems.reduce(
        (sum: number, item: OrderItem) =>
          sum + (item.unit_price || 0) * item.quantity,
        0,
      )
      setTotalRefund(total)
      setValue('refundAmount', total)
    }
  }, [selectedItems, setValue])

  const watchReceipt = watch('receipt')

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = (e.target as HTMLInputElement).files?.[0]

    if (!file || refund?.receipt_url) return

    const orderId = order.id
    const customFileName = `${order.store.name}/${orderId}/${file.name}`

    setCustomFileName(customFileName)
    // @ts-ignore
    setValue('receipt', file, {
      shouldDirty: true,
      shouldTouch: true,
    })
  }

  const closePopup = () => {
    setShowPopup(false)
    resolve()
  }

  return (
    <Dialog open={open}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            Refund Request: <strong>Order: #{order.external_id}</strong>
          </DialogTitle>
        </DialogHeader>

        <form className='flex flex-col gap-4'>
          {order.items.length ? (
            <div>
              <div className='flex flex-col'>
                <h3 className='font-semibold'>Items</h3>
                <ul className='divide-y divide-gray-200'>
                  {order.items
                    .slice()
                    .sort((a, b) => a.unit_price! - b.unit_price!)
                    .map((item) => (
                      <li
                        key={item.id}
                        className='flex items-center justify-between py-2'
                      >
                        <label className='flex w-2/3 items-center'>
                          <input
                            type='checkbox'
                            checked={
                              !!selectedItems.find(
                                (i: OrderItem) => i.id === item.id,
                              )
                            }
                            onChange={() => toggleItemSelection(item)}
                            className='mr-2'
                          />
                          <span className='truncate'>{item.name}</span>
                        </label>
                        <div className='flex w-1/3 items-center justify-end gap-4'>
                          <span className='text-sm text-gray-500'>
                            Qty: {item.quantity}
                          </span>
                          <span className='font-semibold'>
                            ${item.unit_price?.toFixed(2) || '0.00'}
                          </span>
                        </div>
                      </li>
                    ))}

                  <h3 className='pt-3 font-semibold'>Fees</h3>
                  {order.total_merchant_charge &&
                    order.total_merchant_charge > 0 && (
                      <li className='flex items-center justify-between py-2'>
                        <label className='flex w-2/3 items-center'>
                          <input
                            type='checkbox'
                            checked={
                              !!selectedItems.find(
                                (i: OrderItem) =>
                                  i.id === totalMerchantChargeItem.id,
                              )
                            }
                            onChange={() =>
                              toggleItemSelection(totalMerchantChargeItem)
                            }
                            className='mr-2'
                          />
                          <span className='truncate'>a2b Fees</span>
                        </label>

                        <div className='flex w-1/3 items-center justify-end gap-4'>
                          {order.total_merchant_charge ? (
                            <span className='font-semibold'>
                              ${Number(order.total_merchant_charge!).toFixed(2)}
                            </span>
                          ) : null}
                        </div>
                      </li>
                    )}
                </ul>
              </div>
            </div>
          ) : null}

          {order.ordering_platform === OrderingPlatform.Manual &&
          order.items.length <= 0 ? (
            <div className='flex'>
              <FormInput
                type='number'
                placeholder='Refund Amount'
                control={control}
                name='refundAmount'
                prefix='$'
                required
              />

              {errors.refundAmount && (
                <p className='mt-1 text-xs text-red-500'>
                  {errors.refundAmount.message as string}
                </p>
              )}
            </div>
          ) : null}

          <div className='items-between flex-col justify-between'>
            <div className='flex justify-between'>
              <p className='text-lg font-bold'>Total Requested Refund:</p>
              <p className='text-lg font-bold'>
                ${watchRefundAmount || totalRefund!}
              </p>
            </div>
          </div>

          <TextInputWrapper
            label='Notes'
            placeholder='Please provide details to increase the chance of approval'
            control={control}
            rules={{ required: true }}
            error={!!errors.notes}
            propName='notes'
          />

          <div className='flex flex-col gap-2'>
            <div className='flex items-center rounded-lg border border-gray-300 bg-gray-50 p-3 transition duration-150 ease-in-out focus-within:ring-2 focus-within:ring-indigo-500 hover:shadow'>
              <input
                type='file'
                id='receipt'
                accept='image/*,application/pdf'
                onChange={handleFileChange}
                className='hidden'
              />
              <label
                htmlFor='receipt'
                className='flex cursor-pointer items-center gap-2 text-gray-600 transition hover:text-indigo-500'
              >
                <UploadFile />
                <span className='text-sm font-medium'>
                  {watchReceipt ? (
                    <p className='mt-1 text-sm text-gray-600'>
                      {/* @ts-expect-error */}
                      {watchReceipt.name}
                    </p>
                  ) : (
                    <>
                      Upload Order Receipt{' '}
                      <span className='text-red-500'>*</span>
                    </>
                  )}
                </span>
              </label>
            </div>

            {errors.receipt && (
              <p className='mt-1 text-xs text-red-500'>
                {errors.receipt.message}
              </p>
            )}
          </div>
        </form>

        <div className='m-2 flex justify-center space-x-2'>
          <DialogButton onClick={reject}>Cancel</DialogButton>
          <DialogButton
            loading={loading}
            className={!isValid ? 'bg-gray-200' : 'bg-gray-950 text-white'}
            disabled={!isValid}
            onClick={handleSubmit(onSubmit)}
          >
            Submit Refund Request
          </DialogButton>
        </div>
      </DialogContent>

      {showPopup && (
        <Dialog open={showPopup} onOpenChange={closePopup}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>
                Request Refund: <strong>Order: #{order.external_id}</strong>
              </DialogTitle>
            </DialogHeader>
            <p className='mt-4'>
              We submitted your refund request to our delivery partner and will
              update you with the response.
            </p>
          </DialogContent>
        </Dialog>
      )}
    </Dialog>
  )
}
