import { AsyncDialogBaseProps, DialogButton } from './models'
import { useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { useRunRequest } from '../axios-client'
import { InputBaseProps } from '@mui/material/InputBase'
import { CustomTextInput } from '../components/inputs'
import { useStore } from '@/root-store'
import { colors } from '@/theme'

export interface PromptDialogProps<T = undefined> {
  promptMessage: string | JSX.Element
  title: string
  disableDismiss?: boolean
  onBeforeResolve?: (() => Promise<void>) | (() => void)
  resolveButton?: DialogButton<T> | DialogButton<T>[]
  rejectButton?: DialogButton
  hideRejectButton?: boolean
  disableEnforceFocus?: boolean
}

export const UNSAVED_CHANGES_PROMPT: PromptDialogProps = {
  title: 'Unsaved Changes',
  promptMessage: 'Are you sure you want to leave without saving your changes?',
  rejectButton: {
    title: 'Cancel',
    isResolve: false,
    variant: 'contained',
  },
  resolveButton: {
    title: 'Leave',
    isResolve: true,
    variant: 'text',
  },
}

export const PromptDialog = ({
  promptMessage,
  title,
  onBeforeResolve,
  rejectButton,
  resolveButton,
  disableEnforceFocus,
  ...props
}: PromptDialogProps<any> & AsyncDialogBaseProps) => {
  const { authStore } = useStore()
  const runRequest = useRunRequest()
  const onClose = () => {
    props.reject()
  }
  const rejectVariant = rejectButton?.variant || 'text'
  return (
    <Dialog
      maxWidth='sm'
      scroll='body'
      open={props.open}
      onClose={onClose}
      disableEnforceFocus={disableEnforceFocus}
      disableEscapeKeyDown={props.disableDismiss}
      sx={{ '& .MuiPaper-root': { padding: 5 } }}
    >
      <DialogTitleStyled>{title}</DialogTitleStyled>
      <PromptDialogText
        color='black'
        paddingBottom={5}
        paddingLeft={8}
        paddingRight={8}
      >
        {promptMessage}
      </PromptDialogText>
      <ButtonContainer>
        {!props.hideRejectButton && (
          <Button
            fullWidth={true}
            disabled={runRequest.loading}
            onClick={props.reject}
            color={rejectVariant === 'text' ? 'secondary' : 'primary'}
            variant={rejectVariant}
          >
            {rejectButton?.title || 'Cancel'}
          </Button>
        )}
        {(Array.isArray(resolveButton) ? resolveButton : [resolveButton]).map(
          (button) => {
            return (
              <Button
                style={
                  authStore.externalCustomer
                    ? { backgroundColor: colors.green.G00 }
                    : {}
                }
                key={button?.title || 'Yes'}
                fullWidth={true}
                disabled={runRequest.loading}
                startIcon={
                  runRequest.loading && (
                    <CircularProgress size={20} color='secondary' />
                  )
                }
                onClick={async () => {
                  if (onBeforeResolve) {
                    await runRequest.action({
                      action: onBeforeResolve,
                    })
                  }
                  return props.resolve(button?.resolveData)
                }}
                variant={button?.variant || 'contained'}
                autoFocus
              >
                {button?.title || 'Yes'}
              </Button>
            )
          },
        )}
      </ButtonContainer>
    </Dialog>
  )
}

export interface BasicTextBoxDialogProps {
  promptMessage?: string | JSX.Element
  existingText?: string
  textBoxPlaceholder: string
  resolveButton?: string
  dialogTitle: string
  required?: boolean
  numeric?: boolean
  min?: number
  max?: number
  inputProps?: InputBaseProps['inputProps']
  multiline?: boolean
  rows?: number
  startAdornment?: string
  minWidth?: string | number
  onBeforeResolve?: ((text: string) => Promise<void>) | ((text: string) => void)
}

export const BasicTextBoxDialog = (
  props: AsyncDialogBaseProps & BasicTextBoxDialogProps,
) => {
  const [text, setText] = useState(props.existingText || '')
  const runRequest = useRunRequest()

  return (
    <DialogStyled
      sx={{ '& .MuiPaper-root': { minWidth: props.minWidth } }}
      open={props.open}
      onClose={props.reject}
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle>{props.dialogTitle}</DialogTitle>
      <DialogBody>
        {!!props.promptMessage && (
          <PromptDialogText
            style={{ textAlign: 'left' }}
            paddingBottom={5}
            paddingRight={8}
          >
            {props.promptMessage}
          </PromptDialogText>
        )}
        <Box>
          <CustomTextInput
            fullWidth={true}
            inputProps={{ min: props.min, max: props.max, ...props.inputProps }}
            InputProps={
              props.startAdornment
                ? {
                    startAdornment: (
                      <span style={{ marginRight: 2, fontFamily: 'system-ui' }}>
                        {props.startAdornment}
                      </span>
                    ),
                  }
                : undefined
            }
            sx={{ marginTop: '5px' }}
            type={props.numeric ? 'number' : undefined}
            required={props.required}
            placeholder={props.textBoxPlaceholder}
            label={props.textBoxPlaceholder}
            size='small'
            value={text}
            multiline={props.multiline}
            rows={props.rows}
            onChange={setText}
          />
        </Box>
      </DialogBody>
      <ButtonContainer>
        <Button
          disabled={(props.required && !text) || runRequest.loading}
          startIcon={
            runRequest.loading && (
              <CircularProgress size={20} color='secondary' />
            )
          }
          onClick={async () => {
            if (props.onBeforeResolve) {
              await runRequest.action({
                action: () => props.onBeforeResolve!(text),
              })
            }

            return props.resolve({ text })
          }}
          fullWidth={true}
          variant='contained'
          autoFocus
        >
          {props.resolveButton || 'Update'}
        </Button>
        <Button fullWidth={true} onClick={props.reject} color='primary'>
          Cancel
        </Button>
      </ButtonContainer>
    </DialogStyled>
  )
}

const DialogStyled = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    minWidth: 500,
  },
}))

export const ButtonContainer = styled(DialogActions)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: 0,
  marginTop: theme.spacing(6),
  marginLeft: theme.spacing(6),
  marginRight: theme.spacing(6),
  '& > *': {
    paddingTop: `${theme.spacing(3)} !important`,
    paddingBottom: `${theme.spacing(3)} !important`,
    marginBottom: `${theme.spacing(3)} !important`,
  },
  '& :not(:first-of-type)': {
    marginLeft: 0,
    marginBottom: 0,
  },
}))

export const DialogTitleStyled = styled(Typography)(({ theme }) => ({
  justifyContent: 'center',
  fontFamily: 'poppins',
  display: 'flex',
  fontWeight: '500',
  fontSize: '24px',
  margin: theme.spacing(6),
}))

export const PromptDialogText = styled(DialogContentText)(() => ({
  textAlign: 'center',
  fontSize: '16px',
  fontFamily: 'poppins',
}))

export const DialogBody = styled(DialogContent)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  '& > *:first-child': {
    marginTop: '0 !important',
    marginBottom: '0 !important',
  },
  '& > *': {
    marginTop: '16px',
  },
}))
