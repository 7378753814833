import { useEffect, useRef, useState } from 'react'
import { Box, Button, Skeleton, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { useStore } from '../root-store'
import { handleErrors } from '../axios-client'

export class DiscardedPromiseError extends Error {
  constructor() {
    super()
  }
}

interface AsyncFetchWrapperProps<T> {
  children:
    | JSX.Element
    | ((data: T) => JSX.Element)
    | null
    | false
    | (JSX.Element | false | undefined | null)[]
  showErrorState?: boolean
  loaderItemCount?: number
  manualIsLoading?: boolean
}

export const useAsyncFetch = function <T, P extends any[] = [], E = any>({
  loadAction,
  isInitiallyLoading,
  defaultError,
}: {
  loadAction: (...p: P) => Promise<T> | T
  isInitiallyLoading?: boolean
  defaultError?: string
}) {
  const [isLoading, setIsLoading] = useState(isInitiallyLoading || false)
  const [error, setError] = useState<E>()
  const [data, setData] = useState<T>()
  const currentPromiseRef = useRef<T | Promise<T> | undefined>()
  const { snackbarStore } = useStore()

  const load = async (...p: P) => {
    setIsLoading(true)
    setError(undefined)
    let resp: T
    try {
      const newPromise = loadAction(...p)
      currentPromiseRef.current = newPromise
      resp = await newPromise

      if (newPromise !== currentPromiseRef.current) {
        return
      }
      setData(resp)
    } catch (e) {
      if (e instanceof DiscardedPromiseError) {
        return
      }
      console.log(e) // eslint-disable-line no-console
      setError(e as E)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    if (error && defaultError) {
      ;(async () => {
        const converted = await handleErrors({
          e: error,
          defaultError,
          customForAnyError: true,
        })
        snackbarStore.showCustomerError(converted)
      })()
    }
  }, [error, defaultError])

  return {
    error,
    isLoading,
    runAction: load,
    data,
    clearError: () => setError(undefined),
  }
}

interface LoadersProps {
  loaderItemCount: number
}

export const Loaders = ({ loaderItemCount }: LoadersProps) => {
  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      {[...Array(loaderItemCount)].map((_, index) => (
        <Box key={index} sx={{ mb: 2, ml: 3, mr: 3, mt: 3 }}>
          <Skeleton variant='circular' width={40} height={40} />
          <Skeleton variant='text' />
          <Skeleton variant='text' />
          <Skeleton variant='text' />
          <Skeleton variant='text' />
        </Box>
      ))}
    </Box>
  )
}

const AsyncFetchWrapper = function <T>({
  loadAction,
  triggerProp,
  manualIsLoading,
  ...props
}: AsyncFetchWrapperProps<T> & {
  loadAction: () => Promise<T>
  triggerProp?: any | any[]
}) {
  const { error, isLoading, runAction, data } = useAsyncFetch({
    loadAction,
    isInitiallyLoading: true,
  })

  useEffect(
    () => {
      runAction()
    },
    Array.isArray(triggerProp) ? triggerProp : [triggerProp],
  )

  return (
    // @ts-ignore
    <AsyncFetchWrapperFromState
      {...props}
      isLoading={isLoading || manualIsLoading || false}
      reload={runAction}
      error={error}
      data={data}
    />
  )
}

export const AsyncFetchWrapperFromState = function <T>({
  children,
  loaderItemCount = 1,
  showErrorState = true,
  isLoading,
  error,
  reload,
  data,
}: AsyncFetchWrapperProps<T> & {
  error?: any
  isLoading: boolean
  reload?: (...p: any) => Promise<any>
  data?: T
}): JSX.Element {
  if (!isLoading && error && showErrorState) {
    return <ErrorView onReload={reload} />
  }

  if (typeof children === 'function') {
    if (!isLoading) {
      if (data === undefined) {
        return <ErrorView onReload={reload} />
      }

      return children(data)
    }

    return <Loaders loaderItemCount={loaderItemCount} />
  }

  return (
    <>{isLoading ? <Loaders loaderItemCount={loaderItemCount} /> : children}</>
  )
}

interface ErrorViewProps {
  onReload?: (...p: any) => Promise<any>
}

export const ErrorView = ({ onReload }: ErrorViewProps) => {
  return (
    <ErrorContainer>
      <ErrorTextContainer>
        <Box sx={{ marginBottom: 4 }}>
          <Typography variant='h4'>Oops, something went wrong.</Typography>
        </Box>
        <Box>
          <Typography variant='h5'>
            Please try again later. If the problem persists please contact
            support.
          </Typography>
        </Box>
      </ErrorTextContainer>
      {!!onReload && (
        <Box>
          <Button
            size='large'
            color='secondary'
            variant='outlined'
            onClick={onReload}
            sx={{ padding: 4 }}
          >
            <Typography variant='h6'>Try Again</Typography>
          </Button>
        </Box>
      )}
    </ErrorContainer>
  )
}

const ErrorContainer = styled('div')(() => ({
  minHeight: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}))

const ErrorTextContainer = styled('div')(({ theme }) => ({
  textAlign: 'center',
  marginBottom: theme.spacing(8),
  wordWrap: 'break-word',
  maxWidth: '80%',
}))

export { AsyncFetchWrapper }
