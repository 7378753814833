import { DialogStore } from './dialogs/dialog-store'
import { SnackbarStore } from './snackbars/snackbar-store'
import { GeneralStore } from './general-store'
import React from 'react'
import { AuthStore } from './auth/auth-store'
import { PrintStore } from './orders/print-store'

class RootStore {
  dialogStore: DialogStore
  snackbarStore: SnackbarStore
  generalStore: GeneralStore
  authStore: AuthStore
  printStore: PrintStore

  constructor() {
    this.snackbarStore = new SnackbarStore()
    this.dialogStore = new DialogStore()
    this.generalStore = new GeneralStore()
    this.authStore = new AuthStore()
    this.printStore = new PrintStore()
  }
}

const rootStore = new RootStore()
const StoreContext = React.createContext<RootStore>(rootStore)
const { Provider: StoreProvider } = StoreContext
const useStore = () => React.useContext(StoreContext)

export { RootStore, StoreContext, StoreProvider, useStore, rootStore }
