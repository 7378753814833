import LoadingButton from '@/components/loading-button'
import { useStore } from '@/root-store'
import { storesService } from '@/stores/store-service'
import { cn } from '@/utils'
import { useCallback, useEffect, useState } from 'react'

export default function FreeDeliveriesBanner() {
  const { authStore } = useStore()
  const [loading, setLoading] = useState(false)

  const onClick = useCallback(async () => {
    if (authStore.currentStoreId) {
      setLoading(true)
      const response = await storesService.getStoreBillingPortalUrl(
        authStore.currentStoreId,
      )
      window.open(
        response.data.billingPortalUrl,
        '_blank',
        'noopener noreferrer',
      )
      setLoading(false)
    }
  }, [authStore.currentStoreId])

  useEffect(() => {
    authStore.getCurrentStore()
  }, [authStore, authStore.currentStoreId])

  if (
    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
    !authStore.currentStore ||
    authStore.currentStore.free_deliveries_remaining === null ||
    authStore.currentStore.has_payment_method
  ) {
    return null
  }

  return (
    <div
      className={cn(
        '-mx-4 flex flex-wrap items-center justify-between gap-4 px-6 py-2 sm:flex-nowrap',
        authStore.currentStore.free_deliveries_remaining === 0
          ? 'bg-[#FF8282]'
          : 'bg-[linear-gradient(270deg,#FF961B_0%,#FFB800_100%)]',
      )}
    >
      <p className='text-pretty text-base/normal'>
        {authStore.currentStore.free_deliveries_remaining === 0
          ? 'You ran out of free deliveries!'
          : 'First 5 deliveries are on us!'}{' '}
        Add a payment method now for a seamless experience.
      </p>
      {!authStore.currentStore.has_payment_method && (
        <LoadingButton
          loading={loading}
          className={cn(
            'shrink-0 rounded-md px-4 py-2 text-sm/normal font-medium',
            authStore.currentStore.free_deliveries_remaining === 0
              ? 'bg-white'
              : 'bg-[#FFE5C0]',
          )}
          onClick={onClick}
        >
          Add Payment Method
        </LoadingButton>
      )}
    </div>
  )
}
