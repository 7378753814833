import { cn } from '@/utils'
import { useCallback, useMemo } from 'react'
import { DeliveryStatus, LiveOrder } from '../orders/models'
import { ExtendedCube } from './extended-cube'
import { useDragEvents } from './live-orders-tablet'
import { ReadyCube } from './ready-cube'

export const OrderCube = ({
  order,
  reloadOrders,
  onExpand,
  minimized,
}: {
  order: LiveOrder
  reloadOrders: () => void
  onExpand: (order: LiveOrder) => void
  minimized?: boolean
}) => {
  const dragEvents = useDragEvents()
  const allowDrag = !!order.drag_action

  const delivery = useMemo(
    () =>
      order.deliveries?.find(
        (d) =>
          ![DeliveryStatus.Pending, DeliveryStatus.Canceled].includes(
            d.delivery_status,
          ),
      ),
    [order.deliveries],
  )

  const onDragStart = useCallback(
    (event: React.DragEvent<HTMLDivElement>) =>
      dragEvents.dragStart(event, order),
    [],
  )

  const onDragEnd = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.currentTarget.style.opacity = '1'

    const draggable = document.getElementById('current-draggable')

    if (draggable?.parentNode) {
      draggable.parentNode.removeChild(draggable)
    }
  }, [])

  return (
    <div
      className={cn(
        'cursor-default rounded-lg bg-white',
        allowDrag && 'cursor-grab',
        !minimized && 'min-h-[max(100%,315px)]',
      )}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      draggable={allowDrag}
      onClick={() => onExpand(order)}
    >
      {minimized ? (
        <ReadyCube
          order={order}
          reloadOrders={reloadOrders}
          onExpand={() => onExpand(order)}
        />
      ) : (
        <ExtendedCube
          order={order}
          delivery={delivery}
          reloadOrders={reloadOrders}
          onExpand={() => onExpand(order)}
        />
      )}
    </div>
  )
}
