export default function DeleteIcon({ className }: { className?: string }) {
  return (
    <svg viewBox='0 0 32 32' fill='none' className={className}>
      <path
        d='M12.5334 22L16 18.5333L19.4667 22L21.3334 20.1333L17.8667 16.6667L21.3334 13.2L19.4667 11.3333L16 14.8L12.5334 11.3333L10.6667 13.2L14.1334 16.6667L10.6667 20.1333L12.5334 22ZM9.33337 28C8.60004 28 7.97226 27.7389 7.45004 27.2167C6.92782 26.6944 6.66671 26.0667 6.66671 25.3333V8H5.33337V5.33333H12V4H20V5.33333H26.6667V8H25.3334V25.3333C25.3334 26.0667 25.0723 26.6944 24.55 27.2167C24.0278 27.7389 23.4 28 22.6667 28H9.33337ZM22.6667 8H9.33337V25.3333H22.6667V8Z'
        fill='currentColor'
      />
    </svg>
  )
}
