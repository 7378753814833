import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form'
import Input, { InputProps } from './input'

interface Props<T extends FieldValues>
  extends Omit<InputProps, 'value' | 'onChange'> {
  control: Control<T>
  name: FieldPath<T>
}

export default function FormInput<T extends FieldValues>({
  control,
  name,
  type,
  ...props
}: Props<T>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <Input
          value={value ?? ''}
          onChange={(event) =>
            onChange(
              type === 'number'
                ? event.target.valueAsNumber
                : event.target.value,
            )
          }
          error={error?.message}
          type={type}
          {...props}
        />
      )}
    />
  )
}
