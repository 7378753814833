import { DependencyList, EffectCallback, useEffect, useState } from 'react'

export function toDictionary<Item, K extends number | string, V = Item>(
  array: Item[],
  keyFunc: (item: Item) => K,
  valueFunc?: (item: Item) => V,
): Record<K, V> {
  return array.reduce<Record<K, V>>(
    (acc, curr) => {
      acc[keyFunc(curr)] = valueFunc ? valueFunc(curr) : (curr as any as V)
      return acc
    },
    {} as Record<K, V>,
  )
}

export function toMapArrayWithValueMapper<Item, K extends number | string, V>(
  array: Item[],
  keyFunc: (item: Item) => K,
  valueFunc: (item: Item) => V,
) {
  return array.reduce<Record<K, V[]>>(
    (acc, curr) => {
      const key = keyFunc(curr)
      acc[key] = acc[key] || []
      acc[key].push(valueFunc(curr))
      return acc
    },
    {} as Record<K, V[]>,
  )
}

export function toMapArray<K extends number | string, V>(
  array: V[],
  keyFunc: (item: V) => K,
) {
  return toMapArrayWithValueMapper(array, keyFunc, (i) => i)
}

export function getChunks<T>(array: T[], chunkSize: number) {
  return array.reduce<T[][]>((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / chunkSize)

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []
    }

    resultArray[chunkIndex].push(item)
    return resultArray
  }, [])
}

export const useEffectNotFirstTime = (
  effect: EffectCallback,
  deps?: DependencyList,
) => {
  const [firstTime, setFirstTime] = useState(true)
  useEffect(() => {
    if (firstTime) {
      setFirstTime(false)
      return
    }

    return effect()
  }, deps)
}

export const useEffectFirstTime = (
  effect: EffectCallback,
  deps?: DependencyList,
) => {
  const [firstTime, setFirstTime] = useState(true)
  useEffect(() => {
    if (firstTime) {
      setFirstTime(false)
      return effect()
    }

    return
  }, deps)
}
