import { action, makeAutoObservable } from 'mobx'
import { FunctionComponent } from 'react'
import { v4 as uuid } from 'uuid'
import {
  BasicTextBoxDialog,
  BasicTextBoxDialogProps,
  PromptDialog,
  PromptDialogProps,
} from './general-dialogs'
import NewPromptDialog, { NewPromptDialogProps } from './prompt-dialog'

export const wait = (milliseconds: number) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds))
}

export interface CustomDialogBaseProps {
  resolve: (data?: any) => any
  reject: () => any
  open: boolean
}

export class DialogStore {
  public props?: any = null
  public customDialogs: {
    [key: string]: {
      component: FunctionComponent<any>
      props: any
      additionalProps: CustomDialogBaseProps
    }
  } = {}

  constructor() {
    makeAutoObservable(this, { props: false })
  }

  private setOpenForDialog = (id: string, open: boolean) => {
    action(() => {
      this.customDialogs[id] = {
        ...this.customDialogs[id],
        additionalProps: {
          ...this.customDialogs[id].additionalProps,
          open,
        },
      }
    })()
  }

  private hideCustomDialog = async (id: string) => {
    if (!this.customDialogs[id]) {
      return
    }

    this.setOpenForDialog(id, false)

    // we wait to display close animation
    await wait(300)

    action(() => {
      delete this.customDialogs[id]
    })()
  }

  public showTextBoxDialogAsync = async (props?: BasicTextBoxDialogProps) => {
    const res = (await this.showDialogAsync(BasicTextBoxDialog, props)) as {
      text: string
    }
    return res.text
  }

  public showDialogAsync = async <Props>(
    component: FunctionComponent<Props>,
    props?: Omit<Props, keyof CustomDialogBaseProps>,
    rejectOnDismiss: boolean = true,
  ) => {
    const id = uuid()

    // eslint-disable-next-line no-async-promise-executor,no-return-await
    return await new Promise<any>(async (resolve, reject) => {
      action(() => {
        this.customDialogs[id] = {
          component,
          props,
          additionalProps: {
            resolve: (data: any) => {
              resolve(data)
              this.hideCustomDialog(id)
            },
            reject: () => {
              if (rejectOnDismiss) {
                reject()
              }
              this.hideCustomDialog(id)
            },
            open: false,
          },
        }
      })()
      await wait(0)
      action(() => {
        this.setOpenForDialog(id, true)
      })()
    })
  }

  showPromptAsync = async <T = boolean>(
    props: PromptDialogProps<T>,
  ): Promise<T | false> => {
    try {
      const res = await this.showDialogAsync(PromptDialog, props)
      return res || true
    } catch (e) {
      return false
    }
  }

  showNewPromptAsync = async (
    props: NewPromptDialogProps,
  ): Promise<boolean> => {
    try {
      const res = await this.showDialogAsync(NewPromptDialog, props)
      return res || true
    } catch (e) {
      return false
    }
  }
}
