import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form'
import { Switch, SwitchProps } from './switch'

interface Props<T extends FieldValues>
  extends Omit<SwitchProps, 'checked' | 'defaultChecked' | 'onCheckedChange'> {
  control: Control<T>
  name: FieldPath<T>
}

export default function FormSwitch<T extends FieldValues>({
  control,
  name,
  ...props
}: Props<T>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange } }) => (
        <Switch checked={value} onCheckedChange={onChange} {...props} />
      )}
    />
  )
}
