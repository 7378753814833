import { cn } from '@/utils'

export default function Spinner({ className }: { className?: string }) {
  return (
    <svg viewBox='22 22 44 44' className={cn('animate-spin', className)}>
      <circle
        cx='44'
        cy='44'
        r='20.2'
        fill='none'
        stroke='currentColor'
        strokeDasharray='80px,200px'
        strokeDashoffset={0}
        strokeWidth='3.6'
        className='animate-spinner'
      ></circle>
    </svg>
  )
}
