import { throttle } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { Control, Controller, FieldPath } from 'react-hook-form'
import { FieldValues } from 'react-hook-form/dist/types/fields'
import { z } from 'zod'
import { axiosClient } from '../axios-client'
import { AutoComplete, Option } from './ui/autocomplete'

export const locationSchema = z.object({
  display_string: z.string(),
  google_place_id: z.string(),
})

export const AutocompleteAddressWrapper = <T extends FieldValues>({
  control,
  name,
  label,
  required,
  isOnboarding,
}: {
  control: Control<T>
  name: FieldPath<T>
  label: string
  required?: boolean
  isOnboarding?: boolean
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <div className='w-full'>
          <AutoCompleteLocation
            onChange={onChange}
            value={value}
            label={label}
            required={required}
            isOnboarding={isOnboarding}
          />
          {error && (
            <p className='ml-1 mt-1 text-xs text-red-500'>{error.message}</p>
          )}
        </div>
      )}
    />
  )
}

export const AutoCompleteLocation = ({
  value,
  onChange,
  label,
  required,
  isOnboarding,
}: {
  value?: z.infer<typeof locationSchema> | null
  onChange: (value: z.infer<typeof locationSchema> | null) => void
  label: string
  required?: boolean
  isOnboarding?: boolean
}) => {
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState<Option[]>([])

  const fetch = useMemo(
    () =>
      throttle(
        async (query: string, callback: (results?: Option[]) => void) => {
          if (query) {
            const { data } = await axiosClient.get(
              `${isOnboarding ? '/onboarding' : ''}/geo?q=${query}`,
            )
            callback(
              data.results?.map((s: any) => ({
                value: s.place_id,
                label: s.description,
              })),
            )
          }
        },
        750,
      ),
    [isOnboarding],
  )

  useEffect(() => {
    if (inputValue !== value?.display_string) {
      fetch(inputValue, (results) => {
        const newOptions: Option[] = []

        if (results) {
          newOptions.push(...results)
        }

        setOptions(newOptions)
      })
    }
  }, [inputValue, fetch, value])

  return (
    <AutoComplete
      options={options}
      emptyMessage='No results.'
      placeholder={label}
      onInputChange={(value) => setInputValue(value)}
      onValueChange={(value) => {
        setInputValue(value.label)
        onChange({
          display_string: value.label,
          google_place_id: value.value,
        })
      }}
      value={
        value
          ? {
              label: value.display_string,
              value: value.google_place_id,
            }
          : undefined
      }
      required={required}
    />
  )
}
