import { axiosClient, useRunRequest } from '../axios-client'
import { CSSProperties, useCallback, useState } from 'react'
import {
  Action,
  ActionType,
  LiveOrder,
  Order,
  OrderAction,
} from '../orders/models'
import { DialogStore } from '../dialogs/dialog-store'
import { useStore } from '../root-store'
import { IconButton, Menu, MenuItem } from '@mui/material'
import LoadingButton from '../components/loading-button'
import { Link } from 'react-router-dom'
import { MoreVert } from '@mui/icons-material'
import { CreateDeliveryDialog } from '../orders/create-delivery-dialog'
import { updatePrepTime } from '../orders/utils'
import { DateTime } from 'luxon'
import { cn } from '@/utils'
import AcceptScheduledOrderDialog from '@/orders/accept-scheduled-order'
import { PrintStore } from '@/orders/print-store'
import OutOfFreeDeliveries from '@/orders/out-of-free-deliveries'

interface Colors {
  bg: string
  bgHover: string
  text: string
}

const colors: Record<Exclude<OrderAction['color'], undefined>, Colors> = {
  primary: {
    bg: '3 194 232',
    bgHover: '2 135 162',
    text: '255 255 255',
  },
  secondary: {
    bg: '229 249 253',
    bgHover: '205 244 251',
    text: '0 157 224',
  },
  yellow: {
    bg: '250 197 115',
    bgHover: '250 197 115',
    text: '0 0 0',
  },
  error: {
    bg: '218 80 58',
    bgHover: '152 56 40',
    text: '255 255 255',
  },
}

export const useHandleAction = () => {
  const runRequest = useRunRequest()

  const handleAction = useCallback(
    async ({
      order,
      action,
      dialogStore,
      printStore,
      reloadOrder,
    }: {
      order: Order
      action: OrderAction
      dialogStore: DialogStore
      printStore: PrintStore
      reloadOrder?: () => void
    }) => {
      switch (action.type) {
        case ActionType.PageUrl:
          return window.open(action.link, '_blank')
        case ActionType.Api:
          switch (action.action) {
            case Action.SendSMS:
              return dialogStore.showTextBoxDialogAsync({
                textBoxPlaceholder: 'Body',
                dialogTitle: 'Send SMS',
                resolveButton: `Send to ${order.customer_name}`,
                onBeforeResolve: async (sms) => {
                  await axiosClient.post(action.api, { sms_body: sms })
                  return reloadOrder?.()
                },
              })
            case Action.UpdatePrepTime:
              return updatePrepTime({
                dialogStore,
                postUpdate: async (minutes) => {
                  await axiosClient.patch(action.api, {
                    pickup_time: DateTime.utc().plus({ minutes }).toISO(),
                  })
                  return reloadOrder?.()
                },
              })
            case Action.Accept:
              if (
                order.is_pickup ||
                (order.is_scheduled && order.scheduled_prepare_time)
              ) {
                await axiosClient.post(action.api, {})
                if (window.AndroidInterface) {
                  printStore.addToPrintQueue(order)
                }
                return reloadOrder?.()
              } else {
                return dialogStore.showDialogAsync(CreateDeliveryDialog, {
                  order,
                  submit: async (params) => {
                    await axiosClient.post(action.api, params)
                    if (window.AndroidInterface) {
                      printStore.addToPrintQueue(order)
                    }
                    return reloadOrder?.()
                  },
                })
              }
            case Action.AcceptScheduled:
              return dialogStore.showDialogAsync(AcceptScheduledOrderDialog, {
                order,
                submit: async (params) => {
                  await axiosClient.post(action.api, params)
                  return reloadOrder?.()
                },
              })
            case Action.CreateDelivery: {
              await dialogStore.showDialogAsync(CreateDeliveryDialog, {
                order,
              })
              return reloadOrder?.()
            }
          }

          return runRequest.action({
            action: async () => {
              await axiosClient.post(action.api, {})
              return reloadOrder?.()
            },
          })
        case ActionType.Call:
          return window.open(action.phone, '_blank')
        case ActionType.Custom: {
          switch (action.action) {
            case Action.AddPaymentMethod:
              return dialogStore.showDialogAsync(OutOfFreeDeliveries)
          }
          break
        }
      }
    },
    [],
  )

  return { handleAction, isLoading: runRequest.loading }
}

export const ActionsComponent = ({
  order,
  reloadOrder,
  actions,
  minimized,
}: {
  order: Order
  reloadOrder: () => void
  actions: (OrderAction | OrderAction[])[]
  minimized?: boolean
}) => {
  if (!actions.length) {
    return null
  }

  return (
    <>
      {actions.map((a, i) => (
        <div key={i} className='flex gap-2'>
          {(Array.isArray(a) ? a : [a]).map((a, i) => (
            <OrderActionButton
              key={i}
              minimized={minimized}
              order={order}
              action={a}
              reloadOrder={reloadOrder}
            />
          ))}
        </div>
      ))}
    </>
  )
}

export const OrderActionButton = ({
  minimized,
  order,
  action,
  reloadOrder,
}: {
  minimized?: boolean
  order: Order
  action: OrderAction
  reloadOrder: () => void
}) => {
  const { handleAction, isLoading } = useHandleAction()
  const { dialogStore, printStore } = useStore()
  const actionColors = colors[action.color ?? 'primary']

  if (action.link) {
    return (
      <Link
        target='_blank'
        to={action.link}
        className={cn(
          'w-full rounded text-center leading-normal transition-colors duration-300',
          minimized ? 'p-2 text-xs' : 'p-4 text-sm font-semibold',
          !action.variant &&
            'bg-[rgb(var(--bg))] text-[rgb(var(--text))] hover:bg-[rgb(var(--bg-hover))]',
          action.variant === 'outlined' &&
            'border border-[rgb(var(--bg)/.5)] text-[rgb(var(--bg))] hover:border-[rgb(var(--bg))] hover:bg-[rgb(var(--bg)/.05)]',
        )}
        style={
          {
            '--bg': actionColors.bg,
            '--bg-hover': actionColors.bgHover,
            '--text': actionColors.text,
          } as CSSProperties
        }
        onClick={(e) => e.stopPropagation()}
      >
        {action.title}
      </Link>
    )
  }

  return (
    <LoadingButton
      loading={isLoading}
      className={cn(
        'w-full rounded text-center leading-normal transition-colors duration-300',
        minimized ? 'p-2 text-xs' : 'p-4 text-sm font-semibold',
        !action.variant &&
          'bg-[rgb(var(--bg))] text-[rgb(var(--text))] enabled:hover:bg-[rgb(var(--bg-hover))]',
        action.variant === 'outlined' &&
          'border border-[rgb(var(--bg)/.5)] text-[rgb(var(--bg))] enabled:hover:border-[rgb(var(--bg))] enabled:hover:bg-[rgb(var(--bg)/.05)]',
      )}
      style={
        {
          '--bg': actionColors.bg,
          '--bg-hover': actionColors.bgHover,
          '--text': actionColors.text,
        } as CSSProperties
      }
      onClick={(e) => {
        e.stopPropagation()
        return handleAction({
          order,
          action,
          dialogStore,
          printStore,
          reloadOrder,
        })
      }}
    >
      {action.title}
    </LoadingButton>
  )
}

export const ActionsMenu = ({
  order,
  reload,
}: {
  order: LiveOrder
  reload: () => void
}) => {
  const { dialogStore, printStore } = useStore()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const { handleAction } = useHandleAction()

  if (!order.secondary_actions.length) {
    return null
  }

  return (
    <>
      <IconButton
        onClick={(event) => {
          event.stopPropagation()
          anchorEl ? setAnchorEl(null) : setAnchorEl(event.currentTarget)
        }}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id='menu'
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={(e) => {
          ;(e as any)?.stopPropagation?.()
          setAnchorEl(null)
        }}
      >
        {order.secondary_actions.map((a, i) => (
          <MenuItem
            key={i}
            {...(a.link
              ? ({
                  component: Link,
                  target: '_blank',
                  to: a.link,
                } as any)
              : {
                  onClick: (e) => {
                    e.stopPropagation()
                    setAnchorEl(null)
                    return handleAction({
                      order,
                      action: a,
                      dialogStore,
                      printStore,
                      reloadOrder: reload,
                    })
                  },
                })}
          >
            {a.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
