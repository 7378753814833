import { BasicOrderInfo } from '../order-details/basic-order-info'
import OrderActions from '../order-details/order-actions'
import { LiveOrder, OrderDelivery } from '../orders/models'
import { ActionsComponent } from './order-actions'
import { OrderItems } from './order-items'
import { OrderSpecialInstructions } from './review-orders-dialog'

export const ExtendedCube = ({
  order,
  delivery,
  reloadOrders,
  onExpand,
}: {
  order: LiveOrder
  delivery?: OrderDelivery
  reloadOrders: () => void
  onExpand: () => void
}) => {
  return (
    <div className='flex h-full flex-col'>
      <OrderActions
        order={order}
        delivery={delivery}
        reloadOrder={reloadOrders}
        onExpand={onExpand}
      />

      <div className='h-full overflow-auto p-5'>
        <BasicOrderInfo order={order} delivery={delivery} />
        <OrderSpecialInstructions order={order} />
        <OrderItems order={order} />
      </div>

      <div className='flex flex-col gap-1 border-t border-[#E8E8E8] p-5 empty:hidden'>
        <ActionsComponent
          order={order}
          reloadOrder={reloadOrders}
          actions={order.main_actions}
        />
      </div>
    </div>
  )
}
