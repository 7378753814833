import { Button, buttonVariants } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { cn } from '@/utils'
import { VariantProps } from 'class-variance-authority'
import { AsyncDialogBaseProps } from './models'

type ButtonVariant = VariantProps<typeof buttonVariants>['variant']

export interface NewPromptDialogProps {
  title: string
  description?: string
  resolveVariant?: ButtonVariant
  rejectVariant?: ButtonVariant
  buttonsClassName?: string
}

export default function NewPromptDialog({
  open,
  title,
  description,
  resolve,
  reject,
  resolveVariant,
  rejectVariant,
  buttonsClassName,
}: NewPromptDialogProps & AsyncDialogBaseProps) {
  return (
    <Dialog open={open} onOpenChange={reject}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
          {description && <DialogDescription>{description}</DialogDescription>}
        </DialogHeader>

        <div className={cn('flex gap-2', buttonsClassName)}>
          <Button
            variant={rejectVariant ?? 'outline'}
            className='w-full'
            onClick={() => reject()}
          >
            Cancel
          </Button>
          <Button
            variant={resolveVariant ?? 'default'}
            className='w-full'
            onClick={() => resolve(true)}
          >
            Yes
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}
