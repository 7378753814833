import { Box, IconButton, Typography, useTheme } from '@mui/material'
import { Call } from '@mui/icons-material'
import crispLogo from '/crisp-chat.svg'
import { ChatboxPosition, Crisp } from 'crisp-sdk-web'
import { useEffect } from 'react'
import { observer } from 'mobx-react'
import { useStore } from '../root-store'
import { styledWithConditionalProps } from '../utils/styled'
import { useEffectNotFirstTime } from '../utils/hooks'
import useMediaQuery from '@mui/material/useMediaQuery'

export const CrispPosition = observer(() => {
  const { generalStore } = useStore()

  useEffect(() => {
    try {
      Crisp.setPosition(generalStore.crispPosition)
    } catch (e) {
      return
    }
  }, [generalStore.crispPosition])

  return null
})

export const CrispBox = observer(() => {
  const { generalStore } = useStore()

  useEffect(() => {
    Crisp.chat.onChatClosed(() => {
      generalStore.setCrispOpen(false)
    })

    // Crisp.chat.onChatOpened(() => {
    //   // generalStore.setCrispOpen(true)
    // })
  }, [])

  useEffectNotFirstTime(() => {
    if (!Crisp.isCrispInjected() || !window.$crisp?.is) {
      return
    }
    Crisp.createSingletonIfNecessary()

    if (generalStore.crispOpen !== Crisp.chat.isVisible()) {
      if (generalStore.crispOpen) {
        Crisp.chat.show()
        Crisp.chat.open()
      } else {
        Crisp.chat.close()
        Crisp.chat.hide()
      }
    }
  }, [generalStore.crispOpen])

  const theme = useTheme()
  const xlOrUp = useMediaQuery(theme.breakpoints.up('xl'))

  if (!xlOrUp) {
    return null
  }

  return (
    <Root
      $position={generalStore.crispPosition}
      sx={generalStore.crispOpen ? { display: 'none' } : {}}
      onClick={(e) => {
        e.stopPropagation()
        e.preventDefault()
        generalStore.setCrispOpen(true)
      }}
    >
      <Typography>Contact us</Typography>
      <Box>
        <Typography>(833) 942-3303</Typography>
        <IconButton size='small'>
          <Call sx={{ color: 'white' }} />
        </IconButton>
      </Box>
      <Box>
        <Box
          sx={{
            backgroundColor: 'white',
            width: 150,
            borderRadius: 10,
            flex: 1,
            padding: 4,
            paddingTop: 1,
            paddingBottom: 1,
            paddingRight: 10,
          }}
        >
          <Typography typography='body2' color='#78797E !important'>
            Start Chat...
          </Typography>
        </Box>
        {/*  variant={'outlined'}*/}
        {/*  size={'small'}*/}
        {/*  label={'Start Chat...'}*/}
        {/*  color={'secondary'}*/}
        {/*  disabled={true}*/}
        {/*  sx={{ borderRadius: 10 }}*/}
        {/*  InputProps={{*/}
        {/*    sx: {*/}
        {/*      borderRadius: 10,*/}
        {/*      height: 35,*/}
        {/*      backgroundColor: 'white',*/}
        {/*      // '& > input': { padding: 1 },*/}
        {/*    },*/}
        {/*  }}*/}
        {/*/>*/}
        <img src={crispLogo} />
      </Box>
    </Root>
  )
})

const Root = styledWithConditionalProps(Box)<{ $position: ChatboxPosition }>(
  ({ theme, $position }) => ({
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
    gap: theme.spacing(0),
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.spacing(7),
    padding: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    cursor: 'pointer',
    bottom: theme.spacing(5),
    zIndex: theme.zIndex.drawer + 1,

    ...($position === ChatboxPosition.Left
      ? {
          left: theme.spacing(5),
        }
      : { right: theme.spacing(5) }),

    '& *': {
      color: 'white',
      borderColor: 'white !important',
      cursor: 'pointer',
    },

    '& *.MuiOutlinedInput-notchedOutline': {
      borderColor: 'white !important',
    },

    '& > *': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: theme.spacing(3),
      alignSelf: 'stretch',
    },
  }),
)
