import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  IconButton,
  MobileStepper,
  Paper,
  Typography,
} from '@mui/material'
import { DialogBody } from '../dialogs/general-dialogs'
import { AsyncDialogBaseProps } from '../dialogs/models'
import { AsyncFetchWrapper } from '../components/async-fetch-wrapper'
import { useCallback, useMemo, useState } from 'react'
import { ordersService } from '../orders/order-service'
import {
  LiveOrder,
  Order,
  OrderDetails,
  OrderStatus,
  OrderStatuses,
} from '../orders/models'
import { LoadingButton } from '@mui/lab'
import { useRunRequest } from '../axios-client'
import { CreateDeliveryDialog } from '../orders/create-delivery-dialog'
import { useStore } from '../root-store'
import { styled } from '@mui/material/styles'
import {
  Close,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from '@mui/icons-material'
import { pluralSuffixOptionalString } from '../utils/general'
import SwipeableViews from 'react-swipeable-views'
import { DeliveryDetails } from '../order-details/order-component'
import { BasicOrderInfo } from '../order-details/basic-order-info'
import { OrderItems } from './order-items'
import { CreateDelivery } from '../orders/schema'

export const ReviewOrdersDialog = (
  props: AsyncDialogBaseProps & { pending: LiveOrder[] },
) => {
  const { dialogStore } = useStore()
  const [orders, setOrders] = useState<OrderDetails[]>([])
  const [currentOrderIndex, setCurrentOrderIndex] = useState<number>(0)
  const runRequest = useRunRequest()
  const loadPendingReview = useCallback(async () => {
    const res = await ordersService.pendingReviewOrders()
    setOrders(res.data)
  }, [])

  const postAction = useCallback(
    (status: OrderStatus) => {
      setOrders(
        orders.map((o, i) => (i === currentOrderIndex ? { ...o, status } : o)),
      )
      setCurrentOrderIndex(currentOrderIndex + 1)
      if (currentOrderIndex + 1 === orders.length) {
        props.resolve()
      }
    },
    [orders],
  )

  const pendingApproval = useMemo(
    () => orders.filter((o) => o.status === OrderStatus.Created),
    [orders],
  )

  const currentOrder = orders[currentOrderIndex]

  return (
    <DialogStyled open={props.open} fullWidth={true} onClose={props.reject}>
      <AsyncFetchWrapper loadAction={loadPendingReview}>
        {!!currentOrder && (
          <DialogBody sx={{ pb: 2, pt: 0 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                position: 'relative',
              }}
            >
              <DialogTitle
                variant='h5'
                alignSelf='center'
                mb={1}
                mt={0}
                color='white'
              >
                You have {pendingApproval.length} order
                {pluralSuffixOptionalString(pendingApproval)} waiting for
                approval
              </DialogTitle>
              <Box
                sx={{
                  position: 'absolute',
                  right: 0,
                  top: 0,
                  bottom: 0,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <IconButton onClick={() => props.reject()}>
                  <Close sx={{ fill: 'white' }} />
                </IconButton>
              </Box>
            </Box>

            <Paper
              sx={{
                mt: 0,
                padding: 4,
                borderRadius: 2,
                overflow: 'hidden',
                display: 'flex',
              }}
            >
              <SwipeableViews
                axis='x'
                style={{ flex: 1 }}
                enableMouseEvents={true}
                onChangeIndex={(index) => {
                  setCurrentOrderIndex(index)
                }}
                // springConfig={{
                //   duration: '3s',
                //   easeFunction: 'linear',
                //   delay: '0s',
                // }}
                animateTransitions={true}
                index={Math.max(currentOrderIndex, 0)}
              >
                {orders.map((o) => (
                  <OrderReview key={o.id} order={o} />
                ))}
              </SwipeableViews>
            </Paper>

            <DialogActions
              sx={{ padding: 0, gap: 4, justifyContent: 'center' }}
            >
              {currentOrder.status === OrderStatus.Created ? (
                <>
                  <Button
                    fullWidth={true}
                    sx={{
                      // backgroundColor: 'lightgray',
                      backgroundColor: '#c49f9f',
                      color: 'black',
                      paddingTop: 3,
                      paddingBottom: 3,
                      flex: 1,
                    }}
                    variant='contained'
                    size='large'
                    onClick={async () => {
                      await ordersService.rejectOrder(currentOrder.id)
                      postAction(OrderStatus.Rejected)
                    }}
                  >
                    <Typography typography='h6'>Reject</Typography>
                  </Button>
                  <LoadingButton
                    fullWidth={true}
                    variant='contained'
                    color='secondary'
                    sx={{
                      // color: 'white',
                      paddingTop: 3,
                      paddingBottom: 3,
                      flex: 3,
                    }}
                    size='large'
                    type='submit'
                    onClick={() => {
                      const submit = (params?: CreateDelivery) =>
                        runRequest.action({
                          action: async () => {
                            await ordersService.acceptOrder(
                              currentOrder.id,
                              params,
                            )
                            postAction(OrderStatus.Accepted)
                          },
                        })

                      if (currentOrder.is_pickup) {
                        submit()
                      } else {
                        return dialogStore.showDialogAsync(
                          CreateDeliveryDialog,
                          { order: currentOrder, submit },
                        )
                      }
                    }}
                    loading={runRequest.loading}
                  >
                    <Typography typography='h6'>Confirm</Typography>
                  </LoadingButton>
                </>
              ) : (
                <Typography color='white'>
                  {OrderStatuses[currentOrder.status].displayString}
                </Typography>
              )}
            </DialogActions>

            <MobileStepper
              steps={orders.length}
              position='static'
              variant='text'
              sx={{
                color: 'white',
                backgroundColor: 'transparent',
                padding: 0,
                // marginTop: `4px !important`,
              }}
              color='primary'
              activeStep={currentOrderIndex}
              nextButton={
                <Button
                  sx={{ color: 'white' }}
                  size='small'
                  onClick={() => {
                    if (currentOrderIndex >= orders.length - 1) {
                      props.resolve()
                    } else {
                      setCurrentOrderIndex(currentOrderIndex + 1)
                    }
                  }}
                >
                  Skip
                  <KeyboardArrowRight />
                </Button>
              }
              backButton={
                <Button
                  sx={{ color: 'white' }}
                  size='small'
                  onClick={() => setCurrentOrderIndex(currentOrderIndex - 1)}
                  disabled={currentOrderIndex === 0}
                >
                  <KeyboardArrowLeft />
                  Back
                </Button>
              }
            />
          </DialogBody>
        )}
      </AsyncFetchWrapper>
    </DialogStyled>
  )
}

const OrderReview = ({ order }: { order: OrderDetails }) => {
  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        overflow: 'hidden',
      }}
    >
      <BasicOrderInfo order={order} delivery={order.deliveries?.[0]} />
      <Divider />
      <DeliveryDetails order={order} delivery={order.deliveries?.[0]} />
      <Divider />
      <OrderItems order={order} />
    </Box>
  )
}

export const OrderSpecialInstructions = ({ order }: { order: Order }) => {
  if (!order.order_special_instructions) {
    return null
  }

  return (
    <div className='mb-6 flex flex-col gap-2 rounded border border-[#B7B7B7] bg-[rgb(217,217,217,.2)] p-2'>
      <p className='text-xs font-semibold leading-normal text-a2b-blue'>
        Special Instructions
      </p>
      <p className='text-xs leading-normal'>
        {order.order_special_instructions}
      </p>
    </div>
  )
}
export const CourierPickupInstructions = ({ order }: { order: Order }) => {
  return (
    <Box>
      <Typography variant='h6'>Pickup Instructions</Typography>
      <Typography>{order.courier_pickup_instructions || 'None'}</Typography>
    </Box>
  )
}

const DialogStyled = styled(Dialog)(() => ({
  '& .MuiDialog-paper': {
    // minWidth: 700,
    backgroundColor: '#262c79',
    borderRadius: 4,
    overflow: 'hidden',
  },
}))
