import { StoreRounded } from '@mui/icons-material'
import { DeliveryETA } from '../order-details/delivery-eta'
import OrderActions from '../order-details/order-actions'
import { LiveOrder } from '../orders/models'
import { generateShortName } from '../utils/general'
import { ActionsComponent } from './order-actions'

export const ReadyCube = ({
  order,
  reloadOrders,
  onExpand,
}: {
  order: LiveOrder
  reloadOrders: () => void
  onExpand: () => void
}) => {
  return (
    <div className='flex flex-col'>
      <OrderActions
        order={order}
        delivery={order.deliveries?.[0]}
        minimized
        reloadOrder={reloadOrders}
        onExpand={onExpand}
      />

      <div className='p-5'>
        {order.is_self_delivery && (
          <div className='mb-3.5 inline-flex items-center gap-2 rounded-full bg-[#E0CEF7] px-2 py-1 text-dark-bg'>
            <StoreRounded className='!h-4 !w-4' />
            <p className='text-xs font-medium leading-normal'>Self Delivery</p>
          </div>
        )}

        <div className='mb-3.5 flex flex-col justify-center gap-0.5'>
          <p className='text-sm font-semibold leading-normal'>
            #{order.display_id ?? order.external_id}
          </p>
          <p className='text-xs leading-normal'>
            {generateShortName(order.customer_name)}
          </p>
          <p className='text-xs leading-normal'>{order.customer_phone}</p>
        </div>

        {order.deliveries?.[0] && (
          <DeliveryETA delivery={order.deliveries[0]} flat />
        )}

        <div className='mt-3 flex flex-col gap-1 empty:hidden'>
          <ActionsComponent
            minimized
            order={order}
            reloadOrder={reloadOrders}
            actions={order.main_actions}
          />
        </div>
      </div>
    </div>
  )
}
