import LoadingButton from '@/components/loading-button'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import FormDayTimePicker from '@/components/ui/form-day-time-picker'
import FormInput from '@/components/ui/form-input'
import FormSwitch from '@/components/ui/form-switch'
import Wheel from '@/components/wheel'
import { AsyncDialogBaseProps } from '@/dialogs/models'
import { cn } from '@/utils'
import { useIsTouchScreen } from '@/utils/hooks'
import { zodResolver } from '@hookform/resolvers/zod'
import { debounce } from 'lodash'
import { DateTime } from 'luxon'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useRunRequest } from '../axios-client'
import { Order, OrderingPlatform, OrderStatus } from './models'
import { ordersService } from './order-service'
import { CreateDelivery, CreateDeliverySchema } from './schema'
import { useDefaultDeliveryValues } from './utils'
import { useStore } from '@/root-store'

export const CreateDeliveryDialog = (
  props: AsyncDialogBaseProps & {
    order: Order
    submit?: (params: CreateDelivery) => void
  },
) => {
  const isTouchScreen = useIsTouchScreen()
  const { dialogStore } = useStore()
  const [open, setOpen] = useState(true)
  const defaultValues = useDefaultDeliveryValues({ store: props.order.store })
  const { control, setValue, handleSubmit, watch } = useForm<CreateDelivery>({
    resolver: zodResolver(CreateDeliverySchema),
    defaultValues: {
      ...defaultValues,
      dropoff_notification_enabled: props.order.dropoff_notification_enabled,
    },
  })

  const schedule = watch('delivery.schedule')
  const isSelfDelivery = watch('delivery.self_delivery')
  const pickupDelayMinutes = watch('delivery.pickup_delay_minutes')

  const runRequest = useRunRequest()

  const onWheelChange = useCallback(
    debounce((idx) => setValue('delivery.pickup_delay_minutes', idx), 200),
    [setValue],
  )

  const onSubmit = useCallback((params: CreateDelivery) => {
    return runRequest.action({
      action: async () => {
        if (params.delivery.self_delivery) {
          const confirmation = await dialogStore.showNewPromptAsync({
            title: 'Are you sure you want to self deliver this order?',
            description: 'We will not call a courier for this order.',
            buttonsClassName: 'flex-col-reverse',
          })

          if (!confirmation) {
            return
          }
        }

        await (props.submit
          ? props.submit(params)
          : ordersService.createDelivery(props.order.id, params))
        setOpen(false)
      },
      defaultError: 'Error creating delivery',
    })
  }, [])

  useEffect(() => {
    if (!open) {
      props.resolve()
    }
  }, [open, props.resolve])

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className='max-w-2xl gap-10'>
        <DialogHeader>
          <DialogTitle className='leading-normal'>
            {props.order.status === OrderStatus.Accepted
              ? 'Request Courier'
              : 'Accept Order'}
          </DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='flex flex-col gap-4'>
            {!isSelfDelivery && (
              <>
                {props.order.ordering_platform === OrderingPlatform.Manual && (
                  <div>
                    <label
                      className={cn(
                        'flex cursor-pointer select-none items-center gap-4 text-base/normal',
                        isTouchScreen && 'mb-4',
                      )}
                    >
                      Scheduled Delivery Time
                      <FormSwitch control={control} name='delivery.schedule' />
                    </label>
                  </div>
                )}

                {schedule ? (
                  <FormDayTimePicker
                    control={control}
                    name='delivery.pickup_time'
                    disablePast
                    containerClassName='flex-row'
                  />
                ) : isTouchScreen ? (
                  <div className='relative flex h-60 items-center justify-center text-[#16191C]'>
                    <p className='absolute left-0 top-1/2 -translate-y-1/2 text-[#9D9D9D]'>
                      Adjust prep time
                    </p>
                    <Wheel
                      initIdx={pickupDelayMinutes ?? 0}
                      length={61}
                      width={23}
                      label='min'
                      setValue={(idx) => idx.toString()}
                      onChange={onWheelChange}
                    />
                    <p className='absolute right-0 top-1/2 -translate-y-1/2 text-[#9D9D9D]'>
                      Ready at{' '}
                      {DateTime.now()
                        .plus({ minutes: pickupDelayMinutes })
                        .toFormat('h:mm a')}
                    </p>
                  </div>
                ) : (
                  <div>
                    <FormInput
                      type='number'
                      control={control}
                      name='delivery.pickup_delay_minutes'
                      placeholder='Adjust prep time'
                      required
                    />
                  </div>
                )}
              </>
            )}

            <div className='flex flex-wrap justify-between gap-x-4 gap-y-2'>
              {!isSelfDelivery &&
                props.order.store?.dropoff_notifications_enabled && (
                  <label className='flex cursor-pointer select-none items-center gap-4 text-base/normal'>
                    Contact Customer upon Dropoff
                    <FormSwitch
                      control={control}
                      name='dropoff_notification_enabled'
                    />
                  </label>
                )}

              {props.order.store?.is_self_delivery_allowed && (
                <label className='flex cursor-pointer select-none items-center gap-4 text-base/normal'>
                  {props.order.store.is_a2b_delivery_active
                    ? 'Self Deliver This Order'
                    : 'Request a2b Delivery'}
                  <FormSwitch
                    control={control}
                    name='delivery.self_delivery'
                    inverse={!props.order.store.is_a2b_delivery_active}
                  />
                </label>
              )}
            </div>
          </div>

          <LoadingButton
            loading={runRequest.loading}
            className='mt-10 w-full rounded-lg bg-[#00C2E8]/60 p-4 text-sm font-semibold leading-normal'
          >
            {props.order.status !== OrderStatus.Accepted && 'Accept &'}{' '}
            {isSelfDelivery ? 'Self Deliver' : 'Request Courier'}
          </LoadingButton>
        </form>
      </DialogContent>
    </Dialog>
  )
}
