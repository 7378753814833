import { action, makeAutoObservable } from 'mobx'
import { Order } from './models'
import { DateTime } from 'luxon'

const LOCAL_STORAGE_PRINTED_ORDERS_KEY = 'printed_orders'

export class PrintStore {
  printQueue: Order[] = []

  constructor() {
    makeAutoObservable(this)
  }

  addToPrintQueue = (order: Order, forceAdd?: boolean) => {
    if (window.AndroidInterface) {
      window.AndroidInterface?.postMessage(
        JSON.stringify({
          action: 'print-order',
          payload: JSON.stringify({
            storeName: order.store.name,
            storePhone: order.store.pickup_contact?.phone_number,
            orderPlatform: order.ordering_platform,
            orderType: order.is_pickup ? 'PICKUP' : 'DELIVERY',
            orderId: order.id,
            orderTime: order.order_placed_at
              ? DateTime.fromISO(order.order_placed_at).toFormat(
                  'MMM. dd, h:mma',
                )
              : null,
            readyTime: order.merchant_requested_pickup_time
              ? DateTime.fromISO(order.merchant_requested_pickup_time).toFormat(
                  'MMM. dd, h:mma',
                )
              : null,
            customerName: order.customer_name,
            customerPhone: order.customer_phone,
            specialInstructions: order.order_special_instructions,
            items: order.items,
            total: order.order_value,
          }),
        }),
      )
    } else {
      if (
        (!forceAdd && this.hasPrintedOrder(order.id)) ||
        this.printQueue.find((o) => o.id === order.id)
      ) {
        return
      }
    }

    action(() => {
      this.printQueue = [...this.printQueue, order]
    })()
  }

  getAndRemoveFromQueue = () => {
    const order = this.printQueue[0]

    if (order) {
      action(() => {
        this.printQueue.splice(0, 1)
        this.printQueue = [...this.printQueue]
      })()

      this.addPrintedOrderToStorage(order)
    }

    return order
  }

  private hasPrintedOrder = (orderId: string) => {
    const printed = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_PRINTED_ORDERS_KEY) || '{}',
    ) as Record<string, string>

    return !!printed[orderId]
  }

  private addPrintedOrderToStorage = (order: Order) => {
    const printed = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_PRINTED_ORDERS_KEY) || '{}',
    ) as Record<string, string>

    printed[order.id] = order.created_at

    Object.keys(printed).forEach((orderId) => {
      if (DateTime.fromISO(printed[orderId]).diffNow('hours').hours < -1) {
        delete printed[orderId]
      }
    })
    localStorage.setItem(
      LOCAL_STORAGE_PRINTED_ORDERS_KEY,
      JSON.stringify(printed),
    )
  }
}
