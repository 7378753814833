import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import { Config, config } from '../config'
import { FirebaseOptions } from '@firebase/app'
import * as firebaseAuth from 'firebase/auth'

const firebaseConfig: { [k in Config['env']]?: FirebaseOptions } = {
  development: {
    apiKey: 'AIzaSyCPjLMoJ330F6hSmtPYqq9NEb1qEi9YudU',
    authDomain: 'a2b-dev-3f588.firebaseapp.com',
    projectId: 'a2b-dev-3f588',
    storageBucket: 'a2b-dev-3f588.appspot.com',
    messagingSenderId: '683681473898',
    appId: '1:683681473898:web:b0185b47412b347e5b3113',
  },
  prod: {
    apiKey: 'AIzaSyAWo4R7hKmRMiuoOdE-Q0H3CnxzH1nNUAs',
    authDomain: 'a2b-store-prod.firebaseapp.com',
    projectId: 'a2b-store-prod',
    storageBucket: 'a2b-store-prod.appspot.com',
    messagingSenderId: '3198382314',
    appId: '1:3198382314:web:75e81dd4b5af3b3a20e793',
  },
}

firebase.initializeApp(firebaseConfig[config.env]!)
const auth = firebase.auth()
const provider = new firebaseAuth.GoogleAuthProvider()
provider.setCustomParameters({ prompt: 'select_account' })

export { auth, provider }
