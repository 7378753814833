import { axiosClient } from '@/axios-client'
import { config } from '@/config'
import { useStore } from '@/root-store'
import { Button } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Spinner from './spinner'

export default function ConnectUberEatsPage() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { authStore } = useStore()
  const [message, setMessage] = useState<string | null>(null)

  useEffect(() => {
    if (searchParams.get('code')) {
      axiosClient
        .post('/stores/connect-uber-eats', {
          code: searchParams.get('code'),
          a2bStoreId: authStore.currentStoreId,
        })
        .then(() => setMessage('Uber Eats connected successfully'))
        .catch((error) => setMessage(error.response.data.error_text))
    } else if (searchParams.get('error')) {
      setMessage(searchParams.get('error'))
    } else {
      location.href = `https://auth.uber.com/oauth/v2/authorize/?client_id=${config.uberClientId}&response_type=code&redirect_uri=${location.origin + '/connect-uber-eats'}&scope=eats.pos_provisioning`
    }
  }, [authStore.currentStoreId, searchParams])

  return (
    <div className='flex h-full w-full items-center justify-center text-white'>
      {message ? (
        <div className='flex flex-col items-center'>
          <p className='mb-2'>{message}</p>
          <Button
            size='small'
            type='submit'
            color='info'
            variant='contained'
            onClick={() => navigate('/')}
          >
            Back to Live Orders
          </Button>
        </div>
      ) : (
        <Spinner className='w-8' />
      )}
    </div>
  )
}
