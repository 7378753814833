import { observer } from 'mobx-react'
import { Alert, Button, IconButton, Snackbar, styled } from '@mui/material'
import { SnackbarItem } from './snackbar-store'
import { Close } from '@mui/icons-material'
import { useStore } from '../root-store'

export const Snackbars = () => {
  return (
    <>
      <SnackbarMessages />
    </>
  )
}

const SnackbarMessages = observer(() => {
  const { snackbarStore } = useStore()
  return (
    <SnackbarContainer className={snackbarStore.horizontal}>
      {snackbarStore.snackbars.map((snackbar: SnackbarItem) => {
        const close = () => snackbarStore.onDismiss(snackbar.id)
        const action = (snackbar.action || !snackbar.removeCloseButton) && (
          <>
            {snackbar.action && (
              <Button variant='contained' onClick={snackbar.action.onPress}>
                {snackbar.action.label}
              </Button>
            )}
            {!snackbar.removeCloseButton && (
              <IconButton
                size='small'
                aria-label='close'
                color='inherit'
                onClick={close}
              >
                <Close fontSize='small' />
              </IconButton>
            )}
          </>
        )

        return (
          <Snackbar
            sx={{ position: 'relative', marginTop: 2 }}
            key={snackbar.id}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: snackbarStore.horizontal,
            }}
            open={true}
            onClose={(_, reason) => reason !== 'clickaway' && close()}
            action={action}
            message={!snackbar.severity && snackbar.title}
          >
            {snackbar.severity && (
              <Alert
                sx={{
                  paddingBottom: 0,
                  pointerEvents: 'none',
                  userSelect: 'none',
                }}
                action={action}
                onClose={close}
                variant='filled'
                severity={snackbar.severity}
              >
                {snackbar.title}
              </Alert>
            )}
          </Snackbar>
        )
      })}
    </SnackbarContainer>
  )
})

const SnackbarContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  bottom: 10,
  zIndex: 1400,
  position: 'fixed',
  '&.left': {
    alignItems: 'flex-start',
  },
  '&.right': {
    alignItems: 'flex-end',
    right: 0,
  },
  '&.center': {
    left: 0,
    right: 0,
  },
}))
