import {
  DialogButton,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { StepProps } from '../steps'

export default function OrderCanceledStep({ setOpen }: StepProps) {
  return (
    <>
      <DialogHeader className='mx-auto max-w-96 sm:text-center'>
        <DialogTitle className='mb-2.5'>Order Canceled</DialogTitle>
        <DialogDescription>
          We will send an SMS to the customer to let them know you can&apos;t
          accept the order.
        </DialogDescription>
      </DialogHeader>
      <DialogButton onClick={() => setOpen(false)}>Back to Orders</DialogButton>
    </>
  )
}
