import { cn } from '@/utils'
import {
  KeenSliderOptions,
  KeenSliderPlugin,
  TrackDetails,
  useKeenSlider,
} from 'keen-slider/react'
import { useEffect, useRef, useState } from 'react'

const wheelControls: KeenSliderPlugin = (slider) => {
  let touchTimeout: ReturnType<typeof setTimeout>
  let position: {
    x: number
    y: number
  }
  let wheelActive: boolean

  const dispatch = (event: WheelEvent, name: string) => {
    position.x -= event.deltaX
    position.y -= event.deltaY
    slider.container.dispatchEvent(
      new CustomEvent(name, {
        detail: {
          x: position.x,
          y: position.y,
        },
      }),
    )
  }

  const wheelStart = (event: WheelEvent) => {
    position = {
      x: event.pageX,
      y: event.pageY,
    }
    dispatch(event, 'ksDragStart')
  }

  const wheel = (event: WheelEvent) => dispatch(event, 'ksDrag')

  const wheelEnd = (event: WheelEvent) => dispatch(event, 'ksDragEnd')

  const eventWheel = (event: WheelEvent) => {
    event.preventDefault()
    if (!wheelActive) {
      wheelStart(event)
      wheelActive = true
    }
    wheel(event)
    clearTimeout(touchTimeout)
    touchTimeout = setTimeout(() => {
      wheelActive = false
      wheelEnd(event)
    }, 50)
  }

  slider.on('created', () => {
    slider.container.addEventListener('wheel', eventWheel, {
      passive: false,
    })
  })
}

export default function Wheel(props: {
  initIdx?: number
  label?: string
  length: number
  loop?: boolean
  perspective?: 'left' | 'right' | 'center'
  setValue?: (relative: number, absolute: number) => string
  width: number
  onChange?: (idx: number) => void
}) {
  const perspective = props.perspective || 'center'
  const wheelSize = 20
  const slides = props.length
  const slideDegree = 360 / wheelSize
  const slidesPerView = props.loop ? 9 : 1
  const [sliderState, setSliderState] = useState<TrackDetails | null>(null)
  const size = useRef(0)
  const options = useRef<KeenSliderOptions>({
    slides: {
      number: slides,
      origin: props.loop ? 'center' : 'auto',
      perView: slidesPerView,
    },

    vertical: true,

    initial: props.initIdx || 0,
    loop: props.loop,
    dragSpeed: (val) =>
      val *
      (size.current /
        ((size.current / 2) * Math.tan(slideDegree * (Math.PI / 180))) /
        slidesPerView),
    created: (s) => {
      size.current = s.size
    },
    updated: (s) => {
      size.current = s.size
    },
    detailsChanged: (s) => setSliderState(s.track.details),
    rubberband: !props.loop,
    mode: 'free-snap',
  })

  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>(options.current, [
    wheelControls,
  ])

  const [radius, setRadius] = useState(0)

  useEffect(() => {
    if (slider.current) {
      setRadius(slider.current.size / 2)
    }
  }, [slider])

  useEffect(() => {
    if (sliderState?.rel !== undefined) {
      props.onChange?.(sliderState.rel)
    }
  }, [props.onChange, sliderState?.rel])

  const slideValues = () => {
    if (!sliderState) {
      return []
    }
    const offset = props.loop ? 1 / 2 - 1 / slidesPerView / 2 : 0

    const values = []
    for (let i = 0; i < slides; i++) {
      const distance = sliderState
        ? (sliderState.slides[i].distance - offset) * slidesPerView
        : 0
      const rotate =
        Math.abs(distance) > wheelSize / 2
          ? 180
          : distance * (360 / wheelSize) * -1

      values.push({
        style: {
          transform: `rotateX(${rotate}deg) translateZ(${radius}px)`,
        },
        value: props.setValue
          ? props.setValue(i, sliderState.abs + Math.round(distance))
          : i,
      })
    }
    return values
  }

  return (
    <div
      className='block h-full w-full select-none overflow-visible'
      ref={sliderRef}
    >
      <div
        className='relative left-0 z-10 -mt-0.5 h-[calc(42%_+_2px)] w-full border-b-[0.5px] border-b-[#cccccc] bg-gradient-to-b from-white/90 to-white/50'
        style={{ transform: `translateZ(${radius}px)` }}
      />
      <div
        className={cn(
          'flex h-[16%] w-full items-center justify-center [perspective:1000px] [transform-style:preserve-3d]',
          perspective === 'left' &&
            '-translate-x-2.5 [perspective-origin:calc(50%-100px)_50%]',
          perspective === 'right' &&
            'translate-x-2.5 [perspective-origin:calc(50%+100px)_50%]',
        )}
      >
        <div
          className='relative h-full w-full'
          style={{ width: props.width + 'px' }}
        >
          {slideValues().map(({ style, value }, idx) => (
            <div
              className='absolute flex h-full w-full items-center justify-center text-xl font-normal backface-hidden'
              style={style}
              key={idx}
            >
              <span>{value}</span>
            </div>
          ))}
        </div>
        {props.label && (
          <div className='ml-2 text-lg leading-normal'>{props.label}</div>
        )}
      </div>
      <div
        className='relative left-0 z-10 mt-0.5 h-[calc(42%_+_2px)] w-full border-t-[0.5px] border-t-[#cccccc] bg-gradient-to-b from-white/50 to-white/90'
        style={{ transform: `translateZ(${radius}px)` }}
      />
    </div>
  )
}
