import { cn } from '@/utils'
import { ComponentPropsWithoutRef, ElementType } from 'react'
import Spinner from './spinner'

export type LoadingButtonProps<T extends ElementType> =
  ComponentPropsWithoutRef<T> & {
    loading?: boolean
    component?: T
  }

export default function LoadingButton<T extends ElementType = 'button'>({
  loading,
  component,
  className,
  disabled,
  children,
  ...props
}: LoadingButtonProps<T>) {
  const Component = component || 'button'

  return (
    <Component
      className={cn(loading && 'flex justify-center', className)}
      disabled={disabled || loading}
      {...props}
    >
      {loading ? <Spinner className='w-[1.3125rem]' /> : children}
    </Component>
  )
}
