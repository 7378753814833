import { captureException } from '@sentry/react'
import { ChatboxPosition } from 'crisp-sdk-web'
import { action, makeAutoObservable, runInAction } from 'mobx'
import { axiosClient } from './axios-client'
import { StoreModel } from './stores/models'

export class GeneralStore {
  isLoading = false
  showScheduledTab = false
  stores: StoreModel[] = []
  crispPosition: ChatboxPosition = ChatboxPosition.Right
  crispOpen = false

  heartbeatInterval: NodeJS.Timeout | null = null
  hasMerchantUpdate: number | false = false
  hasMerchantForceUpdate: number | false = false

  constructor() {
    makeAutoObservable(this)
  }

  setShowScheduledTab(showScheduledTab: boolean) {
    action(() => {
      this.showScheduledTab = showScheduledTab
    })()
  }

  setCrispOpen = (open: boolean) => {
    action(() => {
      this.crispOpen = open
    })()
  }

  setCrispPosition = (position: ChatboxPosition) => {
    action(() => {
      this.crispPosition = position
    })()
  }

  async sendHeartbeat() {
    try {
      const { data } = await axiosClient.get('/heartbeat', {
        params: {
          commitHash: import.meta.env.VITE_A2B_VERSION,
        },
      })

      runInAction(() => {
        this.hasMerchantUpdate = data.hasMerchantUpdate ? Date.now() : false
        this.hasMerchantForceUpdate = data.hasMerchantForceUpdate
          ? Date.now()
          : false
      })
    } catch (error) {
      captureException(error)
      console.error('Failed to send heartbeat', error)
    }
  }

  startHeartbeatInterval() {
    if (!this.heartbeatInterval) {
      this.heartbeatInterval = setInterval(() => this.sendHeartbeat(), 60_000)
      this.sendHeartbeat()
    }
  }
}
