import axios, { CreateAxiosDefaults } from 'axios'
import { config } from './config'
import { useState } from 'react'
import { rootStore, useStore } from './root-store'
import { auth } from './auth/firebase'

export const axiosClient = axios.create({
  timeout: 5000,
  baseURL: config.serviceUrl || '',
} as CreateAxiosDefaults)

axiosClient.interceptors.request.use(async (req) => {
  req.headers = req.headers || {}
  req.headers['Authorization'] = (await auth.currentUser?.getIdToken()) || ''

  if (rootStore.authStore.currentUserStore?.store_id) {
    req.headers['A2B-Store-Id'] = rootStore.authStore.currentUserStore.store_id
  }

  return req
})

export class CustomError extends Error {
  public errorCode?: string
  public data?: any
  public details?: string[]

  constructor({
    errorText,
    errorCode,
    details,
    data,
  }: {
    errorText: string
    errorCode?: string
    data?: any
    details?: string[]
  }) {
    super(errorText)
    this.data = data
    this.errorCode = errorCode
    this.details = details
  }
}

export const handleErrors = async ({
  e,
  defaultError,
  errorCodeHandler,
  customForAnyError,
}: {
  e: any
  defaultError?: string
  errorCodeHandler?: {
    [errorCode: string]: (data: any) => void | Promise<void>
  }
  customForAnyError?: boolean
}) => {
  const errorCode =
    e?.response?.status === 400 && (e.response?.data as any)?.error_code
  if (errorCode && errorCodeHandler?.[errorCode]) {
    await errorCodeHandler?.[errorCode]?.(e.response.data.data)
  }

  const customError =
    (e?.response?.status === 400 && (e.response.data as any)?.error_text) ||
    defaultError ||
    (customForAnyError ? 'An Error Occurred' : undefined)

  if (customError) {
    return new CustomError({
      errorText: customError,
      errorCode: e?.response?.data?.error_code,
      ...e?.response?.data,
    })
  }
  return e
}

export const useRunRequest = () => {
  const [loading, setLoading] = useState(false)
  const [loadingByKey, setLoadingByKey] = useState<{ [key: string]: boolean }>(
    {},
  )
  const [error, setError] = useState<Error | undefined>()
  const { snackbarStore } = useStore()

  return {
    loading,
    setLoading,
    loadingByKey,
    error,
    setError,
    action: async <T = any>({
      action,
      defaultError,
      key,
    }: {
      action: () => Promise<T> | T
      defaultError?: string
      key?: string
    }): Promise<T> => {
      setLoading(true)
      setLoadingByKey({ ...loadingByKey, [key || '']: true })
      try {
        return await action()
      } catch (e) {
        const error = await handleErrors({
          e,
          defaultError,
          customForAnyError: true,
        })

        snackbarStore.showCustomerError(error)
        setError(error)
        throw e
      } finally {
        setLoading(false)
        setLoadingByKey({ ...loadingByKey, [key || '']: false })
      }
    },
  }
}
