import { Backdrop, CircularProgress, styled } from '@mui/material'
import React, { CSSProperties } from 'react'

export const BackdropPresenter = ({
  open,
  style,
  size,
  hideLoading,
  absolute,
  children,
}: {
  open: boolean
  size?: number
  style?: CSSProperties
  hideLoading?: boolean
  absolute?: boolean
  children?: React.ReactNode
}) => {
  const Comp = absolute ? RelativeBackdropStyled : BackdropStyled
  return (
    <Comp open={open} style={style}>
      {!hideLoading && <CircularProgress color='secondary' size={size} />}
      {children}
    </Comp>
  )
}

export const BackdropStyled = styled(Backdrop)(({ theme }) => ({
  top: 0, // TODO: not sure why I need this, for some reason the fixed isn't topping properly
  zIndex: theme.zIndex.drawer + 1,
  color: '#fff',
  position: 'fixed',
  backgroundColor: 'rgba(255,255,255,0.5)',
}))

export const RelativeBackdropStyled = styled(Backdrop)(() => ({
  margin: 0,
  top: 0,
  bottom: 0,
  zIndex: 900,
  color: '#fff',
  position: 'absolute',
  backgroundColor: 'rgba(255,255,255,0.5)',
}))
