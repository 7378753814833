import { AuthState } from '@/auth/auth-store'
import { auth } from '@/auth/firebase'
import { useRunRequest } from '@/axios-client'
import { useStore } from '@/root-store'
import { updatePassword, validatePassword } from '@firebase/auth'
import { zodResolver } from '@hookform/resolvers/zod'
import { observer } from 'mobx-react'
import { useCallback, useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { z } from 'zod'
import LoadingButton from './loading-button'
import Logo from './logo'
import { Button } from './ui/button'
import FormInput from './ui/form-input'

const updatePasswordSchema = z.object({
  password: z.string(),
  repeatPassword: z.string(),
})

const OnboardingPassword = observer(() => {
  const navigate = useNavigate()
  const { authStore, snackbarStore } = useStore()
  const form = useForm<z.infer<typeof updatePasswordSchema>>({
    resolver: zodResolver(updatePasswordSchema),
  })
  const runRequest = useRunRequest()

  const handleSubmit: SubmitHandler<z.infer<typeof updatePasswordSchema>> =
    useCallback(
      async ({ password, repeatPassword }) => {
        const { currentUser } = auth

        if (!currentUser) {
          return snackbarStore.showSnackbar({
            title: 'Failed to set password',
            severity: 'error',
          })
        }

        if (password !== repeatPassword) {
          return snackbarStore.showSnackbar({
            title: "Passwords don't match",
            severity: 'error',
          })
        }

        const status = await validatePassword(auth, password)

        if (!status.isValid) {
          let message: string
          if (status.meetsMinPasswordLength !== true) {
            message = `Password must be at least ${status.passwordPolicy.customStrengthOptions.minPasswordLength} characters`
          } else if (status.meetsMaxPasswordLength !== true) {
            message = `Password cannot be longer than ${status.passwordPolicy.customStrengthOptions.maxPasswordLength} characters`
          } else if (status.containsUppercaseLetter !== true) {
            message = 'Password must contain an uppercase letter'
          } else if (status.containsLowercaseLetter !== true) {
            message = 'Password must contain a lowercase letter'
          } else if (status.containsNumericCharacter !== true) {
            message = 'Password must contain a number'
          } else if (status.containsNonAlphanumericCharacter !== true) {
            message = 'Password must contain a symbol'
          } else {
            message = 'Invalid password'
          }

          return form.setError('password', { message })
        }

        await runRequest.action({
          action: async () => {
            await updatePassword(currentUser, password)
            navigate('/')
          },
          defaultError: 'Failed to set password',
        })
      },
      [form, navigate, runRequest, snackbarStore],
    )

  useEffect(() => {
    if (
      authStore.authState !== null &&
      authStore.authState !== AuthState.AUTHENTICATING &&
      authStore.authState !== AuthState.SIGNED_IN
    ) {
      navigate('/')
    }
  }, [authStore.authState, navigate])

  return (
    <div className='flex min-h-screen items-center justify-center'>
      <form
        onSubmit={form.handleSubmit(handleSubmit)}
        className='max-w-md rounded-lg px-4 md:p-10 md:shadow-[0_0_14px_0_rgba(0,0,0,.1)]'
      >
        <Logo className='mx-auto mb-4 w-16' />
        <p className='text-center text-2xl/normal font-bold'>Welcome to a2b</p>
        <p className='mb-9 text-center text-2xl/normal md:font-medium'>
          Please create a password
        </p>

        <p className='mb-6 text-center text-sm/normal text-[#9D9D9D]'>
          Create a password that’s at least 8 characters long and includes one
          uppercase letter (A-Z), one lowercase letter (a-z), and one number
          (0-9).
        </p>

        <div className='mb-4'>
          <FormInput
            control={form.control}
            type='password'
            name='password'
            placeholder='Your password'
          />
        </div>

        <div className='mb-4'>
          <FormInput
            control={form.control}
            type='password'
            name='repeatPassword'
            placeholder='Repeat your password'
          />
        </div>

        <LoadingButton
          component={Button}
          loading={runRequest.loading}
          className='w-full bg-[#00C2E8]/60 text-black hover:bg-[#00C2E8]'
        >
          Get Started
        </LoadingButton>
      </form>
    </div>
  )
})

export default OnboardingPassword
