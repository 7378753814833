import { useStore } from '@/root-store'
import { cn } from '@/utils'
import { Schedule } from '@mui/icons-material'
import { useEffect, useMemo, useRef } from 'react'
import { createPortal } from 'react-dom'
import newOrderSound from '../assets/new-order-sound.mp3'
import { NewOrderAlertVolume } from '../orders/models'

export const NewOrderModal = ({
  newOrderCount,
  newScheduledOrderCount,
  newOrderAlertVolume,
  clearNewOrders,
}: {
  newOrderCount: number
  newScheduledOrderCount: number
  newOrderAlertVolume?: NewOrderAlertVolume
  clearNewOrders: () => void
}) => {
  const { generalStore } = useStore()
  const isScheduled = useMemo(
    () => newOrderCount === 0 && newScheduledOrderCount > 0,
    [newOrderCount, newScheduledOrderCount],
  )
  const count = useMemo(
    () => (isScheduled ? newScheduledOrderCount : newOrderCount),
    [isScheduled, newOrderCount, newScheduledOrderCount],
  )
  const audioRef = useRef<HTMLAudioElement>(null)

  const closeModal = () => {
    if (audioRef.current) {
      audioRef.current.muted = true
    }

    generalStore.setShowScheduledTab(isScheduled)
    clearNewOrders()
  }

  useEffect(() => {
    document.addEventListener('click', () => {
      if (audioRef.current?.paused) {
        audioRef.current.play()
      }
    })
  }, [])

  useEffect(() => {
    if (count > 0) {
      if (audioRef.current) {
        if (audioRef.current.paused) {
          audioRef.current.play()
        } else {
          audioRef.current.currentTime = 0
        }

        audioRef.current.muted = false
        audioRef.current.volume = newOrderAlertVolume === 'QUIET' ? 0.5 : 1
      }

      const timeout = setTimeout(() => {
        if (audioRef.current) {
          const handler = () => {
            if (
              audioRef.current &&
              audioRef.current.currentTime >= audioRef.current.duration - 0.1
            ) {
              audioRef.current.muted = true
              audioRef.current.removeEventListener('timeupdate', handler)
            }
          }

          audioRef.current.addEventListener('timeupdate', handler)
        }
      }, 30_000)

      return () => clearTimeout(timeout)
    }
  }, [count, newOrderAlertVolume])

  return createPortal(
    <>
      <audio ref={audioRef} src={newOrderSound} loop muted></audio>
      {count > 0 && (
        <div className='fixed inset-0 bg-black/80 p-12 font-[Poppins,sans-serif]'>
          <div
            className={cn(
              'flex h-full w-full flex-col items-center justify-between gap-10 overflow-hidden rounded-lg p-10',
              isScheduled ? 'bg-scheduled' : 'bg-a2b-blue',
            )}
          >
            <div className='z-0 flex flex-1 flex-col items-center justify-center text-center'>
              <div className='relative mb-8 flex h-64 w-64 flex-col items-center justify-center rounded-full bg-[#F2F2F2] before:absolute before:inset-0 before:-z-10 before:animate-ping before:rounded-[inherit] before:bg-inherit'>
                <p className='text-9xl'>{count}</p>
                <p className='flex items-center gap-1.5'>
                  {isScheduled && (
                    <>
                      <Schedule className='h-5 w-5 !text-base' />
                      Scheduled{' '}
                    </>
                  )}
                  Order
                  {count === 1 ? '' : 's'}
                </p>
              </div>
              <p
                className={cn(
                  'text-xl',
                  isScheduled ? 'text-black' : 'text-white',
                )}
              >
                Need{count === 1 ? 's' : ''} to be confirmed
              </p>
            </div>
            <button
              className='z-10 rounded-lg bg-white px-40 py-6 text-sm font-semibold text-black'
              onClick={closeModal}
            >
              View Order
              {count === 1 ? '' : 's'}
            </button>
          </div>
        </div>
      )}
    </>,
    document.body,
  )
}
