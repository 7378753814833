import 'firebase/messaging'
import 'firebase/auth'
import { action, makeAutoObservable } from 'mobx'
import { auth, provider } from './firebase'
import { axiosClient, handleErrors } from '../axios-client'
import { config } from '../config'
import { StoreModel } from '../stores/models'
import { Crisp } from 'crisp-sdk-web'
import { EditStoreFields, storesService } from '../stores/store-service'
import * as Sentry from '@sentry/react'

const SESSION_KEY_AUTH_USER = 'a2b.auth.user'
export const USER_NOT_FOUND = 'User not found'
const LOCAL_STORAGE_CURRENT_STORE_ID = 'current_store_id'

const GenericError = 'Oops, Something went wrong. Please try again later.'
const PasswordNotMatch = "Password don't match"

enum AuthState {
  AUTHENTICATING,
  SIGNED_OUT,
  SIGNED_IN,
  ERROR_SIGNING_IN = 4,
}

export enum StoreUserRoles {
  StoreAdmin = 'store_admin',
}

interface BasicUserStore {
  store_id: string
  store_name: string
  roles: StoreUserRoles[]
}

export interface UserStore {
  store_id: string
  store: StoreModel
  roles: StoreUserRoles[]
}

export interface User {
  id: string
  name: string
  email: string
  is_admin: boolean
}

export interface ExternalCustomer {
  name: string
  logo_url: string
}

export interface UserClaims {
  token_id: string
  user: User
  user_stores: {
    adminRoleStores: string[]
    stores: string[]
  }
}

class AuthStore {
  authState: AuthState | null = null
  firebaseUser?: any // TODO
  error: string | null
  claims: UserClaims | null = null
  userStores: BasicUserStore[] = []
  externalCustomer?: ExternalCustomer
  isFirstLogin = false
  currentStoreId: string | null = null
  currentStore: StoreModel | null = null

  navigateToLoginAndKeepOldLink = false

  constructor() {
    this.error = ''
    makeAutoObservable(this)

    action(() => {
      this.currentStoreId =
        localStorage.getItem(LOCAL_STORAGE_CURRENT_STORE_ID) || null
    })()
  }

  get currentUserStore() {
    if (!this.currentStoreId) {
      return null
    }
    return this.userStores?.find((s) => s.store_id === this.currentStoreId)
  }

  getCurrentStore = async (forceRefresh?: boolean) => {
    if (!this.currentStoreId) {
      return null
    } else if (
      !forceRefresh &&
      this.currentStore &&
      this.currentStore.id === this.currentStoreId
    ) {
      return this.currentStore
    }

    const store = await storesService.getStore(this.currentStoreId)

    action(() => {
      this.currentStore = store.data.store
    })()

    return store.data.store
  }

  redirectToLoginAndKeepUrl = () => {
    action(() => {
      this.navigateToLoginAndKeepOldLink = true
    })()
  }

  clearNavigateToLogin = (redirectUrl: string) => {
    localStorage.setItem('loginRedirectUrl', redirectUrl)
    action(() => {
      this.navigateToLoginAndKeepOldLink = false
    })()
  }

  getAndClearRedirectUrl = () => {
    const redirect = localStorage.getItem('loginRedirectUrl')
    localStorage.removeItem('loginRedirectUrl')
    return redirect
  }

  subscribe = () => {
    action(() => {
      this.authState = AuthState.AUTHENTICATING
    })()
    return auth.onAuthStateChanged(async (user) => {
      if (user?.isAnonymous) {
        return
      }

      this.firebaseUser = user

      // TODO: call login with us?
      if (!user) {
        if (
          Crisp.isCrispInjected() &&
          window.$crisp?.is &&
          Crisp.session.getIdentifier()
        ) {
          Crisp.session.reset()
        }
        this.signOut()
        return
      }

      await this.login()
    })
  }

  login = async () => {
    if (!auth.currentUser!.emailVerified) {
      return action(() => {
        this.authState = AuthState.SIGNED_OUT
      })()
    }

    try {
      const res = await axiosClient.post<{
        claims: UserClaims
        userStore?: UserStore
        user_stores: BasicUserStore[]
        is_first_login: boolean
        external_customer?: ExternalCustomer
      }>(`${config.loginUrl}/login`, {
        token: await auth.currentUser!.getIdToken(),
      })

      // we refresh token after login so custom claims will exist on token
      await auth.currentUser!.getIdToken(true)

      Crisp.user.setEmail(res.data.claims.user.email)
      Crisp.user.setNickname(res.data.claims.user.name)

      Crisp.session.setData({
        user_id: res.data.claims.user.id,
      })

      Crisp.session.setSegments(['merchant'], false)

      action(() => {
        this.updateUserData(res.data)
        this.authState = AuthState.SIGNED_IN
      })()

      if (this.currentUserStore) {
        Crisp.user.setCompany(this.currentUserStore.store_name, {})
      }
    } catch (e: any) {
      Sentry.captureException(e)

      const wrappedError = await handleErrors({ e, customForAnyError: true })

      if (wrappedError?.errorCode === 'user_not_found') {
        this.signOut()
        this.setError(USER_NOT_FOUND)
      } else if (e.response?.status === 403) {
        this.signOut()
        this.setError(e.message)
      } else {
        action(() => {
          this.authState = AuthState.ERROR_SIGNING_IN
        })()
        this.setError(e.message)
      }
    }
  }

  updateUserData = (data: {
    claims: UserClaims
    user_stores: BasicUserStore[]
    external_customer?: ExternalCustomer
    is_first_login: boolean
  }) => {
    action(() => {
      this.claims = data.claims
      this.userStores = data.user_stores
      this.isFirstLogin = data.is_first_login
      this.externalCustomer = data.external_customer
      if (!this.currentStoreId) {
        this.switchStore(data.user_stores?.[0]?.store_id)
      }
    })()
  }

  switchStore = (newStoreId: string | null) => {
    if (!newStoreId) {
      localStorage.removeItem(LOCAL_STORAGE_CURRENT_STORE_ID)
    } else {
      localStorage.setItem(LOCAL_STORAGE_CURRENT_STORE_ID, newStoreId)
    }
    action(() => {
      this.currentStoreId = newStoreId
    })()
  }

  signOut = async () => {
    this.authState = AuthState.SIGNED_OUT
    this.firebaseUser = undefined
    localStorage.removeItem(SESSION_KEY_AUTH_USER)
    this.switchStore(null)
    await auth.signOut()
  }

  get isAuthenticated(): boolean {
    return this.authState === AuthState.SIGNED_IN
  }

  setError = (e: string | null) => (this.error = e)

  signInWithGoogle = async () => {
    this.setError(null)
    try {
      await auth.signInWithPopup(provider)
    } catch (e: any) {
      Sentry.captureException(e)

      if (e.code === 'auth/cancelled-popup-request') {
        return
      }
      this.setError(e.message || GenericError)
    }
  }

  forgotPassword = async (email: string): Promise<boolean> => {
    try {
      await auth.sendPasswordResetEmail(email, {
        url:
          config.env === 'development'
            ? `http://localhost:3011/login?email=${encodeURIComponent(email)}`
            : `https://${window.location.host}/login?email=${encodeURIComponent(email)}`,
      })
      return true
    } catch (e: any) {
      this.setError(e.message || GenericError)
      return false
    }
  }

  signInWithMagicLink = async (email: string, link: string) => {
    try {
      if (auth.isSignInWithEmailLink(link)) {
        await auth.signInWithEmailLink(email, link)
        return true
      }
      return false
    } catch (e: any) {
      Sentry.captureException(e)

      this.setError(
        e.code === 'auth/invalid-action-code'
          ? GenericError
          : e.message || GenericError,
      )
      return false
    }
  }
  sendMagicLink = async (email: string) => {
    try {
      await auth.sendSignInLinkToEmail(email, {
        // URL you want to redirect back to. The domain (www.example.com) for this
        // URL must be in the authorized domains list in the Firebase Console.
        url:
          config.env === 'development'
            ? 'http://localhost:3011/login'
            : `https://${window.location.host}/login`,
        // This must be true.
        handleCodeInApp: true,
      })
      window.localStorage.setItem('emailForSignIn', email)
      return true
    } catch (e: any) {
      Sentry.captureException(e)

      window.localStorage.removeItem('emailForSignIn')
      this.setError(e.message || GenericError)
      return false
    }
  }

  verifyPasswordResetCode = async (code: string): Promise<string> => {
    try {
      return await auth.verifyPasswordResetCode(code)
    } catch (e: any) {
      Sentry.captureException(e)

      this.setError(e.message || GenericError)
      return ''
    }
  }

  confirmPasswordReset = async (
    email: string,
    code: string,
    password: string,
    verifyPassword: string,
  ) => {
    if (password !== verifyPassword) {
      this.error = PasswordNotMatch
    } else {
      try {
        await auth.confirmPasswordReset(code, password)
      } catch (e: any) {
        this.setError(e.message || GenericError)
        return
      }
      await auth.signInWithEmailAndPassword(email, password)
    }
  }

  updateStore = async (storeId: string, fields: EditStoreFields) => {
    const res = await storesService.updateStore(storeId, fields)
    action(() => {
      this.claims = res.data.updatedClaimsAndToken.claims
    })()
  }
}

export { AuthStore, AuthState }
