import { OrderDetails } from '../orders/models'
import { Dialog, Drawer, styled } from '@mui/material'
import { OrderComponent } from './order-component'
import React, { useCallback, useState } from 'react'
import { AsyncFetchWrapper } from '../components/async-fetch-wrapper'
import { ordersService } from '../orders/order-service'
import { useOrderPopup } from './hooks'
import { BreakpointsSelector } from '../utils/mobile'
import { ChatboxPosition } from 'crisp-sdk-web'
import { useStore } from '../root-store'
import { v4 as uuid } from 'uuid'

// export const ORDER_DRAWER_WIDTH = '50vh'

export const OrderPopup = () => {
  const [order, setOrder] = useState<OrderDetails>()
  const { selectedOrderId, toggleOpenOrder } = useOrderPopup()
  const { generalStore } = useStore()
  const [reloadOrder, setReloadOrder] = useState(selectedOrderId)

  const loadOrder = useCallback(async () => {
    if (!selectedOrderId) {
      setOrder(undefined)
      generalStore.setCrispPosition(ChatboxPosition.Right)
      return
    }
    const res = await ordersService.getOrder(selectedOrderId)
    setOrder(res.data)
    generalStore.setCrispPosition(ChatboxPosition.Left)
  }, [selectedOrderId])

  return (
    <RootComponent open={!!selectedOrderId} onClose={() => toggleOpenOrder()}>
      <AsyncFetchWrapper
        loadAction={loadOrder}
        triggerProp={[selectedOrderId, reloadOrder]}
      >
        {!!order && (
          <OrderComponent
            order={order}
            reloadOrder={() => setReloadOrder(uuid())}
            onClose={() => toggleOpenOrder()}
          />
        )}
      </AsyncFetchWrapper>
    </RootComponent>
  )
}

const RootComponent = ({
  children,
  open,
  onClose,
}: {
  open: boolean
  onClose: () => void
  children: React.ReactElement
}) => {
  return (
    <BreakpointsSelector
      xs={
        <DialogStyled
          open={open}
          onClose={onClose}
          fullWidth={true}
          maxWidth='md'
          PaperProps={{ sx: { backgroundColor: '#fafcfc', display: 'flex' } }}
        >
          {children}
        </DialogStyled>
      }
      md={
        <DrawerStyled
          disableEnforceFocus
          open={open}
          onClose={onClose}
          anchor='right'
        >
          {children}
        </DrawerStyled>
      }
    />
  )
}

const DrawerStyled = styled(Drawer)(({ theme }) => ({
  display: 'flex',
  width: '30%',
  zIndex: 50,

  [theme.breakpoints.between('md', 'xl')]: {
    width: '50%',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },

  //flexShrink: 0,
  ['& .MuiDrawer-paper']: {
    width: '30%',
    [theme.breakpoints.between('md', 'xl')]: {
      width: '50%',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}))

const DialogStyled = styled(Dialog)(() => ({
  // '& .MuiPaper-root': { minWidth: '80%' },
}))
