import { StoreModel } from '../stores/models'
import { CreateDelivery } from './schema'
import { useMemo } from 'react'
import { DateTime } from 'luxon'
import Decimal from 'decimal.js'
import {
  DeliveryStatus,
  DeliveryStatuses,
  Order,
  OrderDelivery,
  OrderStatuses,
  VehicleType,
} from './models'
import { DialogStore } from '../dialogs/dialog-store'
import { Box, styled } from '@mui/material'
import { UpdatePrepTimeDialog } from './update-prep-time-dialog'

export const useDefaultDeliveryValues = ({
  store,
}: {
  store: StoreModel
}): CreateDelivery => {
  const defaultPickupTime = useMemo(() => {
    const date = DateTime.now().plus({ hour: 1 })

    return date.set({
      minute: new Decimal(date.minute).div(5).ceil().mul(5).toNumber(),
    })
  }, [])

  return {
    delivery: {
      self_delivery:
        store.is_self_delivery_allowed && !store.is_a2b_delivery_active,
      pickup_delay_minutes: store.default_prep_time,
      schedule: false,
      pickup_time: defaultPickupTime.toISO(),
      vehicle_type: VehicleType.Car,
    },
  }
}

export const getOrderStatusData = (order: Order, delivery?: OrderDelivery) => {
  return delivery && OrderStatuses[order.status].preferDeliveryStatus
    ? DeliveryStatuses[delivery.delivery_status]
    : OrderStatuses[order.status]
}

export const updatePrepTime = ({
  dialogStore,
  postUpdate,
}: {
  dialogStore: DialogStore
  postUpdate: (minutes: number) => Promise<void>
}) => {
  return dialogStore.showDialogAsync(UpdatePrepTimeDialog, {
    submit: (minutes: number) => postUpdate(minutes),
  })
}

export const Section = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: theme.spacing(3),
  margin: theme.spacing(3),
}))

export const getEstimatedPickupTime = (delivery: OrderDelivery) => {
  let pickupTime =
    !!delivery.pickup_time_estimated &&
    DateTime.fromISO(delivery.pickup_time_estimated)

  if (pickupTime && delivery.delivery_status === DeliveryStatus.Pending) {
    pickupTime = DateTime.now().plus(
      pickupTime.diff(DateTime.fromISO(delivery.created_at)),
    )
  }

  return pickupTime
}
