import { Autocomplete, Box, debounce, InputAdornment } from '@mui/material'
import { Search } from '@mui/icons-material'
import { Order } from './models'
import { AutocompleteItem, CustomTextInput } from '../components/inputs'
import { useCallback, useEffect, useState } from 'react'
import { useEffectNotFirstTime } from '../utils/hooks'
import { ordersService } from './order-service'
import { BasicOrderInfo } from '../order-details/basic-order-info'

const useDebouncedFilter = (debounceValue: number = 500) => {
  const [debouncedText, setDebouncedTextInternal] = useState('')
  const [text, setText] = useState('')

  const setTextDebounced = useCallback(
    debounce(
      (text: string) => setDebouncedTextInternal(text),
      debounceValue || 500,
    ),
    [setDebouncedTextInternal],
  )
  useEffect(() => {
    setTextDebounced(text)
  }, [text])

  return { text, setText, debouncedText }
}

export const AutocompleteOrders = ({
  value,
  onChange,
  label,
}: {
  value?: Order | null
  onChange: (order?: Order | null) => void
  label: string
}) => {
  const [loading, setLoading] = useState(false)
  const { text, setText, debouncedText } = useDebouncedFilter()
  const [orders, setOrders] = useState<Order[]>([])

  useEffectNotFirstTime(() => {
    if (!text) {
      setLoading(false)
      setOrders([])
      return
    }

    setLoading(true)
  }, [text])

  useEffect(() => {
    if (!debouncedText) {
      return
    }

    fetchOrders(debouncedText)
  }, [debouncedText])

  const fetchOrders = useCallback(async (filter: string) => {
    try {
      const data = await ordersService.filterOrders(filter)
      setOrders(data.data)
    } finally {
      setLoading(false)
    }
  }, [])

  return (
    <Autocomplete
      loading={loading}
      options={orders}
      fullWidth={true}
      getOptionLabel={(option: Order) => option.id}
      isOptionEqualToValue={(a: Order, b: Order) => a.id === b.id}
      filterSelectedOptions={false}
      filterOptions={(options) => options}
      value={value || null}
      size='small'
      onChange={(_, value) => {
        return onChange(value)
      }}
      sx={{
        borderRadius: '8px !important',
      }}
      componentsProps={{
        paper: {
          sx: {
            borderRadius: 2,
            // backgroundColor: '#cccccc',
          },
        },
      }}
      inputValue={text}
      onInputChange={(e, value) => setText(value)}
      renderInput={(params) => {
        return (
          <CustomTextInput
            {...params}
            placeholder={label}
            InputProps={{
              ...params.InputProps,
              sx: {
                borderRadius: '8px !important',
                backgroundColor: 'rgba(255,255,255,0.8) !important',
                paddingRight: '8px !important',
                alignSelf: 'end',
                minWidth: '200px',
              },
              endAdornment: (
                <InputAdornment position='end'>
                  <Search color='primary' />
                </InputAdornment>
              ),
            }}
            // rules={props.rules}
            // error={props.error}
          />
        )
      }}
      renderOption={(p, option) => {
        return (
          <AutocompleteItem {...p} key={option.id} value={option.id}>
            <Box>
              <BasicOrderInfo order={option} />
            </Box>
          </AutocompleteItem>
        )
      }}
    />
  )
}
