import Spinner from '@/components/spinner'
import { storesService } from '@/stores/store-service'
import {
  AttachMoney,
  Bolt,
  CardGiftcard,
  History,
  Logout,
  Person,
  Print,
} from '@mui/icons-material'
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  Typography,
} from '@mui/material'
import { observer } from 'mobx-react'
import { useCallback, useMemo, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useStore } from '../root-store'
import { BreakpointsSelector } from '../utils/mobile'
import { CreateReferral } from '@/components/create-referral'
import { colors } from '@/theme'

export const SIDEBAR_WIDTH = 140

const MenuItem = ({
  title,
  icon: Icon_,
  route,
  onClick,
  closeMenu,
  textColor,
}: {
  title: string
  icon: any
  route?: string
  onClick?: (props: { storeId: string | null }) => void | Promise<void>
  closeMenu?: () => void
  textColor?: string
}) => {
  const { pathname } = useLocation()
  const { authStore } = useStore()
  const [loading, setLoading] = useState(false)
  const Icon = useMemo(
    () =>
      loading ? <Spinner className='w-6 p-0.5' /> : <Icon_ fill='white' />,
    [Icon_, loading],
  )

  const _onClick = useCallback(() => {
    setLoading(true)
    Promise.resolve(onClick?.({ storeId: authStore.currentStoreId }))
      // eslint-disable-next-line no-console
      .catch(console.error)
      .then(() => {
        closeMenu?.()
        setLoading(false)
      })
  }, [authStore.currentStoreId, closeMenu, onClick])

  return (
    <ListItem disablePadding={true}>
      <MenuItemButton
        onClick={_onClick}
        component={Link}
        to={route}
        divider={true}
        selected={pathname === route}
        sx={{ color: textColor || colors.dark.D70 }}
      >
        <BreakpointsSelector
          xs={
            <ListItemIcon sx={{ color: textColor || 'white' }}>
              {Icon}
            </ListItemIcon>
          }
          md={Icon}
        />
        <ListItemText
          primaryTypographyProps={{ variant: 'subtitle1' }}
          sx={{ color: textColor || 'white' }}
        >
          {title}
        </ListItemText>
      </MenuItemButton>
    </ListItem>
  )
}

export const NavigationMenu = observer(
  ({ onClick }: { onClick?: () => void }) => {
    const { authStore, dialogStore } = useStore()
    const navigate = useNavigate()
    return (
      <SidebarStyled>
        <List sx={{ flex: 1, gap: 0, pt: 0, color: 'white' }}>
          <MenuItem
            title='Live Orders'
            icon={Bolt}
            route='/'
            closeMenu={onClick}
          />
          <MenuItem
            title='Order History'
            icon={History}
            route='/orders'
            closeMenu={onClick}
          />
          <MenuItem
            title='Billing Portal'
            icon={AttachMoney}
            closeMenu={onClick}
            onClick={async ({ storeId }) => {
              if (storeId) {
                const response =
                  await storesService.getStoreBillingPortalUrl(storeId)
                window.open(
                  response.data.billingPortalUrl,
                  '_blank',
                  'noopener noreferrer',
                )
              }
            }}
          />
          {window.AndroidInterface && (
            <MenuItem
              title='Printer Settings'
              icon={Print}
              onClick={() =>
                window.AndroidInterface?.postMessage(
                  JSON.stringify({
                    action: 'navigate-to-printer-settings',
                    payload: '',
                  }),
                )
              }
            />
          )}
        </List>
        <MenuItem
          onClick={() => {
            dialogStore.showDialogAsync(CreateReferral).catch(() => {})
          }}
          icon={CardGiftcard}
          title='Refer a2b and get $250'
          textColor={colors.blue.B60}
        />
        <Typography
          variant='subtitle1'
          sx={{
            padding: 2,
            textAlign: 'center',
            color: 'white',
          }}
        >
          <Person fontSize='large' />
          <br /> {authStore.claims?.user.name}
        </Typography>
        <SignOutBox>
          <Button
            onClick={() => {
              return dialogStore.showPromptAsync({
                title: 'Logout',
                promptMessage: 'Are you sure you want to logout?',
                onBeforeResolve: async () => {
                  await authStore.signOut()
                  return navigate('/login')
                },
              })
            }}
            endIcon={<Logout />}
            variant='text'
            // sx={{ color: 'white' }}
            color='info'
          >
            Sign Out
          </Button>
        </SignOutBox>
      </SidebarStyled>
    )
  },
)

const SignOutBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  padding: theme.spacing(4),
  paddingTop: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  gap: theme.spacing(4),
}))

const MenuItemButton = styled(ListItemButton)<{ component?: any; to?: string }>(
  ({ theme }) => ({
    [theme.breakpoints.up('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      padding: 0,
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.colors.dark.D70,
      '& *': {
        color: 'white',
        fontWeight: 600,
      },
    },
    '&.Mui-selected:hover, &:hover': {
      backgroundColor: 'rgb(86, 86, 87)',
    },
  }),
)

const SidebarStyled = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  [theme.breakpoints.up('xl')]: {
    width: SIDEBAR_WIDTH,
    gap: theme.spacing(4),
    // backgroundColor: theme.palette.primary.main,
  },
  [theme.breakpoints.down('xl')]: {},
  display: 'flex',
  flexDirection: 'column',
}))
