import { useRunRequest } from '@/axios-client'
import LoadingButton from '@/components/loading-button'
import { AutocompleteAddressWrapper } from '@/components/new-autocomplete-address'
import Spinner from '@/components/spinner'
import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import FormInput from '@/components/ui/form-input'
import FormSelect from '@/components/ui/form-select'
import { SelectItem } from '@/components/ui/select'
import { NewOrderAlertVolume } from '@/orders/models'
import { zodResolver } from '@hookform/resolvers/zod'
import { startCase } from 'lodash'
import { observer } from 'mobx-react'
import { useCallback, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { z } from 'zod'
import { AsyncDialogBaseProps } from '../dialogs/models'
import { useStore } from '../root-store'
import { StoreModel } from './models'
import { EditStoreSchema, storesService } from './store-service'

function EditStoreDialogContent({
  store,
  resolve,
}: {
  store: StoreModel
  resolve: () => void
}) {
  const { authStore } = useStore()
  const runRequest = useRunRequest()
  const form = useForm<z.infer<typeof EditStoreSchema>>({
    resolver: zodResolver(EditStoreSchema),
    defaultValues: {
      ...store,
    },
  })

  const handleSubmit: SubmitHandler<z.infer<typeof EditStoreSchema>> =
    useCallback(
      (params) => {
        return runRequest.action({
          defaultError: 'Error updating store',
          action: async () => {
            await authStore.updateStore(store.id, params)
            resolve()
          },
        })
      },
      [authStore, resolve, runRequest, store.id],
    )

  return (
    <>
      <DialogHeader>
        <DialogTitle>Update {store.name}</DialogTitle>
      </DialogHeader>

      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <div className='flex flex-col gap-4'>
          <div>
            <FormInput
              control={form.control}
              name='name'
              placeholder='Name *'
              required
            />
          </div>

          <div>
            <AutocompleteAddressWrapper
              label='Address *'
              control={form.control}
              name='location'
              required
            />
          </div>

          <div>
            <FormInput
              control={form.control}
              name='default_courier_pickup_instructions'
              placeholder='Pickup Instructions'
            />
          </div>

          <div>
            <FormInput
              type='number'
              control={form.control}
              name='default_prep_time'
              placeholder='Default Prep Time (minutes)'
              required
            />
          </div>

          <div>
            <FormSelect
              control={form.control}
              name='new_order_alert_volume'
              placeholder='What type of business do you operate? *'
              triggerClassName='focus:ring-transparent focus:ring-0'
            >
              {Object.values(NewOrderAlertVolume).map((value) => (
                <SelectItem key={value} value={value}>
                  {startCase(value.toLowerCase().replace('_', ' '))}
                </SelectItem>
              ))}
            </FormSelect>
          </div>
        </div>

        <div className='mt-4'>
          <LoadingButton
            loading={runRequest.loading}
            component={Button}
            className='w-full bg-[#66DAF1] text-black hover:bg-[#00C2E8]'
          >
            Update Store
          </LoadingButton>
        </div>
      </form>
    </>
  )
}

const EditStoreDialog = observer(
  ({ open, resolve, storeId }: AsyncDialogBaseProps & { storeId?: string }) => {
    const { authStore } = useStore()
    const [store, setStore] = useState<StoreModel>()

    useEffect(() => {
      if (storeId) {
        storesService.getStore(storeId).then((res) => setStore(res.data.store))
      } else {
        authStore
          .getCurrentStore(true)
          .then((store) => store && setStore(store))
      }
    }, [authStore, storeId])

    return (
      <Dialog open={open} onOpenChange={resolve}>
        <DialogContent>
          {store ? (
            <EditStoreDialogContent store={store} resolve={resolve} />
          ) : (
            <div className='flex flex-1 items-center justify-center'>
              <Spinner className='w-8' />
            </div>
          )}
        </DialogContent>
      </Dialog>
    )
  },
)

export default EditStoreDialog
