import { Schedule, StoreRounded } from '@mui/icons-material'
import { DateTime } from 'luxon'
import { useMemo } from 'react'
import PickUpIcon from '../components/icons/pickup-icon'
import { OrderingPlatformAvatar } from '../orders/components'
import { Order, OrderDelivery } from '../orders/models'
import { generateShortName } from '../utils/general'
import { DeliveryETA } from './delivery-eta'

export const BasicOrderInfo = ({
  order,
  delivery,
  shortName,
  hideLogo,
}: {
  order: Order
  delivery?: OrderDelivery
  shortName?: boolean
  hideLogo?: boolean
}) => {
  const scheduledTime = useMemo(() => {
    if (!order.is_scheduled) {
      return null
    }

    if (order.customer_requested_pickup_time) {
      return DateTime.fromISO(order.customer_requested_pickup_time)
    } else if (order.customer_requested_dropoff_time) {
      return DateTime.fromISO(order.customer_requested_dropoff_time)
    }

    return null
  }, [
    order.customer_requested_dropoff_time,
    order.customer_requested_pickup_time,
    order.is_scheduled,
  ])

  return (
    <div className='flex flex-col items-start pb-5'>
      <div className='mb-5 flex flex-wrap gap-2 empty:mb-0'>
        {order.is_scheduled && (
          <div className='flex items-center gap-2 rounded-full bg-scheduled px-2 py-1 text-dark-bg'>
            <Schedule className='h-4 w-4 !text-base' />
            <p className='text-xs font-medium leading-normal'>
              Scheduled
              {scheduledTime !== null &&
                ` for ${scheduledTime.toFormat(scheduledTime.hasSame(DateTime.now(), 'day') ? 'h:mm a' : 'MMM d, h:mm a')}`}
            </p>
          </div>
        )}
        {order.is_pickup && (
          <div className='flex items-center gap-2 rounded-full bg-[#F2F2F2] px-2 py-1 text-dark-bg'>
            <PickUpIcon className='h-4 w-4' />
            <p className='text-xs font-medium leading-normal'>Pickup</p>
          </div>
        )}
        {order.is_self_delivery && (
          <div className='flex items-center gap-2 rounded-full bg-[#E0CEF7] px-2 py-1 text-dark-bg'>
            <StoreRounded className='!h-4 !w-4' />
            <p className='text-xs font-medium leading-normal'>Self Delivery</p>
          </div>
        )}
      </div>
      <div className='flex w-full items-center justify-between gap-4'>
        <div className='flex items-center gap-4'>
          {!hideLogo && (
            <OrderingPlatformAvatar order={order} width={60} height={60} />
          )}
          <div className='flex flex-col justify-center gap-0.5'>
            <p className='text-sm font-semibold leading-normal'>
              #{order.display_id ?? order.external_id}
            </p>
            <p className='text-xs leading-normal'>
              {shortName
                ? generateShortName(order.customer_name)
                : order.customer_name}
            </p>
            <p className='text-xs leading-normal'>{order.customer_phone}</p>
          </div>
        </div>
        {delivery && <DeliveryETA delivery={delivery} />}
      </div>
    </div>
  )
}
