import DeleteIcon from '@/components/icons/delete-icon'
import { DialogButton, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { CurrencyExchange, SupportAgent } from '@mui/icons-material'
import { Crisp } from 'crisp-sdk-web'
import { useCallback } from 'react'
import { StepProps } from '../steps'
import { useStore } from '@/root-store'
import { OrderStatus, RefundStatus } from '@/orders/models'
import { RefundDialog } from '@/orders/refunds'

export default function IssueWithOrderStep({
  setOpen,
  setCurrentStep,
  order,
  reloadOrder,
}: StepProps) {
  const { dialogStore, snackbarStore } = useStore()

  const openChat = useCallback(() => {
    setOpen(false)
    Crisp.chat.open()
  }, [setOpen])

  const onRefundSelection = async () => {
    if (!order.total_merchant_charge) {
      snackbarStore.showSnackbar({
        title:
          'Order does not have any a2b fees, maybe it is not Delivered / Canceled yet',
        severity: 'error',
      })
      return
    }

    let refundStatus: RefundStatus | undefined
    if (
      order.refunds?.some((refund) => {
        const statusFound = [
          RefundStatus.Rejected,
          RefundStatus.Approved,
        ].includes(refund.refund_status)
        if (statusFound) {
          refundStatus = refund.refund_status
        }
        return statusFound
      })
    ) {
      snackbarStore.showSnackbar({
        title: `Order already has a refund request in status ${refundStatus?.toLowerCase()}`,
        severity: 'error',
      })
      return
    }

    setOpen(false)

    await dialogStore.showDialogAsync(RefundDialog, {
      order,
      reloadOrder,
    })
  }

  const disableRefundRequestButton = ![
    OrderStatus.Delivered,
    OrderStatus.Canceled,
  ].includes(order.status)

  return (
    <>
      <DialogHeader>
        <DialogTitle>Issue With Order</DialogTitle>
      </DialogHeader>
      <div className='flex flex-col gap-2'>
        <DialogButton onClick={() => openChat()}>
          <SupportAgent className='!h-6 !w-6' />
          Chat with support
        </DialogButton>
        <DialogButton onClick={() => setCurrentStep('cancelOrder')}>
          <DeleteIcon className='h-6 w-6' />
          Cancel order
        </DialogButton>
        <DialogButton
          disabled={disableRefundRequestButton}
          onClick={async () => {
            await onRefundSelection()
          }}
          className='flex flex-col items-start text-left'
        >
          <div className='flex items-center space-x-3'>
            <CurrencyExchange className='!h-6 !w-6' />
            <span>Request Refund</span>
          </div>
          <span className='-mt-2 text-xs text-neutral-400'>
            {disableRefundRequestButton
              ? 'Only available for Delivered / Canceled orders'
              : 'Please prepare the receipt of the order'}
          </span>
        </DialogButton>
      </div>
    </>
  )
}
