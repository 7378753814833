import { Section } from './utils'
import { AsyncFetchWrapper } from '../components/async-fetch-wrapper'
import { Box, Paper, Tab, Tabs, Typography } from '@mui/material'
import { useCallback, useState } from 'react'
import { ordersService } from './order-service'
import { InsightsInterval, InsightsIntervals } from './models'
import { formatPriceStr } from '../utils/price-utils'
import { useStore } from '../root-store'

export const OrderInsights = () => {
  const [interval, setInterval] = useState(InsightsInterval.Today)
  const [insights, setInsights] = useState({
    total_orders: 0,
    total_saved: 0,
  })
  const { authStore } = useStore()

  const fetchInsights = useCallback(async () => {
    const res = await ordersService.insights(interval)
    setInsights(res.data)
  }, [interval])

  return (
    <AsyncFetchWrapper
      loadAction={fetchInsights}
      triggerProp={[authStore.currentUserStore?.store_id, interval]}
    >
      <Section
        padding={5}
        gap={3}
        display='flex'
        flexDirection='column'
        fontFamily='Montserrat'
      >
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Typography variant='h4'>Insights</Typography>

          <Tabs style={{ marginTop: 0 }} value={interval}>
            {Object.entries(InsightsIntervals).map(([key, data]) => {
              return (
                <Tab
                  key={key}
                  value={key}
                  onClick={() => setInterval(key as InsightsInterval)}
                  label={data.displayString}
                />
              )
            })}
          </Tabs>
        </Box>

        <Box display='flex' gap={3} alignItems='center'>
          {[
            {
              title: 'Total Orders Delivered',
              metric: insights.total_orders,
            },
            {
              title: 'Money Saved with a2b',
              metric: `${formatPriceStr({
                d: insights.total_saved,
                showZero: true,
                hideCurrency: true,
                showComma: true,
              })}`,
              color: '#00C2E8',
              metricSign: '$',
            },
          ].map((insight) => {
            return <InsightBox key={insight.title} {...insight} />
          })}
        </Box>
      </Section>
    </AsyncFetchWrapper>
  )
}

const InsightBox = ({
  title,
  metric,
  metricSign,
  color,
}: {
  title: string
  metric: string | number
  metricSign?: string
  color?: string
}) => {
  return (
    <Paper
      elevation={0}
      sx={{
        height: '20vm',
        minHeight: 100,
        border: '1px solid #DFDFDF',
        padding: 5,
        borderRadius: 3,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        '& *': {
          fontFamily: 'inherit',
          fontWeight: 600,
        },
      }}
    >
      <Typography lineHeight={1} variant='h6'>
        {title}
      </Typography>
      <Typography lineHeight={1} sx={{ color }} variant='h6'>
        <span>{metricSign}</span>
        <span style={{ fontSize: '3rem' }}>{metric}</span>
      </Typography>
    </Paper>
  )
}
