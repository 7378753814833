import { Box, styled, SxProps, Tooltip } from '@mui/material'
import * as React from 'react'
import { TooltipProps } from '@mui/material/Tooltip/Tooltip'
import { CSSProperties } from 'react'
import { styledWithConditionalProps } from '../utils/styled'

export const PreTag = styled('pre')(() => ({
  margin: 0,
  lineHeight: 1,
}))

export const OptionalTooltip = ({
  title,
  children,
  ...props
}: Omit<TooltipProps, 'title'> & { title?: React.ReactNode | null }) =>
  title ? (
    <Tooltip {...props} title={title}>
      {children}
    </Tooltip>
  ) : (
    children
  )

export const EllipsisStyled = styledWithConditionalProps(Box)<{
  $multipleLines?: number
}>(({ $multipleLines }) => ({
  flex: 1,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  ...($multipleLines
    ? {
        maxWidth: '100%',
        display: '-webkit-box',
        whiteSpace: 'initial',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: $multipleLines,
      }
    : {}),
}))

export const EllipsisWithTooltipDiv = (props: {
  children?: React.ReactNode | null
  tooltipTitle?: string | React.ReactNode
  style?: CSSProperties
  sx?: SxProps
  multiLines?: number
  onClick?: () => void
  disableInteractive?: boolean
}) => {
  return (
    <OptionalTooltip
      title={props.tooltipTitle || props.children}
      disableInteractive={props.disableInteractive}
    >
      <EllipsisStyled
        style={props.style}
        $multipleLines={props.multiLines}
        sx={props.sx}
        onClick={props.onClick}
      >
        {props.children}
      </EllipsisStyled>
    </OptionalTooltip>
  )
}
