import { useRunRequest } from '@/axios-client'
import { CustomTextInput } from '@/components/inputs'
import LoadingButton from '@/components/loading-button'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import Wheel from '@/components/wheel'
import { AsyncDialogBaseProps } from '@/dialogs/models'
import { useIsTouchScreen } from '@/utils/hooks'
import { debounce } from 'lodash'
import { DateTime } from 'luxon'
import { useCallback, useEffect, useState } from 'react'

export function UpdatePrepTimeDialog({
  resolve,
  submit,
}: AsyncDialogBaseProps & { submit: (minutes: number) => Promise<void> }) {
  const isTouchScreen = useIsTouchScreen()
  const [open, setOpen] = useState(true)
  const runRequest = useRunRequest()
  const [wheelValue, setWheelValue] = useState(0)

  const onWheelChange = useCallback(
    debounce((idx: number) => setWheelValue(idx), 200),
    [],
  )

  const onSubmit = useCallback(() => {
    runRequest.action({
      action: async () => {
        await submit(wheelValue)
        setOpen(false)
      },
    })
  }, [runRequest, submit, wheelValue])

  useEffect(() => {
    if (!open) {
      resolve()
    }
  }, [open, resolve])

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className='max-w-2xl gap-10'>
        <DialogHeader>
          <DialogTitle>Update Pickup Time</DialogTitle>
        </DialogHeader>
        {isTouchScreen ? (
          <div className='relative flex h-60 items-center justify-center text-[#16191C]'>
            <p className='absolute left-0 top-1/2 -translate-y-1/2 text-[#9D9D9D]'>
              Adjust prep time
            </p>
            <Wheel
              length={61}
              width={23}
              label='min'
              setValue={(idx) => idx.toString()}
              onChange={onWheelChange}
            />
            <p className='absolute right-0 top-1/2 -translate-y-1/2 text-[#9D9D9D]'>
              Ready at{' '}
              {DateTime.now().plus({ minutes: wheelValue }).toFormat('h:mm a')}
            </p>
          </div>
        ) : (
          <CustomTextInput
            type='number'
            label='Adjust prep time'
            inputProps={{ step: 1 }}
            required
            size='small'
            value={wheelValue}
            onChange={(value) => setWheelValue(Math.max(0, value))}
            fullWidth
          />
        )}
        <LoadingButton
          loading={runRequest.loading}
          className='w-full rounded-lg bg-[#00C2E8]/60 p-4 text-sm font-semibold leading-normal'
          onClick={onSubmit}
        >
          Update Pickup Time
        </LoadingButton>
      </DialogContent>
    </Dialog>
  )
}
