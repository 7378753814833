import React, { useEffect } from 'react'
import { throttle } from 'lodash'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { Autocomplete, Grid, TextFieldProps, Typography } from '@mui/material'
import { FieldValues } from 'react-hook-form/dist/types/fields'
import {
  Control,
  Controller,
  FieldPath,
  RegisterOptions,
} from 'react-hook-form'
import { axiosClient } from '../axios-client'
import { useStateFromProp } from '../utils/hooks'
import { z } from 'zod'
import { CustomTextInput } from './inputs'

export const ZodAddress = z.object({
  latitude: z.string().or(z.number()),
  longitude: z.string().or(z.number()),
  display_string: z.string(),
  postal_code: z.string().optional(),
  google_place_data: z.any(),
  locality: z.string(),
  state: z.string().or(z.null()),
  // city: z.string().or(z.null()),
  country: z.string().or(z.null()),
  // google_place_id: z.string(),
})

export type Address = z.infer<typeof ZodAddress>

export interface Suggestion {
  displayString: string
  googlePlaceId: string
}

export const AutocompleteAddressWrapper = <T extends FieldValues>(props: {
  propName: FieldPath<T>
  control: Control<T>
  rules?: Exclude<
    RegisterOptions,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >
  label: string
  disabled?: boolean
  error: boolean
  loading?: boolean
  textFieldProps?: Omit<TextFieldProps, 'value' | 'onChange'>
}) => {
  return (
    <Controller
      name={props.propName}
      rules={props.rules}
      control={props.control}
      render={({ field: { onChange, value } }) => {
        return (
          <AutoCompleteLocation
            loading={props.loading}
            error={props.error}
            rules={props.rules}
            onChange={onChange}
            value={value}
            label={props.label}
          />
        )
      }}
    />
  )
}
export const AutoCompleteLocation = (props: {
  disabled?: boolean
  value?: any | null
  onChange: (value: any | null) => void
  error?: boolean
  loading?: boolean
  rules?: Exclude<
    RegisterOptions,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >
  label: string
  inputClassName?: string
}) => {
  const [loading, setLoading] = useStateFromProp(props.loading)
  const [inputValue, setInputValue] = React.useState('')
  const [options, setOptions] = React.useState<Suggestion[]>([])
  const [suggestion, setSuggestion] = React.useState<Suggestion | null>(
    props.value
      ? {
          googlePlaceId: props.value.google_place_id!,
          displayString: props.value.display_string!,
        }
      : null,
  )

  const fetch = React.useMemo(
    () =>
      throttle(
        async (
          request: { input: string },
          callback: (results?: Suggestion[]) => void,
        ) => {
          if (request.input) {
            setLoading(true)
            const { data } = await axiosClient.get(`/geo?q=${request.input}`)
            callback(
              data.results?.map((s: any) => ({
                googlePlaceId: s.place_id,
                displayString: s.description,
              })),
            )
            setLoading(false)
          }
        },
        300,
      ),
    [],
  )
  const fetchPlaceDetails = async () => {
    if (suggestion?.googlePlaceId) {
      const { data } = await axiosClient.get(
        `/geo/details/${suggestion.googlePlaceId}`,
      )
      props.onChange(data)
      return
    }
    props.onChange(null)
  }

  useEffect(() => {
    if (suggestion?.googlePlaceId !== props.value?.google_place_id) {
      fetchPlaceDetails()
    }
  }, [suggestion])

  useEffect(() => {
    if (inputValue !== props.value?.display_string) {
      fetch({ input: inputValue }, (results?: Suggestion[] | any) => {
        let newOptions: Suggestion[] = []
        if (suggestion) {
          newOptions = [suggestion]
        }

        if (results) {
          newOptions = [...newOptions, ...results]
        }
        setOptions(newOptions)
      })
    }
  }, [suggestion, inputValue, fetch])

  useEffect(() => {
    if (props.value?.google_place_id !== suggestion?.googlePlaceId) {
      setSuggestion(
        props.value
          ? {
              googlePlaceId: props.value.google_place_id!,
              displayString: props.value.display_string!,
            }
          : null,
      )
    }
  }, [props.value?.googlePlaceId])
  const localOptions = options
  if (suggestion) {
    // if we receive value that does not exist in list we add it manually (happens when value is retrieved from backend instead of search
    if (!options.some((o) => o.googlePlaceId === suggestion.googlePlaceId)) {
      localOptions.push(suggestion)
    }
  }
  return (
    <Autocomplete
      size='small'
      isOptionEqualToValue={(o, value) => {
        return o.googlePlaceId === value.googlePlaceId
      }}
      filterOptions={() => localOptions}
      defaultValue={null}
      getOptionLabel={(option) => option?.displayString || ''}
      options={localOptions}
      disabled={props.disabled}
      fullWidth={true}
      autoComplete
      loading={loading}
      includeInputInList
      filterSelectedOptions
      value={suggestion || null}
      multiple={false}
      onChange={(_, newValue: any | null) => {
        setOptions(options)
        setSuggestion(newValue)
      }}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue)
      }}
      renderInput={(params) => {
        return (
          <CustomTextInput
            {...params}
            size='small'
            label={props.label}
            rules={props.rules}
            error={props.error}
          />
        )
      }}
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <Grid container alignItems='center'>
              <Grid item>
                <LocationOnIcon />
              </Grid>
              <Grid item xs>
                <Typography variant='body2' color='textSecondary'>
                  {option.displayString}
                </Typography>
              </Grid>
            </Grid>
          </li>
        )
      }}
    />
  )
}
