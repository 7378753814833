import { axiosClient } from '../axios-client'
import * as z from 'zod'
import { ZodAddress } from '../components/autocomplete-address'
import { StoreModel } from './models'
import { NewOrderAlertVolume, VehicleType } from '../orders/models'
import { UserClaims } from '../auth/auth-store'

export const EditStoreSchema = z.object({
  name: z.string(),
  default_courier_pickup_instructions: z.string().optional(),
  default_prep_time: z.number().int().min(0).optional(),
  default_vehicle_type: z.nativeEnum(VehicleType).default(VehicleType.Car),
  location: ZodAddress.optional(),
  new_order_alert_volume: z
    .nativeEnum(NewOrderAlertVolume)
    .default(NewOrderAlertVolume.Loud),
})

export type EditStoreFields = z.infer<typeof EditStoreSchema>

class StoresService {
  getStore = (storeId: string) => {
    return axiosClient.get<{ store: StoreModel }>(`/stores/${storeId}`)
  }

  getStoreBillingPortalUrl = (storeId: string) => {
    return axiosClient.get<{ billingPortalUrl: string }>(
      `/stores/${storeId}/billing-portal`,
    )
  }

  updateStore = (storeId: string, data: EditStoreFields) => {
    return axiosClient.patch<{
      store: StoreModel
      updatedClaimsAndToken: { claims: UserClaims }
    }>(`/stores/${storeId}`, {
      ...data,
    })
  }
}

export const storesService = new StoresService()
