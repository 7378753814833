import {
  createTheme,
  outlinedInputClasses,
  responsiveFontSizes,
} from '@mui/material'

interface Dark {
  D10: string
  D20: string
  D30: string
  D40: string
  D50: string
  D60: string
  D70: string
}
interface Blue {
  B00: string
  B10: string
  B20: string
  B30: string
  B40: string
  B50: string
  B60: string
}

interface Red {
  R00: string
  R10: string
  R20: string
  R30: string
  R40: string
  R50: string
}

interface Yellow {
  Y00: string
  Y10: string
  Y20: string
  Y30: string
  Y40: string
}

interface Green {
  G00: string
  G10: string
  G20: string
  G30: string
  G40: string
  G50: string
}

interface Purple {
  P00: string
  P10: string
  P20: string
  P30: string
  P40: string
  P50: string
}

interface ThemeColors {
  background: string
  dark: Dark
  blue: Blue
  red: Red
  yellow: Yellow
  green: Green
  purple: Purple
}

declare module '@mui/material/styles' {
  interface Theme {
    colors: ThemeColors
  }

  // allow configuration using `createTheme`
  interface ThemeOptions {
    colors: ThemeColors
  }

  interface TypographyVariantsOptions {
    tiny?: React.CSSProperties
    small?: React.CSSProperties
    body?: React.CSSProperties
    title?: React.CSSProperties
  }
}

declare module '@mui/material/styles' {
  interface Theme {
    colors: ThemeColors
  }

  // allow configuration using `createTheme`
  interface ThemeOptions {
    colors: ThemeColors
  }

  interface TypographyVariantsOptions {
    tiny?: React.CSSProperties
    small?: React.CSSProperties
    body?: React.CSSProperties
    title?: React.CSSProperties
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    tiny: true
    small: true
    body: true
    title: true
  }
}

export const colors = {
  dark: {
    D10: '#000000',
    D20: '#595A5F',
    D30: '#78797E',
    D40: '#AFAFAF',
    D50: '#DBDBDC',
    D60: '#EEEEEE',
    D70: '#F6F6F6',
    D80: '#FFFFFF',
  },
  blue: {
    B00: '#004664',
    B10: '#009DE0',
    B20: '#03C2E8',
    B30: '#a0e7f6',
    B40: '#dbf7ff',
    B50: 'rgba(219,247,255,0.37)',
    B60: '#44E0FF',
  },
  red: {
    R00: '#BC2C15',
    R10: '#DA503A',
    R20: '#D87566',
    R30: '#EDA094',
    R40: '#FFD9D3',
    R50: '#FFECE9',
  },
  yellow: {
    Y00: '#E2AD02',
    Y10: '#FFCA61',
    Y20: '#FAE1B0',
    Y30: '#FDEBC4',
    Y40: '#FFF5E0',
  },
  green: {
    G00: '#1F9060',
    G10: '#6ABD6B',
    G20: '#93D6A6',
    G30: '#E9F4EF',
    G40: '#DAFFE4',
    G50: '#F3FFF7',
  },
  purple: {
    P00: '#21005D',
    P10: '#6B6ABD',
    P20: '#9484CE',
    P30: '#BBAEEA',
    P40: '#E8DEF8',
    P50: '#F3EFFF',
  },
}

const theme = responsiveFontSizes(
  createTheme({
    spacing: 4,
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            height: 42,
            borderRadius: 8,
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            [`&.${outlinedInputClasses.focused}`]: {
              border: '0 !important',
            },
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderWidth: 1,
              borderRadius: 8,
              borderColor: 'rgba(0, 0, 0, 0.12) !important',
            },
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          divider: {
            // borderTop: '1.1px solid rgb(238 238 238 / 31%)',
            // borderBottom: '1.1px solid rgb(238 238 238 / 31%)',
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: '0.8em',
          },
        },
      },
      MuiTabs: {
        defaultProps: {
          textColor: 'secondary',
          variant: 'scrollable',
        },
        styleOverrides: {
          indicator: {
            backgroundColor: '#00C2E8',
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            '&.Mui-disabled': {
              color: colors.dark.D50,
            },
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            //   height: 42,
            '&.Mui-disabled': {
              pointerEvents: 'auto',
            },
            '&.MuiButton-contained': {
              boxShadow: 'inherit',
              borderRadius: 10,
            },
            '&.MuiButton-outlined': {
              boxShadow: 'inherit',
              borderRadius: 10,
            },
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              // borderRadius: 10,
              // borderColor: colors.green.G00,
            },
            '&:hover': {
              // borderColor: colors.green.G00,
            },
          },
        },
      },
      MuiToggleButtonGroup: {
        styleOverrides: {
          grouped: {
            '&:last-of-type': {
              borderTopRightRadius: 8,
              borderBottomRightRadius: 8,
            },
            '&:first-of-type': {
              borderTopLeftRadius: 8,
              borderBottomLeftRadius: 8,
            },
          },
        },
      },
    },
    colors: {
      ...colors,
      background: '#F6F8FA',
    },
    typography: {
      fontFamily: 'Poppins',
      button: {
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 500,
        textTransform: 'none',
      },
      tiny: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '18px',
      },
      small: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '20px',
      },
    },
    palette: {
      contrastThreshold: 2.5,
      primary: {
        main: colors.dark.D10,
        light: '#2c2c2d',
        contrastText: '#fff',
      },
      secondary: {
        main: colors.blue.B20,
        contrastText: '#fff',
      },
      error: {
        main: colors.red.R10,
      },
      warning: {
        main: colors.yellow.Y00,
        contrastText: '#fff',
      },
      success: {
        main: colors.green.G10,
      },
    },
  }),
  { factor: 3 },
)

theme.typography.body1 = {
  ...theme.typography.subtitle1,
  [theme.breakpoints.down('md')]: {
    fontSize: '0.9rem',
  },
}

theme.typography.caption = {
  ...theme.typography.caption,
  [theme.breakpoints.down('lg')]: {
    fontSize: '0.8rem',
  },
}

//
// theme.typography.subtitle1 = {
//   ...theme.typography.subtitle1,
//   [theme.breakpoints.down('md')]: {
//     fontSize: '1.2rem',
//     fontWeight: 400,
//   },
// }
//
// theme.typography.subtitle2 = {
//   ...theme.typography.subtitle2,
//   [theme.breakpoints.down('md')]: {
//     fontSize: '1rem',
//     fontWeight: 400,
//   },
// }

export default () => theme
