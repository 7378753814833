import { axiosClient } from '../axios-client'
import {
  InsightsInterval,
  InsightsIntervals,
  LiveOrder,
  Order,
  OrderDetails,
  OrderWithDeliveries,
  PipeStatus,
} from './models'
import { CreateDelivery, CreateOrderFields } from './schema'
import { DateTime } from 'luxon'

interface LiveOrdersResponse {
  newOrderCount?: number
  newScheduledOrderCount?: number
  orders: { [key in PipeStatus]?: LiveOrder[] }
}

class OrdersService {
  private lastPoll = Date.now()

  liveOrders = () => {
    return axiosClient.get<LiveOrdersResponse>('/orders/live-orders', {
      params: {
        lastPoll: this.lastPoll,
      },
    })
  }

  updateLastPoll = () => {
    this.lastPoll = Date.now()
  }

  insights = (interval: InsightsInterval) => {
    return axiosClient.get<{
      total_orders: number
      total_saved: number
    }>('/orders/insights', {
      params: {
        startTime: InsightsIntervals[interval].isoStart?.(),
        endTime: InsightsIntervals[interval].isoEnd?.(),
      },
    })
  }

  pendingReviewOrders = () => {
    return axiosClient.get<OrderDetails[]>('/orders/pending-review')
  }

  getOrders = () => {
    return axiosClient.get<OrderWithDeliveries[]>('/orders')
  }

  filterOrders = (textFilter: string) => {
    return axiosClient.get<Order[]>('/orders/autocomplete', {
      params: { q: textFilter },
    })
  }

  getOrder = (orderId: string) => {
    return axiosClient.get<OrderDetails>(`/orders/${orderId}`)
  }

  createOrderAndDelivery = (
    data: Omit<CreateOrderFields, 'customer_address'> & {
      customer_address: string
    },
  ) => {
    return axiosClient.post<{ order: Order }>('/orders', {
      ...data,
    })
  }

  createDelivery = (orderId: string, data: CreateDelivery) => {
    return axiosClient.post<Order>('/deliveries', {
      ...data.delivery,
      order_id: orderId,
    })
  }

  updatePickupMinutes = (deliveryId: string, pickupMinutes: number) => {
    return axiosClient.patch<Order>(`/deliveries/${deliveryId}`, {
      pickup_time: DateTime.utc().plus({ minutes: pickupMinutes }).toISO(),
    })
  }

  cancelDelivery = (deliveryId: string) => {
    return axiosClient.put<Order>(`/deliveries/${deliveryId}/cancel`)
  }

  cancelOrder = (orderId: string, reason?: string) => {
    return axiosClient.post<Order>(`/orders/${orderId}/cancel`, { reason })
  }

  deleteOrder = (orderId: string) => {
    return axiosClient.delete(`/orders/${orderId}`)
  }

  sendSms = (orderId: string, body: string) => {
    return axiosClient.post(`/orders/${orderId}/sms`, { sms_body: body })
  }

  acceptOrder = (orderId: string, delivery?: CreateDelivery) => {
    return axiosClient.post(`/orders/${orderId}/accept`, delivery)
  }
  rejectOrder = (orderId: string) => {
    return axiosClient.post(`/orders/${orderId}/reject`, {})
  }
}

export const ordersService = new OrdersService()
