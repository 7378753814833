import { action, makeAutoObservable } from 'mobx'
import { v4 as uuid } from 'uuid'
import { AlertColor, Box } from '@mui/material'
import React from 'react'
import { CustomError } from '../axios-client'

export interface SnackbarAction {
  label: string
  onPress?: () => void
}

export interface SnackbarItem {
  id: string
  title: string | React.ReactNode
  action?: SnackbarAction
  removeCloseButton?: boolean
  onCloseClicked?: () => void
  severity?: AlertColor
}

type Horizontal = 'left' | 'center' | 'right'

export class SnackbarStore {
  snackbars: SnackbarItem[] = []
  horizontal: Horizontal = 'center'

  constructor() {
    makeAutoObservable(this)
  }

  onDismiss = (id: string) => {
    const index = this.snackbars.findIndex((i) => i.id === id)

    if (index >= 0) {
      action(() => {
        this.snackbars = [
          ...this.snackbars.slice(0, index),
          ...this.snackbars.slice(index + 1, this.snackbars.length),
        ]
      })()
    }
  }

  showCustomerError = (error: CustomError) => {
    return this.showSnackbar({
      title: error.details?.length ? (
        <Box>
          {error.message}
          {error.details.flatMap((d) => (
            <>
              <br />
              {d}
            </>
          ))}
        </Box>
      ) : (
        error.message
      ),
      severity: 'error',
    })
  }

  showSnackbar = (props: {
    title: string | React.ReactNode
    action?: {
      label: string
      onPress: () => void
    }
    removeCloseButton?: boolean
    onCloseClicked?: () => void
    label?: string
    onPress?: () => void
    timeToHide?: number
    horizontal?: Horizontal
    severity?: AlertColor
  }) => {
    const id = uuid()
    const item: SnackbarItem = {
      id: id,
      title: props.title,
      onCloseClicked: props.onCloseClicked,
      removeCloseButton: props.removeCloseButton,
      action: props.action,
      severity: props.severity,
    }
    action(() => {
      this.snackbars = [...this.snackbars, item]
      this.horizontal = props.horizontal || 'center'
    })()

    setTimeout(() => this.onDismiss(id), props.timeToHide || 5000)
  }
}
