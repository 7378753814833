import { cn } from '@/utils'
import {
  ChangeEventHandler,
  FocusEventHandler,
  forwardRef,
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
} from 'react'

export interface InputProps
  extends Pick<
    InputHTMLAttributes<HTMLInputElement>,
    | 'type'
    | 'placeholder'
    | 'onChange'
    | 'className'
    | 'required'
    | 'onFocus'
    | 'onBlur'
    | 'disabled'
    | 'readOnly'
  > {
  containerClassName?: InputProps['className']
  value: string
  error?: string
  mask?: 'phone'
  prefix?: string
  prefixClassName?: string
  suffix?: string
  suffixClassName?: string
}

const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
  {
    type,
    placeholder,
    value,
    onChange,
    className,
    required,
    containerClassName,
    mask,
    error,
    prefix,
    prefixClassName,
    suffix,
    suffixClassName,
    onFocus,
    onBlur,
    disabled,
    readOnly,
  },
  ref,
) {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [isFocused, setIsFocused] = useState(false)
  const [hasContent, setHasContent] = useState(value !== '')

  const handleFocus: FocusEventHandler<HTMLInputElement> = (event) => {
    if (!disabled && !readOnly) {
      setIsFocused(true)
      onFocus?.(event)
    }
  }

  const handleBlur: FocusEventHandler<HTMLInputElement> = (event) => {
    if (!disabled && !readOnly) {
      setIsFocused(false)
      onBlur?.(event)
    }
  }

  const applyMask = (value: string, cursorPosition: number) => {
    if (mask === 'phone') {
      const numericValue = value.replace(/\D/g, '')
      const formattedValue = numericValue.replace(
        /^(\d{0,3})(\d{0,3})(\d{0,4})$/,
        (_, g1, g2, g3) =>
          `${g1 ? `(${g1}` : ''}${g2 ? `) ${g2}` : ''}${g3 ? ` ${g3}` : ''}`.trim(),
      )

      const nonDigitCountBefore = (
        value.slice(0, cursorPosition).match(/\D/g) || []
      ).length
      const nonDigitCountAfter = (
        formattedValue.slice(0, cursorPosition).match(/\D/g) || []
      ).length

      let newCursorPosition =
        cursorPosition + (nonDigitCountAfter - nonDigitCountBefore)
      // adjust cursor when typing the number after ')'
      if (numericValue.length >= 3 && cursorPosition === 5) {
        newCursorPosition += 1
      }

      return {
        maskedValue: formattedValue,
        newCursorPosition: Math.min(newCursorPosition, formattedValue.length),
      }
    }

    return {
      maskedValue: value,
      newCursorPosition: cursorPosition,
    }
  }

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (disabled || readOnly) return

    const { maskedValue, newCursorPosition } = applyMask(
      event.target.value,
      event.target.selectionStart || 0,
    )

    setHasContent(maskedValue !== '')
    onChange?.({
      ...event,
      target: {
        ...event.target,
        value: maskedValue,
        valueAsNumber: Number(maskedValue),
      },
    })

    if (inputRef.current) {
      // delay cursor update to let react update the dom
      setTimeout(() => {
        inputRef.current!.selectionStart = newCursorPosition
        inputRef.current!.selectionEnd = newCursorPosition
      }, 0)
    }
  }

  useEffect(() => {
    setHasContent(value !== '')
  }, [value])

  return (
    <div className={cn('w-full', containerClassName)}>
      <div className='relative w-full'>
        <div
          className={cn(
            'flex w-full rounded-lg border border-outline text-sm/normal transition-colors duration-200',
            disabled || readOnly
              ? 'cursor-not-allowed bg-gray-100 text-gray-500'
              : '',
            className,
          )}
        >
          {prefix && (
            <p className={cn('self-center pl-5', prefixClassName)}>{prefix}</p>
          )}
          <input
            ref={(node) => {
              inputRef.current = node
              if (typeof ref === 'function') {
                ref(node)
              } else if (ref) {
                ref.current = node
              }
            }}
            type={type}
            required={required}
            disabled={disabled}
            readOnly={readOnly}
            className={cn(
              'w-full rounded-[inherit] px-5 py-3 text-sm/normal outline-none',
              prefix && 'pl-0',
              suffix && 'pr-0',
              disabled || readOnly
                ? 'cursor-not-allowed bg-gray-100 text-gray-500'
                : '',
            )}
            value={applyMask(value, 0).maskedValue}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          {suffix && (
            <p className={cn('self-center pr-5', suffixClassName)}>{suffix}</p>
          )}
        </div>
        <label
          className={cn(
            'pointer-events-none absolute left-5 line-clamp-1 max-w-[calc(100%-2.5rem)] select-none bg-white text-sm/normal transition-all',
            isFocused || hasContent
              ? '-top-2 px-1 text-xs/normal text-[#6F6F6F]'
              : 'top-1/2 -translate-y-1/2 text-placeholder',
            disabled || readOnly ? 'text-gray-400' : '',
          )}
        >
          {placeholder}
        </label>
      </div>
      {error && <p className='ml-1 mt-1 text-xs text-red-500'>{error}</p>}
    </div>
  )
})

export default Input
