import {
  DeliveryPartner,
  DeliveryPartners,
  Order,
  OrderDelivery,
  OrderingPlatforms,
} from './models'
import { Avatar, Chip } from '@mui/material'
import { getOrderStatusData } from './utils'
import { EllipsisWithTooltipDiv } from '../components/general-components'

export const OrderingPlatformAvatar = ({
  order,
  width,
  height,
}: {
  order: Order
  width?: number
  height?: number
}) => {
  const PlatformIcon = OrderingPlatforms[order.ordering_platform].icon
  return PlatformIcon ? (
    <Avatar
      sx={{
        border: '1px solid black',
        borderRadius: 2,
        width: (width || 40) - 14,
        height: (height || 40) - 14,
        background: 'white',
        padding: 1.5,
      }}
    >
      <img
        src={PlatformIcon}
        style={{
          width: '100%',
          height: '100%',
          background: 'white',
        }}
      />
    </Avatar>
  ) : (
    <Avatar
      sx={{
        bgcolor: 'red',
        width: (width || 40) - 12,
        height: (height || 40) - 12,
        borderRadius: 2,
        padding: 1.5,
      }}
    >
      {order.store.name.slice(0, 1).toUpperCase()}
    </Avatar>
  )
}

export const OrderStatusComponent = ({
  order,
  delivery,
}: {
  order: Order
  delivery?: OrderDelivery
}) => {
  const statusData = getOrderStatusData(order, delivery)

  return (
    <Chip
      label={
        <EllipsisWithTooltipDiv>
          {statusData.displayString}
        </EllipsisWithTooltipDiv>
      }
      size='small'
      sx={{ backgroundColor: statusData.backgroundColor }}
    />
  )
}

export const DeliveryLogo = ({
  deliveryPartner,
  size,
}: {
  deliveryPartner: DeliveryPartner
  size?: number
}) => {
  const icon = DeliveryPartners[deliveryPartner].icon
  return (
    <Avatar sx={{ width: size || 40, height: size || 40 }}>
      <img src={icon} style={{ width: size || 40, height: size || 40 }} />
    </Avatar>
  )
}
