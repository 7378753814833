import ReactDOM from 'react-dom/client'
import App from './App'
import React from 'react'
import './index.css'
import * as Sentry from '@sentry/react'
import { config } from './config'

Sentry.init({
  dsn: config.sentryDsn,
  environment: config.env,
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 1.0,
})

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
