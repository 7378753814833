import { Info } from 'luxon'

export const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const pluralSuffixOptionalString = (array: any[], suffix = 's') => {
  return array.length === 1 ? '' : suffix
}

export const generateShortName = (name?: string) => {
  if (!name) {
    return name
  }
  const split = name.split(' ')
  return `${split[0]}${split?.[1] ? ` ${split[1][0]}.` : ''}`
}

export type NullablePartial<T> = {
  [P in keyof T]?: T[P] | null
}

export enum Day {
  Mon = 1,
  Tue = 2,
  Wed = 3,
  Thu = 4,
  Fri = 5,
  Sat = 6,
  Sun = 7,
}

export const DaysData: {
  [key in Day]: { displayString: string; short: string }
} = {
  [Day.Mon]: {
    displayString: 'Monday',
    short: 'Mon',
  },
  [Day.Tue]: {
    displayString: 'Tuesday',
    short: 'Tue',
  },
  [Day.Wed]: {
    displayString: 'Wednesday',
    short: 'Wed',
  },
  [Day.Thu]: {
    displayString: 'Thursday',
    short: 'Thu',
  },
  [Day.Fri]: {
    displayString: 'Friday',
    short: 'Fri',
  },
  [Day.Sat]: {
    displayString: 'Saturday',
    short: 'Sat',
  },
  [Day.Sun]: {
    displayString: 'Sunday',
    short: 'Sun',
  },
}

export const DAYS_OPTIONS_SHORT = Object.keys(DaysData).map((d) => {
  const day = parseInt(d) as Day
  return {
    key: d,
    value: DaysData[day].displayString,
  }
})

export const DAYS_OPTIONS = Object.keys(DaysData).map((d) => {
  const day = parseInt(d) as Day
  return {
    key: d,
    value: DaysData[day].displayString,
  }
})

export const getDaysRange = (days: number[]): string => {
  const workingDays = days.sort().map((d) => d - 1)

  if (!workingDays.length) {
    return ''
  }

  if (workingDays.length <= 1) {
    return Info.weekdays('short')[workingDays[0]]
  }

  if (workingDays.length <= 2) {
    return workingDays.map((d) => Info.weekdays('short')[d]).join(', ')
  }

  const consecutiveString = (start: number, end: number) =>
    start === end
      ? Info.weekdays('short')[workingDays[start]]
      : `${Info.weekdays('short')[workingDays[start]]}-${
          Info.weekdays('short')[workingDays[end]]
        }`

  const parts: { start: number; end: number }[] = []
  let start = 0
  let i = 1

  while (i < workingDays.length) {
    if (workingDays[i - 1] + 1 !== workingDays[i]) {
      parts.push({ start, end: i - 1 })
      start = i
    }
    i += 1
  }

  parts.push({ start, end: workingDays.length - 1 })
  return parts.map((p) => consecutiveString(p.start, p.end)).join(', ')
}

export const PHONE_REGEX =
  /(^$|^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$)/im
