import { Route, Routes } from 'react-router-dom'
import { LiveOrdersPage } from './live-orders-page'
import { Page } from '../utils/page'

export const LiveOrdersRoute = () => {
  return (
    <Routes>
      {/*<Route path='/:orderId' element={<EditOrderPage />} />*/}
      <Route
        path='/'
        element={
          <Page title='Orders'>
            <LiveOrdersPage />
          </Page>
        }
      />
    </Routes>
  )
}
