import { Crisp } from 'crisp-sdk-web'
import { useCallback, useEffect, useRef } from 'react'
import { Order } from '../orders/models'
import {
  Actions,
  CrispMessageReceived,
  CrispQuestions,
  Flow,
  FlowData,
} from './models'
import { ordersService } from '../orders/order-service'

const sendQuestion = (q: CrispQuestions) => {
  const data = (
    typeof Flow[q] === 'function' ? (Flow[q] as any)() : Flow[q]
  ) as FlowData

  switch (data.type) {
    case 'picker':
      Crisp.message.show(data.type, {
        id: q,
        text: data.text!,

        choices: Object.keys(data.options!).map((i) => ({
          value: i,
          label: data.options![i].text,
          selected: false,
        })),
      })
      break
    case 'carousel':
      Crisp.message.showCarousel({
        text: data.text,
        targets: Object.keys(data.options!).map((i) => ({
          title: data.options![i].text,
          actions: [],
        })),
      })
      break
    case 'text':
      Crisp.message.showText(data.text!)
      break
  }
}

const runAction = async ({
  action,
  reloadOrder,
  reason,
}: {
  action: Actions
  reloadOrder?: () => void
  reason?: string
}) => {
  switch (action) {
    case Actions.CancelAction: {
      const orderId = Crisp.session.getData('orderId') as string

      if (!orderId) {
        return sendQuestion(CrispQuestions.HumanSupport)
      }

      try {
        await ordersService.cancelOrder(orderId, reason)
        if (reloadOrder) {
          await reloadOrder?.()
        }

        Crisp.message.showText('Thanks, we requested canceling the order.')
        // Crisp.message.endThread()
        return
      } catch (e) {
        return sendQuestion(CrispQuestions.HumanSupport)
      }
    }

    default:
      return sendQuestion(CrispQuestions.HumanSupport)
  }
}

export const useAskForHelpFlow = () => {
  const readMessagesRef = useRef(new Set<number>())
  const reloadOrderRef = useRef<() => void>()

  useEffect(() => {
    return () => (reloadOrderRef.current = undefined)
  }, [])

  useEffect(() => {
    Crisp.message.onMessageReceived((data: CrispMessageReceived) => {
      // Skip responses not being updates
      if (data.origin !== 'update') {
        return
      }

      const currentFlow = (
        typeof Flow[data.content.id as CrispQuestions] === 'function'
          ? (Flow[data.content.id as CrispQuestions] as any)()
          : Flow[data.content.id as CrispQuestions]
      ) as FlowData
      if (!currentFlow) {
        return
      }
      // Skip messages types other than pickers
      if (data.type !== currentFlow.type) {
        return
      }

      if (readMessagesRef.current.has(data.fingerprint)) {
        return
      }
      console.log('messsaggeee', data)
      readMessagesRef.current.add(data.fingerprint)

      const selected = data.content.choices?.find((choice) => choice.selected)

      if (currentFlow.type === 'picker' && selected) {
        Crisp.message.send('text', selected.label)
        const nextQuestion = currentFlow.options?.[selected.value]?.nextQuestion

        if (nextQuestion) {
          sendQuestion(nextQuestion)
          return
        }

        const nextAction = currentFlow.options?.[selected.value]?.nextAction

        if (nextAction) {
          return runAction({
            action: nextAction,
            reloadOrder: reloadOrderRef.current,
            reason: selected.label,
          })
        }
      }

      if (currentFlow.nextQuestion) {
        sendQuestion(currentFlow.nextQuestion)
        return
      }

      if (currentFlow.nextAction) {
        return runAction({
          action: currentFlow.nextAction,
          reloadOrder: reloadOrderRef.current,
        })
      }

      // Crisp.message.endThread()
      //
      // Crisp.message.send('text', 'Current choice is ' + _choice?.label)
    })
  }, [])

  return useCallback(
    ({ order, reloadOrder }: { order: Order; reloadOrder?: () => void }) => {
      if (reloadOrder) {
        reloadOrderRef.current = reloadOrder
      }
      Crisp.chat.open()
      // generalStore.setCrispOpen(true)
      // Crisp.message.startThread(
      //   `Order ${order.external_id}, Store ${order.store?.name}`,
      // )

      Crisp.session.setData({
        orderId: order.id,
        externalOrderId: order.external_id,
        storeId: order.store_id,
        storeName: order.store?.name,
      })

      // Crisp.message.startThread(`Order Issues #${order.external_id}`)

      sendQuestion(CrispQuestions.TypeOfHelp)
    },
    [],
  )
}

// Crisp.message.show('carousel', {
//   text: "Sure! Here's what I have...",
//
//   targets: [
//     {
//       title: 'iPhone 12 Mini',
//       description: 'Refurbished iPhone 12 Mini in excellent condition.',
//
//       actions: [
//         {
//           label: 'View Product',
//           url: 'https://www.apple.com/shop/buy-iphone/iphone-12',
//         },
//       ],
//     },
//
//     {
//       title: 'iPhone 13',
//       description: 'Brand new iPhone 13, coming with Apple Care.',
//
//       actions: [
//         {
//           label: 'View Product',
//           url: 'https://www.apple.com/shop/buy-iphone/iphone-13',
//         },
//       ],
//     },
//   ],
// })
// Crisp.message.sendText(`Hey, I need help with order ${order.external_id}`)
