import { AsyncDialogBaseProps } from '@/dialogs/models'
import { Dialog } from '@mui/material'

import ShareIcon from '@mui/icons-material/Share'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { axiosClient } from '@/axios-client'
import { useStore } from '@/root-store'
import { useState } from 'react'
import { Check } from '@mui/icons-material'

const createReferLink = async (storeId: string) => {
  const res = await axiosClient.get<{
    referralLink: string
  }>(`/referral/${storeId}`)
  return res.data.referralLink
}

export const CreateReferral = (props: AsyncDialogBaseProps) => {
  const {
    authStore: { currentStoreId },
  } = useStore()

  const [copied, setCopied] = useState(false)
  return (
    <Dialog open={props.open} fullWidth={true} onClose={props.reject}>
      <div className='flex flex-col items-center justify-center border-gray-800 bg-white p-6 shadow-lg'>
        {/* Close Button */}
        <button
          aria-label='Close'
          className='self-end text-lg text-gray-800 hover:text-gray-500'
          onClick={props.reject}
          style={{ fontSize: '24px' }}
        >
          &times;
        </button>

        {/* Title */}
        {copied ? (
          <h2 className='font-poppins mt-4 text-center text-2xl font-semibold text-[#00C2E8]'>
            <span>
              <Check />
            </span>{' '}
            Copied to Clipboard
          </h2>
        ) : (
          <h2 className='font-poppins mt-4 text-center text-2xl font-semibold text-black'>
            Refer a2b to Other Stores
            <br />
            and <span className='text-[#00C2E8]'>Earn $250!</span>
          </h2>
        )}
        {/* Subtitle */}
        <p className='font-poppins mt-2 text-center text-sm text-gray-500'>
          You will receive the $250 coupon after the store you referred has
          completed their sign-up process and made 5 deliveries.
        </p>

        {/* Buttons */}
        <div className='mt-6 flex flex-wrap items-center justify-center gap-4'>
          {/* Share Button */}
          {navigator?.share && (
            <div
              className='flex cursor-pointer items-center justify-center gap-2 rounded-lg border border-gray-300 bg-white p-[30px] px-[40px] hover:bg-gray-100'
              onClick={async () => {
                const link = await createReferLink(currentStoreId!)
                await navigator.share({
                  title: 'Expand Your Delivery Capabilities with a2b.',
                  text: 'You can find more info in this link:',
                  url: link,
                })
              }}
            >
              <div className='flex items-center justify-center'>
                <ShareIcon className='text-gray-700' />
                <p className='font-poppins w-[80px] text-center font-medium text-gray-700'>
                  Share
                </p>
              </div>
            </div>
          )}

          {/* Copy Link Button */}
          <div
            className='m-4 flex cursor-pointer items-center justify-center gap-2 rounded-lg border border-gray-300 bg-white p-[30px] px-[40px] hover:bg-gray-100'
            onClick={async () => {
              const link = await createReferLink(currentStoreId!)
              // support both ways of copying to clipboard.
              // old browsers won't support navigator.share and new ones may not support document.execCommand
              if (navigator.clipboard?.writeText) {
                await navigator.clipboard.writeText(link)
              } else {
                const textArea = document.createElement('textarea')
                textArea.value = link
                document.body.appendChild(textArea)
                textArea.select()
                document.execCommand('copy')
                document.body.removeChild(textArea)
              }
              setCopied(true)
            }}
          >
            <div className='flex items-center justify-center gap-[10px]'>
              <ContentCopyIcon className='text-gray-700' />
              <p className='font-poppins w-[80px] font-medium text-gray-700'>
                Copy Link
              </p>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}
