import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form'
import DayTimePicker, { DayTimePickerProps } from './day-time-picker'

interface Props<T extends FieldValues>
  extends Omit<DayTimePickerProps, 'value' | 'onChange'> {
  control: Control<T>
  name: FieldPath<T>
}

export default function FormDayTimePicker<T extends FieldValues>({
  control,
  name,
  ...props
}: Props<T>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <DayTimePicker
          value={value}
          onChange={onChange}
          error={error?.message}
          {...props}
        />
      )}
    />
  )
}
