import { Route, Routes } from 'react-router-dom'
import { OrdersPage } from './orders-page'
import { Page } from '../utils/page'

export const OrdersRoute = () => {
  return (
    <Routes>
      {/*<Route path='/:orderId' element={<EditOrderPage />} />*/}
      <Route
        path='/'
        element={
          <Page title='Orders'>
            <OrdersPage />
          </Page>
        }
      />
    </Routes>
  )
}
